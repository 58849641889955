import { createEvent, createStore } from 'effector';
import Cookies from 'js-cookie';
import { apiGet, apiPost } from '../api';
import { passwordRecoverConfirm } from '../api/auth';
import { fetchProfile } from '../api/profile';
import { queryClient } from '../index';
import { IApiResponse_Auth, IApiResponse_Core, IApiResponse_Translations } from '../types/api';
import { IForm_Login } from '../types/form/auth';
import { IForm_PasswordRecoverRequest, IForm_PasswordUpdate } from '../types/form/passwordRecover';
import { IAuth_Store } from '../types/store/auth';
import { EForm_ErrorStore } from '../types/store/form';
import { EModal_VisibleStore } from '../types/store/modals';
import { updateAppLoading } from './app';
import { resetFormErrors, setFormErrors, updateActiveForm } from './form';
import { hideAllModal, showModal } from './modal';
import { resetProfile, updateProfile } from './profile';
import { changeLanguage, Language, updateTranslations } from './language';
import { getLocale } from '../i18n/config';

export const authUser = async (data: IForm_Login) => {
  updateActiveForm(EForm_ErrorStore.Login);
  queryClient.clear();

  const response: IApiResponse_Core<IApiResponse_Auth> = await apiPost({
    url: '/auth/',
    postData: data,
    viewAlert: false,
  });

  if (response.error) return;

  const authToken = response.data.token;

  Cookies.set('authToken', authToken, { expires: data.remember ? 360 : 1, path: '' });
  updateProfile(response.data.instance);
  login(authToken);
};

export const authCheck = async () => {
  const authToken = Cookies.get('authToken');

  const defaultLang = localStorage.getItem('lang');

  if (!defaultLang) {
    localStorage.setItem('lang', 'ru');
  } else {
    changeLanguage(defaultLang as Language);
  }

  const translation: IApiResponse_Core<IApiResponse_Translations[]> = await apiGet({
    url: '/locales/translitions/',
  });

  const cache = {};

  Object.keys(translation.data[0]).forEach(key => {
    // @ts-ignore
    cache[key] = {};

    translation.data.forEach((innerKey, index) => {
      // @ts-ignore
      cache[key][innerKey['ru']] = translation.data[index][key];
    });
  });

  // @ts-ignore
  localStorage.setItem('translation-ru', JSON.stringify(cache.ru));
  // @ts-ignore
  localStorage.setItem('translation-en', JSON.stringify(cache.en));
  // @ts-ignore
  localStorage.setItem('translation-gr', JSON.stringify(cache.el));

  updateTranslations(cache);

  getLocale();

  if (authToken) {
    const profileData = await fetchProfile({
      headerList: {
        Authorization: `Token ${authToken}`,
      },
    });

    if (profileData) {
      updateProfile(profileData);
      login(authToken);
      updateAppLoading(false);

      return;
    }
  }

  logout();
  updateAppLoading(false);
};

export const recoverPasswordRequest = async (postData: IForm_PasswordRecoverRequest) => {
  updateActiveForm(EForm_ErrorStore.PasswordRecover);

  const response = await apiPost({
    url: '/reset_password/',
    postData,
  });

  if (response.error) return;

  hideAllModal();
  showModal(EModal_VisibleStore.PasswordRecoverSuccess);
};

export const recoverPasswordRequestSetNewPassword = async (data: IForm_PasswordUpdate) => {
  updateActiveForm(EForm_ErrorStore.SetNewPassword);
  resetFormErrors();

  if (data.password.length < 6) {
    setFormErrors({
      key: EForm_ErrorStore.SetNewPassword,
      value: { password: [{ message: 'Длина пароля должна быть не менее 6 символов' }] },
    });

    return;
  }

  if (data.password !== data.passwordRepeat) {
    setFormErrors({
      key: EForm_ErrorStore.SetNewPassword,
      value: {
        password: [{ message: 'Пароли должны совпадать' }],
        passwordRepeat: [{ message: 'Пароли должны совпадать' }],
      },
    });

    return;
  }

  const res = await passwordRecoverConfirm(data);

  if (res.error) return;

  alert('Пароль успешно обновлен. Авторизуйтесь');
  return res;
};

const initStore = {
  authToken: null,
};

export const login = createEvent<string>();
export const logout = createEvent();

export const $auth = createStore<IAuth_Store>(initStore)
  .on(login, (state, payload) => ({ ...state, authToken: payload }))
  .on(logout, () => {
    resetProfile();
    Cookies.remove('authToken');
    return initStore;
  });
