import { BiClients_NewClients } from '../../components/common/Modal/BiToSegmentsModals/HomePageTab_1/BiClients_NewClients';
import { BiClients_Male } from '../../components/common/Modal/BiToSegmentsModals/HomePageTab_1/BiClients_Male';
import { BiClients_Female } from '../../components/common/Modal/BiToSegmentsModals/HomePageTab_1/BiClients_Female';

export enum EForm_ErrorStore {
  Login = 'login',
  MailingAdd = 'mailingAdd',
  Sources = 'sources',
  SourcesGroup = 'sources_group',
  MailingEdit = 'mailingEdit',
  AuthRequest = 'authRequest',
  ClientEdit = 'clientEdit',
  ClientCreate = 'clientCreate',
  ClientUnite = 'clientUnite',

  ProfileEdit = 'profileEdit',
  PasswordRecover = 'passwordRecover',
  SetNewPassword = 'setNewPassword',
  EventEdit = 'eventEdit',
  EventAdd = 'eventAdd',

  TopMatch = 'topMatch',
  EventSystem = 'eventSystem',
  TVRate = 'tvRate',

  FileImport = 'fileImport',
  UserCreate = 'createUser',
  UserEdit = 'userEdit',
  RoleEdit = 'editRole',
  RoleCreate = 'createRole',
  ReferenceCreate = 'referenceCreate',
  ReferenceEdit = 'referenceEdit',
  PollGroupCreate = 'pollGroupCreate',
  PollGroupAdd = 'pollGroupAdd',
  PollForm = 'pollForm',
  AddPollRequest = 'addPollRequest',
  ExternalPoll = 'externalPoll',

  CreatePromoCode = 'createPromoCode',

  TicketOwnersFilter = 'ticketOwnersFilter',

  EventAddPlayer = 'eventAddPlayer',
  EventAddHockeyTeam = 'eventAddHockeyTeam',
  EventAddHockeyTime = 'eventAddHockeyTime',
  EventLogsAddHockey = 'eventLogsAddHockey',
  EventAddTrainer = 'eventAddTrainer',
  EventFootballEditStat = 'eventFootballEditStat',
  EventAddVolleyballPlayer = 'eventAddVolleyballPlayer',

  ProductCreate = 'productCreate',
  CategoryCreate = 'categoryCreate',
  CategoryShopEdit = 'categoryShopEdit',
  ProductShopEdit = 'productShopEdit',
  PushNotificationEdit = 'pushNotificationEdit',
  PushNotificationCreate = 'pushNotificationCreate',
  BannerCreate = 'bannerCreate',
  BannerEdit = 'bannerEdit',
  Notice = 'notice',
  NoticeEdit = 'noticeEdit',

  SendSegmentItem = 'sendSegmentItem',
  SegmentsItems = 'segmentsItems',
  SegmentTree = 'segmentTree',
  SaveSegmentSample = 'saveSegmentSample',

  BiTopPosts = 'biTopPosts',

  BiManiaEarnedPoints = 'biManiaEarnedPoints',

  AlbumNameEdit = 'albumNameEdit',
  PhotoNameEdit = 'photoNameEdit',
  AlbumCreate = 'albumCreate',
  PhotoCreate = 'photoCreate',

  BadgeCreateCategory = 'badgeCreateCategory',
  BadgeCreate = 'badgeCreate',
  BadgeCategoryEdit = 'badgeCategoryEdit',
  BadgeEdit = 'badgeEdit',

  Segmentation_ItemEdit = 'segmentation_ItemEdit',

  NewsSettings = 'newsSettings',
  PushNotificationSettings = 'pushNotificationSettings',

  LoyaltyStatusCreate = 'loyaltyStatusCreate',
  LoyaltyStatusCreateEn = 'loyaltyStatusCreateEn',
  LoyaltyStatusCreateEl = 'loyaltyStatusCreateEl',
  LoyaltyStatusEdit = 'loyaltyStatusEdit',
  AppSettings = 'appSettings',

  QuizForm = 'quizForm',
  QuizFormQuestion = 'quizFormQuestion',

  MailingTemplateGroupAdd = 'mailingTemplateGroupAdd',
  SaveSegment = 'saveSegment',
  EditSegments = 'editSegments',
  MailingTemplateGroup = 'mailingTemplateGroup',
  MailingTemplateGroupEdit = 'mailingTemplateGroupEdit',

  MailingTemplateAdd = 'mailingTemplateAdd',
  MailingTemplateEdit = 'mailingTemplateEdit',

  GtoFilter = 'gtoFilter',

  // BiToSegments = 'biToSegments',
  FromBiClients_AllClients = 'fromBiClients_AllClients',
  FromBiClients_NewClients = 'fromBiClients_NewClients',
  FromBiClients_Male = 'fromBiClients_Male',
  FromBiClients_Female = 'fromBiClients_Female',
  FromBiRealized_TotalTickets = 'fromBiRealized_TotalTickets',
  FromBiRealized_TotalSubscription = 'fromBiRealized_TotalSubscription',
  FromBiRealized_TotalInvitation = 'fromBiRealized_TotalInvitation',
  FromBiRealized_TotalTotal = 'fromBiRealized_TotalTotal',
  FromBiVisits_TotalTickets = 'fromBiVisits_TotalTickets',
  FromBiVisits_TotalSubscription = 'fromBiVisits_TotalSubscription',
  FromBiVisits_TotalInvitation = 'fromBiVisits_TotalInvitation',
  FromBiVisits_TotalTotal = 'fromBiVisits_TotalTotal',

  FromBiConsolidatedEntering_Imports = 'fromBiConsolidatedEntering_Imports',
  FromBiConsolidatedEntering_Tickets = 'fromBiConsolidatedEntering_Tickets',
  FromBiConsolidatedEntering_Loyal = 'fromBiConsolidatedEntering_Loyal',
  FromBiConsolidatedEntering_Polls = 'fromBiConsolidatedEntering_Polls',
  FromBiConsolidatedEntering_Quizes = 'fromBiConsolidatedEntering_Quizes',
  FromBiConsolidatedEntering_Sales = 'fromBiConsolidatedEntering_Sales',

  FromBiGeography_City = 'fromBiGeography_City',
  FromBiGeography_Region = 'fromBiGeography_Region',
  FromBiGeography_Country = 'fromBiGeography_Country',
  FromBiGeography_World = 'fromBiGeography_World',
  FromBiGeography_Unknown = 'fromBiGeography_Unknown',

  FromBiProfile_FioContact = 'fromBiProfile_FioContact',
  FromBiProfile_Email = 'fromBiProfile_Email',
  FromBiProfile_NameEmail = 'fromBiProfile_NameEmail',
  FromBiProfile_Phone = 'fromBiProfile_Phone',
  FromBiProfile_NamePhone = 'fromBiProfile_NamePhone',

  FromBiActivityClients_Total = 'fromBiActivityClients_Total',
  FromBiActivityClients_Buyers = 'fromBiActivityClients_Buyers',
  FromBiActivityClients_Loyal = 'fromBiActivityClients_Loyal',
  FromBiActivityClients_Nonactive = 'fromBiActivityClients_Nonactive',

  FromBiSalesSummary_Total = 'fromBiSalesSummary_Total',
  FromBiSalesSummary_Presales = 'fromBiSalesSummary_Presales',
  FromBiSalesSummary_MatchDay = 'fromBiSalesSummary_MatchDay',
  FromBiSalesSummary_Online = 'fromBiSalesSummary_Online',
  FromBiSalesSummary_Offline = 'fromBiSalesSummary_Offline',

  FromBiSalesLifeCycle_Attracted = 'fromBiSalesLifeCycle_Attracted',
  FromBiSalesLifeCycle_Interested = 'fromBiSalesLifeCycle_Interested',
  FromBiSalesLifeCycle_Buyers = 'fromBiSalesLifeCycle_Buyers',
  FromBiSalesLifeCycle_Promising = 'fromBiSalesLifeCycle_Promising',
  FromBiSalesLifeCycle_Premium = 'fromBiSalesLifeCycle_Premium',
  FromBiSalesLifeCycle_left = 'fromBiSalesLifeCycle_left',
}

export interface IUpdateForm_ErrorStores {
  key: string;
  value: {};
}

export interface EFromAddLogs {}

export interface IForm_ErrorStore {
  login: {};
  mailingAdd: {};
  mailingEdit: {};
  eventCalendar: {};
  authRequest: {};
  passwordRecover: {};
  setNewPassword: {};
  clientCreate: {};
  clientEdit: {};
  eventEdit: {};
  eventAdd: {};
  profileEdit: {};
  fileImport: {
    file?: any;
  };
  userCreate: {};
  userEdit: {};
  pollGroupCreate: {};
  pollGroupAdd: {};
  pollForm: {};
  addPollRequest: {};
  externalPoll: {};
  quizForm: {};
  quizFormQuestion: {};

  mailingTemplateGroupAdd: {};
  mailingTemplateGroupEdit: {};

  mailingTemplateAdd: {};
  mailingTemplateEdit: {};

  gtoFilter: {};
  biToSegments: {};
}

export interface IActiveForm {
  formName: string | null;
}
