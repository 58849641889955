import { AxiosResponse } from 'axios';
import { createEvent, createStore, sample } from 'effector';
import { Api } from '../api';
import { API } from '../global/api';
import { getUrlParams } from '../helpers/url';
import { queryClient } from '../index';
import { IApiResponse_Core } from '../types/api';
import {
  EExternalPoll_QuestionType,
  IExternalPoll_Step1,
  IExternalPoll_Step2,
  IExternalPollStore,
  IExternalPollStore_PageBegin,
  IExternalPollStore_PageEnd,
} from '../types/externalPoll';
import { IForm_ExternalPollStep1, IForm_ExternalPollStep2 } from '../types/form/externalPoll';
import { IPoll_Question } from '../types/poll';
import { EForm_ErrorStore } from '../types/store/form';
import { setFormErrors, updateActiveForm } from './form';

export const hidePageBegin = () => updatePageBegin({ visible: false });

export const formSubmitStep1 = async (formData: IForm_ExternalPollStep1) => {
  updateActiveForm(EForm_ErrorStore.ExternalPoll);

  const params: { group: string } = getUrlParams();

  const externalPollData: IApiResponse_Core<IExternalPoll_Step1> | undefined = queryClient.getQueryData([
    'externalPoll',
    params.group,
  ]);

  if (!formData.subscription || !formData.policy) {
    const error: any = {};

    if (externalPollData?.data?.is_subscription_required && !formData.subscription) {
      error.subscription = [{ message: 'Необходимо подтвердить согласие' }];
    }

    if (!formData.policy) {
      error.policy = [{ message: 'Необходимо подтвердить согласие' }];
    }

    setFormErrors({
      key: EForm_ErrorStore.ExternalPoll,
      value: error,
    });

    return;
  }

  const { data }: AxiosResponse<IApiResponse_Core<{ form_id: number }>> = await Api.post(
    `${API.urlExternal}/external/poll/form/create/`,
    {
      email: formData.email,
      group_id: params.group,
    },
    {
      headers: {
        AppLoading: 'true',
      },
    }
  );

  if (data.error) return;

  updateFormId(data.data.form_id);
  updateStep(2);
};

export const formSubmitStep2 = async (postData: IForm_ExternalPollStep2) => {
  updateActiveForm(EForm_ErrorStore.ExternalPoll);

  const $formId = sample({
    source: $externalPoll,
    fn: s => s.formId,
  }).getState();

  const { data }: AxiosResponse<IApiResponse_Core<IExternalPoll_Step2>> = await Api.post(
    `${API.urlExternal}/external/poll/verification/`,
    {
      code: postData.code,
      form_id: $formId,
    },
    {
      headers: {
        AppLoading: 'true',
      },
    }
  );

  if (data.error) return;

  const formData = {};

  data.data.questions.forEach(question => {
    let value: [] | string = [];

    if (question.type === EExternalPoll_QuestionType.Simple) {
      value = '';
    }

    // @ts-ignore
    formData[question.id] = value;
  });

  updateStep3Form(formData);

  if (data.data.visible_start_page) {
    updatePageBegin({
      visible: data.data.visible_start_page,
      begin_description: data.data.begin_description || '',
      begin_image: data.data.begin_image || '',
      begin_title: data.data.begin_title || '',
    });
  }

  updateQuestions(data.data.questions);
  updateStep(3);
};

export const formSubmitStep3 = async () => {
  updateActiveForm(EForm_ErrorStore.ExternalPoll);

  const $formId = sample({
    source: $externalPoll,
    fn: s => s.formId,
  }).getState();

  const $answers = sample({
    source: $externalPoll,
    fn: s => s.step3Form,
  }).getState();

  const { data }: AxiosResponse<IApiResponse_Core<{ end_page: IExternalPollStore_PageEnd; saved: boolean }>> =
    await Api.post(
      `${API.urlExternal}/external/poll/answers/`,
      { form_id: $formId, answers: $answers },
      {
        headers: {
          AppLoading: 'true',
        },
      }
    );

  if (data.error || !data.data.saved) return;

  updatePageEnd(data.data.end_page);
  updateStep(4);
};

export const updateStep = createEvent<number>();
export const updateFormId = createEvent<number>();
export const updateQuestions = createEvent<IPoll_Question[]>();
export const updatePageEnd = createEvent<IExternalPollStore_PageEnd>();
export const updatePageBegin = createEvent<IExternalPollStore_PageBegin>();

export const updateStep3Form = createEvent<any>();

export const $externalPoll = createStore<IExternalPollStore>({
  step: 1,
  formId: null,
  questions: [],
  step3Form: {},
  pageBegin: {
    visible: false,
    begin_description: '',
    begin_image: '',
    begin_title: '',
  },
  pageEnd: {
    end_description: '',
    end_image: '',
    end_title: '',
    end_url: '',
  },
})
  .on(updateStep, (state, payload) => ({ ...state, step: payload }))
  .on(updateFormId, (state, payload) => ({ ...state, formId: payload }))
  .on(updateQuestions, (state, payload) => ({ ...state, questions: payload }))
  .on(updatePageEnd, (state, payload) => ({ ...state, pageEnd: payload }))
  .on(updatePageBegin, (state, payload) => ({ ...state, pageBegin: { ...state.pageBegin, ...payload } }))
  .on(updateStep3Form, (state, payload) => ({ ...state, step3Form: payload }));
