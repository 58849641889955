import { createEvent, createStore } from 'effector';
import { IModal_EditData, IModal_UpdateEditData, IModal_VisibleStore } from '../types/store/modals';

export const showModal = createEvent<string>();
export const hideModal = createEvent<string>();
export const hideAllModal = createEvent();

export const updateModalEditData = createEvent<IModal_UpdateEditData>();
export const cleanModalEditData = createEvent<string>();
export const resetModalEditData = createEvent();

export const $modalEditData = createStore<IModal_EditData>({
  references: null,
  users: null,
  roles: null,
  pollQuestions: null,
  shopCategory: null,
  shopProduct: null,
  encryption: null,
})
  .on(updateModalEditData, (state, payload) => ({
    ...state,
    [payload.key]: payload.value,
  }))
  .on(cleanModalEditData, (state, payload) => ({
    ...state,
    [payload]: null,
  }))
  .reset(resetModalEditData);

export const $modalVisible = createStore<IModal_VisibleStore>({
  tableVisibleRows: false,
  authRequest: false,
  callbackRequest: false,
  callbackRequestSuccess: false,
  clientEdit: false,
  clientCreate: false,
  passwordRecover: false,
  passwordRecoverSuccess: false,
  clientQuestionnaire: false,
  fileImport: false,
  ticketOwnersData: false,
  userCreate: false,
  userEdit: false,
  roleCreate: false,
  roleEdit: false,
  referenceCreate: false,
  referenceEdit: false,
  biFilter: false,
  summaryDataFilter: false,
  mailingTemplateGroupAdd: false,
  mailingTemplateGroupEdit: false,
  productCreate: false,
  categoryCreate: false,
  categoryShopEdit: false,
  productShopEdit: false,
  badgeCategoryCreate: false,
  badgeCreate: false,
  pushCreate: false,
})
  .on(showModal, (state, payload) => ({
    ...state,
    [payload]: true,
  }))
  .on(hideModal, (state, payload) => ({
    ...state,
    [payload]: false,
  }))
  .reset(hideAllModal);
