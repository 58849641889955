import { updateActiveForm } from './form';
import { EForm_ErrorStore } from '../types/store/form';
import { IApiResponse_Core } from '../types/api';
import { apiDelete, apiPost, apiPut } from '../api';
import { queryClient } from '../index';
import { hideModal } from './modal';
import { EModal_VisibleStore } from '../types/store/modals';
import {
  IBuildSegmentation_Indicators,
  IFormFieldsModalSegmentsData,
  IFormFieldsModalSegmentsItemData,
  ISaveSegmentSampleData,
  ISegment,
  ISegmentation_Tree,
} from '../types/segments';
import { createEvent, createStore } from 'effector';
import { ISegmentsItemStore, ISegmentsSampleStore, ISegmentsTreeNodeStore } from '../types/store/segmentsItem';
import { prepareFormData } from '../helpers/form';
import { addSegmentationStep, moveToNextSegmentationStep } from './segmentation';

export const updateSegmentation_NodesModalData = createEvent<ISegmentation_Tree>();
export const updateSegmentation_ActiveNodeId = createEvent<number>();
export const updateSegmentation_PanelData = createEvent<IBuildSegmentation_Indicators>();
export const clearSegmentation = createEvent();
export const $segmentsNodes = createStore<ISegmentsTreeNodeStore>({
  segmentsTreeNode: null,
  activeNodeId: null,
  panelData: null,
})
  .on(updateSegmentation_NodesModalData, (state, payload) => ({ ...state, segmentsTreeNode: payload }))
  .on(updateSegmentation_ActiveNodeId, (state, payload) => ({ ...state, activeNodeId: payload }))
  .on(updateSegmentation_PanelData, (state, payload) => ({ ...state, panelData: payload }))
  .on(clearSegmentation, (state) => ({
    segmentsTreeNode: null,
    activeNodeId: null,
    panelData: null,
  }))

export const saveSegmentationItem = async (data: IFormFieldsModalSegmentsData, reset?: any) => {
  updateActiveForm(EForm_ErrorStore.SegmentTree);

  let value1 = data.value_1?.constructor.name == 'Array' ? data.value_1.join(', ') : data.value_1;
  let value2 = data.value_2?.constructor.name == 'Array' ? data.value_2.join(', ') : data.value_2;
  let value3 = data.value_3?.constructor.name == 'Array' ? data.value_3.join(', ') : data.value_3;
  let value4 = data.value_4?.constructor.name == 'Array' ? data.value_4.join(', ') : data.value_4;
  let value5 = data.value_5?.constructor.name == 'Array' ? data.value_5.join(', ') : data.value_5;
  let value6 = data.value_6?.constructor.name == 'Array' ? data.value_6.join(', ') : data.value_6;

  const response: IApiResponse_Core<ISegmentation_Tree> = await apiPost({
    url: '/segment_nodes/',
    postData: {
      divide_1: data.divide_1,
      divide_2: data.divide_2,
      divide_3: data.divide_3,
      date_from_1: data.date_from_1,
      date_to_1: data.date_to_1,
      date_from_2: data.date_from_2,
      date_to_2: data.date_to_2,
      filter: data.filter,
      parent: data.parent,
      tree: data.tree,
      value_1: value1,
      value_2: value2,
      value_3: value3,
      value_4: value4,
      value_5: value5,
      value_6: value6,
    },
  });

  if (response.error) return;

  addSegmentationStep(response.data.nodes);
  moveToNextSegmentationStep();

  updateSegmentation_NodesModalData(response.data);

  reset();
  await queryClient.refetchQueries('segments');
  hideModal(EModal_VisibleStore.SegmentationModal);
};

// segmentItem
export const updateSegmentation_ItemData = createEvent<ISegment>();
export const $segmentItem = createStore<ISegmentsItemStore>({
  segmentItem: null,
}).on(updateSegmentation_ItemData, (state, payload) => ({ ...state, segmentItem: payload }));

export const sendSegmentItem = async (data: IFormFieldsModalSegmentsItemData, reset?: any) => {
  updateActiveForm(EForm_ErrorStore.SendSegmentItem);

  const response: IApiResponse_Core<ISegment> = await apiPost({
    url: '/segments/',
    postData: data,
  });

  if (response.error) return;

  updateSegmentation_ItemData(response.data);

  reset();
  await queryClient.refetchQueries('segment_item');
  hideModal(EModal_VisibleStore.SegmentationItem);
};

//saveSegmentSample
export const updateSegmentation_SampleData = createEvent<ISegmentation_Tree>();
export const $segmentSample = createStore<ISegmentsSampleStore>({
  segmentSample: null,
}).on(updateSegmentation_SampleData, (state, payload) => ({ ...state, segmentSample: payload }));

export const saveSegmentSample = async (data: ISaveSegmentSampleData) => {
  updateActiveForm(EForm_ErrorStore.SaveSegmentSample);

  const formData = { ...data };

  const postData = prepareFormData(formData);

  if (data) {
    postData.append('saved', 'true');
  }

  const response: IApiResponse_Core<ISegmentation_Tree> = await apiPut({
    url: `/segment_tree/${data.id}/`,
    postData,
  });

  if (response.error) return;

  updateSegmentation_SampleData(response.data);

  queryClient.setQueryData(['segments', response.data.id + ''], response.data);

  await queryClient.refetchQueries('segments');
  hideModal(EModal_VisibleStore.SegmentationSaveSample);
};

// segmentsPage
export const segmentRemoveRequest = async (id: number | undefined, callback?: () => void) => {
  if (confirm('Удалить сегмент?')) {
    const res = await apiDelete({
      url: `/segments/${id}/`,
    });

    if (res.element) return;

    await queryClient.refetchQueries('segments');
  }
  if (callback) {
    callback();
  }
};
