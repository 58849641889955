import { createEvent, createStore } from 'effector';
import { apiPut } from '../api';
import { findInArray } from '../helpers/find';
import { queryClient } from '../index';
import { IApiResponse_Core } from '../types/api';
import { IDictionary } from '../types/dictionary';
import { IForm_ProfileEdit } from '../types/form/profile';
import { IProfile } from '../types/profile';
import { EForm_ErrorStore } from '../types/store/form';
import { IProfileStore } from '../types/store/user';
import { updateActiveForm } from './form';

export const editProfileRequest = async (data: IForm_ProfileEdit) => {
  updateActiveForm(EForm_ErrorStore.ProfileEdit);

  const dictionaryQueryData = queryClient.getQueryData<IDictionary>('dictionary');

  if (data.image) {
    const postData = new FormData();
    postData.append('logo', data.image[0]);

    const res = await apiPut({
      url: `/instances/${data.id}/`,
      postData,
    });

    if (res.error) return;
  }

  if (data.user_agreement) {
    const postData = new FormData();
    postData.append('user_agreement', data.user_agreement);

    const res = await apiPut({
      url: `/instances/${data.id}/`,
      postData,
    });

    if (res.error) return;
  }

  const response: IApiResponse_Core<IProfile> = await apiPut({
    url: `/instances/${data.id}/`,
    postData: {
      email: data.email,
      full_name: data.full_name,
      id: data.id,
      inn: data.inn,
      kpp: data.kpp,
      name: data.name,
      mailing_sender_email: data.mailing_sender_email,
      ogrn: data.ogrn,
      site: data.site,
      timezone: data.timezone,
      sms_service: data.sms_service,
      sms_login: data.sms_login,
      sms_token: data.sms_token,
      sms_sender_name: data.sms_sender_name,
      polls_smtp: data.polls_smtp,
      polls_port: data.polls_port,
      polls_login: data.polls_login,
      polls_password: data.polls_password,
      mailing_sender_name: data.mailing_sender_name,
      mailing_token: data.mailing_token,
      sendpulse_client_id: data.sendpulse_client_id,
      sendpulse_client_secret: data.sendpulse_client_secret,
      mailing_service: data.mailing_service,
      phone: data.phone?.replace(/\D/g, ''),
      colorscheme: findInArray({
        arr: dictionaryQueryData?.colorscheme,
        value: Number(data.colorscheme),
      }),
      activity_type: findInArray({
        arr: dictionaryQueryData?.activity_type,
        value: Number(data.activity_type),
      }),
      vk_link: data.vk_link,
      twitter_link: data.twitter_link,
      youtube_link: data.youtube_link,
      instagram_link: data.instagram_link,
      facebook_link: data.facebook_link,
    },
  });

  if (response.error) return;

  updateProfile(response.data);
};

export const updateProfile = createEvent<IProfile>();
export const resetProfile = createEvent();

export const $profile = createStore<IProfileStore>({
  profile: null,
})
  .on(updateProfile, (state, payload) => ({
    ...state,
    profile: payload,
  }))
  .reset(resetProfile);
