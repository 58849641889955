import { createEvent, createStore } from 'effector';
import { apiPost } from '../api';
import { IAppStore, ICallbackRequestProps } from '../types/store/app';
import { EForm_ErrorStore } from '../types/store/form';
import { EModal_VisibleStore } from '../types/store/modals';
import { setFormErrors, updateActiveForm } from './form';
import { hideAllModal, showModal } from './modal';

export const callbackRequest = async (props: ICallbackRequestProps) => {
  updateActiveForm(EForm_ErrorStore.AuthRequest);

  if (!props.data.agree) {
    setFormErrors({
      key: EForm_ErrorStore.AuthRequest,
      value: { agree: [{ message: 'Необходимо подтвердить своё согласие' }] },
    });

    return;
  }

  const response = await apiPost({
    url: '/site_requests/',
    postData: {
      ...props.data,
      phone: props.data.phone.replace(/\D/g, ''),
      purpose: props.purpose,
    },
  });

  if (response.error) return;

  hideAllModal();
  showModal(EModal_VisibleStore.CallbackRequestSuccess);
};

export const updateApiLoading = createEvent<boolean>();
export const updateAppLoading = createEvent<boolean>();

export const $app = createStore<IAppStore>({
  apiLoading: false,
  appLoading: true,
})
  .on(updateApiLoading, (state, payload) => ({
    ...state,
    apiLoading: payload,
  }))
  .on(updateAppLoading, (state, payload) => ({
    ...state,
    appLoading: payload,
  }));
