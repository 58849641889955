import { lazy } from 'react';

export const MAIN_PAGES = {
  home: lazy(() => import('../components/pages/main/Bi/Home').then(({ HomePage }) => ({ default: HomePage }))),
  homeGto: lazy(() =>
    import('../components/pages/main/GtoBi/HomeGto').then(({ HomePageGto }) => ({ default: HomePageGto }))
  ),
  clients: lazy(() =>
    import('../components/pages/main/Clients/Clients').then(({ ClientsPage }) => ({ default: ClientsPage }))
  ),
  client: lazy(() =>
    import('../components/pages/main/Clients/Client/Client').then(({ ClientPage }) => ({ default: ClientPage }))
  ),
  events: lazy(() =>
    import('../components/pages/main/Events/Events').then(({ EventsPage }) => ({ default: EventsPage }))
  ),
  event: lazy(() =>
    import('../components/pages/main/Events/Event/Event').then(({ EventPage }) => ({ default: EventPage }))
  ),
  eventEdit: lazy(() =>
    import('../components/pages/main/Events/EventEdit').then(({ EventEditPage }) => ({ default: EventEditPage }))
  ),
  eventAdd: lazy(() =>
    import('../components/pages/main/Events/EventAdd').then(({ EventAdd }) => ({ default: EventAdd }))
  ),
  segmentation: lazy(() =>
    import('../components/pages/main/Segmentation/Segmentation').then(({ SegmentationPage }) => ({
      default: SegmentationPage,
    }))
  ),
  mailings: lazy(() =>
    import('../components/pages/main/Mailings/Mailings').then(({ MailingsPage }) => ({ default: MailingsPage }))
  ),
  mailingView: lazy(() =>
    import('../components/pages/main/Mailings/Mailing').then(({ MailingPage }) => ({ default: MailingPage }))
  ),
  mailingEdit: lazy(() =>
    import('../components/pages/main/Mailings/MailingEdit').then(({ MailingEditPage }) => ({
      default: MailingEditPage,
    }))
  ),
  mailingAdd: lazy(() =>
    import('../components/pages/main/Mailings/MailingAdd').then(({ MailingAddPage }) => ({
      default: MailingAddPage,
    }))
  ),
  mailingTriggers: lazy(() =>
    import('../components/pages/main/Mailings/Triggers/Triggers').then(({ Triggers }) => ({ default: Triggers }))
  ),
  mailingTrigger: lazy(() =>
    import('../components/pages/main/Mailings/Triggers/Trigger').then(({ Trigger }) => ({ default: Trigger }))
  ),
  mailingTriggerAdd: lazy(() =>
    import('../components/pages/main/Mailings/Triggers/TriggerAdd').then(({ MailingTriggerAdd }) => ({
      default: MailingTriggerAdd,
    }))
  ),
  mailingTriggerEdit: lazy(() =>
    import('../components/pages/main/Mailings/Triggers/TriggersEdit').then(({ TriggersEdit }) => ({
      default: TriggersEdit,
    }))
  ),
  profile: lazy(() => import('../components/pages/main/Profile').then(({ ProfilePage }) => ({ default: ProfilePage }))),
  eventsCalendar: lazy(() =>
    import('../components/pages/main/Events/EventsCalendar').then(({ EventsCalendarPage }) => ({
      default: EventsCalendarPage,
    }))
  ),
  ticketOwners: lazy(() =>
    import('../components/pages/main/Clients/TicketOwners').then(({ TicketOwnersPage }) => ({
      default: TicketOwnersPage,
    }))
  ),
  leads: lazy(() => import('../components/pages/main/Clients/Leads').then(({ LeadsPage }) => ({ default: LeadsPage }))),
  references: lazy(() =>
    import('../components/pages/main/Administration/References').then(({ ReferencesPage }) => ({
      default: ReferencesPage,
    }))
  ),
  referencesIn: lazy(() =>
    import('../components/pages/main/Administration/ReferencesIn').then(({ ReferencesInPage }) => ({
      default: ReferencesInPage,
    }))
  ),
  journal: lazy(() =>
    import('../components/pages/main/Administration/Journal').then(({ JournalPage }) => ({ default: JournalPage }))
  ),
  users: lazy(() =>
    import('../components/pages/main/Administration/Users').then(({ UsersPage }) => ({ default: UsersPage }))
  ),
  roles: lazy(() =>
    import('../components/pages/main/Administration/Roles').then(({ RolesPages }) => ({ default: RolesPages }))
  ),
  bus: lazy(() => import('../components/pages/main/Administration/Bus').then(({ BusPage }) => ({ default: BusPage }))),
  busIn: lazy(() =>
    import('../components/pages/main/Administration/BusIn').then(({ BusInPage }) => ({ default: BusInPage }))
  ),
  pollGroups: lazy(() =>
    import('../components/pages/main/Polls/PollGroups').then(({ PollGroupsPage }) => ({
      default: PollGroupsPage,
    }))
  ),
  pollGroupsAdd: lazy(() =>
    import('../components/pages/main/Polls/PollGroupsAdd').then(({ PollGroupsAddPage }) => ({
      default: PollGroupsAddPage,
    }))
  ),
  pollGroupsEdit: lazy(() =>
    import('../components/pages/main/Polls/PollGroupsEdit').then(({ PollGroupsEditPage }) => ({
      default: PollGroupsEditPage,
    }))
  ),
  pollsList: lazy(() =>
    import('../components/pages/main/Polls/PollGroup').then(({ PollGroupPage }) => ({
      default: PollGroupPage,
    }))
  ),
  poll: lazy(() =>
    import('../components/pages/main/Polls/Poll').then(({ PollPage }) => ({
      default: PollPage,
    }))
  ),
  pollAdd: lazy(() =>
    import('../components/pages/main/Polls/PollAdd').then(({ PollAddPage }) => ({
      default: PollAddPage,
    }))
  ),
  pollEdit: lazy(() =>
    import('../components/pages/main/Polls/PollEdit').then(({ PollEditPage }) => ({
      default: PollEditPage,
    }))
  ),
  questionnaireDirectories: lazy(() =>
    import('../components/pages/main/QuestionnaireDirectories').then(({ QuestionnaireDirectoriesPage }) => ({
      default: QuestionnaireDirectoriesPage,
    }))
  ),
  quiz: lazy(() =>
    import('../components/pages/main/Quiz/Quiz').then(({ QuizPage }) => ({
      default: QuizPage,
    }))
  ),
  quizes: lazy(() =>
    import('../components/pages/main/Quiz/Quizes').then(({ QuizesPage }) => ({
      default: QuizesPage,
    }))
  ),
  quizAdd: lazy(() =>
    import('../components/pages/main/Quiz/QuizAdd').then(({ QuizAddPage }) => ({
      default: QuizAddPage,
    }))
  ),
  quizEdit: lazy(() =>
    import('../components/pages/main/Quiz/QuizEdit').then(({ QuizEditPage }) => ({
      default: QuizEditPage,
    }))
  ),
  settingsApp: lazy(() =>
    import('../components/pages/main/LoyaltyProgram/SettingsApp/SettingsApp').then(({ SettingsAppPage }) => ({
      default: SettingsAppPage,
    }))
  ),
  shop: lazy(() =>
    import('../components/pages/main/LoyaltyProgram/Shop/Shop').then(({ ShopPage }) => ({
      default: ShopPage,
    }))
  ),
  promocodes: lazy(() =>
    import('../components/pages/main/LoyaltyProgram/Promocodes/Promocodes').then(({ PromocodesPage }) => ({
      default: PromocodesPage,
    }))
  ),
  media: lazy(() =>
    import('../components/pages/main/LoyaltyProgram/Media/Media').then(({ MediaPage }) => ({
      default: MediaPage,
    }))
  ),
  mediaList: lazy(() =>
    import('../components/pages/main/LoyaltyProgram/Media/MediaList').then(({ MediaListPage }) => ({
      default: MediaListPage,
    }))
  ),
  news: lazy(() =>
    import('../components/pages/main/LoyaltyProgram/News/News').then(({ NewsPage }) => ({
      default: NewsPage,
    }))
  ),
  newsOne: lazy(() =>
    import('../components/pages/main/LoyaltyProgram/News/Pages/OneNewsPage').then(({ OneNewsPage }) => ({
      default: OneNewsPage,
    }))
  ),

  mailingTemplate: lazy(() =>
    import('../components/pages/main/MailingTemplates/Template').then(({ MailingTemplatePage }) => ({
      default: MailingTemplatePage,
    }))
  ),
  mailingTemplateAdd: lazy(() =>
    import('../components/pages/main/MailingTemplates/TemplateAdd').then(({ MailingTemplateAddPage }) => ({
      default: MailingTemplateAddPage,
    }))
  ),
  mailingTemplateEdit: lazy(() =>
    import('../components/pages/main/MailingTemplates/TemplateEdit').then(({ MailingTemplateEditPage }) => ({
      default: MailingTemplateEditPage,
    }))
  ),

  mailingTemplateGroupList: lazy(() =>
    import('../components/pages/main/MailingTemplates/GroupList').then(({ MailingTemplateGroupListPage }) => ({
      default: MailingTemplateGroupListPage,
    }))
  ),
  mailingTemplateGroup: lazy(() =>
    import('../components/pages/main/MailingTemplates/Group').then(({ MailingTemplateGroupPage }) => ({
      default: MailingTemplateGroupPage,
    }))
  ),
  summaryData: lazy(() =>
    import('../components/pages/main/SummaryData/SummaryData/SummaryData').then(({ SummaryDataPage }) => ({
      default: SummaryDataPage,
    }))
  ),
  segments: lazy(() =>
    import('../components/pages/main/Segments/Segments').then(({ SegmentsPage }) => ({
      default: SegmentsPage,
    }))
  ),
  pushNotifications: lazy(() =>
    import('../components/pages/main/PushNotifications/PushNotifications').then(({ PushNotificationsPage }) => ({
      default: PushNotificationsPage,
    }))
  ),
  import: lazy(() =>
    import('../components/pages/main/Import/Import').then(({ Import }) => ({
      default: Import,
    }))
  ),
  test: lazy(() =>
    import('../components/pages/main/Test/Test').then(({ TestPage }) => ({
      default: TestPage,
    }))
  ),
};

export const AUTH_PAGES = {
  login: lazy(() => import('../components/pages/auth/Login').then(({ LoginPage }) => ({ default: LoginPage }))),
  passwordRecover: lazy(() =>
    import('../components/pages/auth/PasswordRecover').then(({ PasswordRecoverPage }) => ({
      default: PasswordRecoverPage,
    }))
  ),
};
