import { ReactNode, useRef } from 'react';
import { AppHeader } from '../common/AppHeader/AppHeader';
import { AppMenu } from '../common/AppMenu/AppMenu';

export interface IMainLayout {
  children: ReactNode;
}

export const AppLayout = ({ children }: IMainLayout) => {
  const mainWrapRef = useRef<HTMLDivElement>(null);
  const appMenuRef = useRef<HTMLDivElement>(null);

  const mobMenuCallback = () => {
    mainWrapRef.current?.classList.toggle('_menu-opened');

    if (window.innerWidth > 1199) {
      appMenuRef.current?.classList.toggle('_small');
    } else {
      appMenuRef.current?.classList.toggle('_active');
    }
  };

  return (
    <>
      <AppMenu ref={appMenuRef} />

      <div ref={mainWrapRef} id="main-wrap" className="d-flex flex-column _lk">
        <AppHeader mobMenuCallback={mobMenuCallback} />

        <div id="site-content">{children}</div>
      </div>
    </>
  );
};
