export const COLORS = {
  text: '#304156',
  accent: '#D3153D',
  text2: '#828D99',
  link: '#2992F3',
  bg: '#F3F5F9',
  pink: '#FF4A7A',
  primary: '#3686FF',
  green: '#00C292',
  white: '#fff',
};
