import { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AUTH_PAGES } from '../../global/pages';
import { Fallback } from '../common/Fallback';
import { AuthLayout } from '../layouts/Auth';

export const AuthStack = () => {
  return (
    <Suspense fallback={<Fallback />}>
      <AuthLayout>
        <Switch>
          <Route exact path="/" component={AUTH_PAGES.login} />
          <Route exact path="/recover" component={AUTH_PAGES.passwordRecover} />

          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </AuthLayout>
    </Suspense>
  );
};
