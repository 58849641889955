import { createEvent, createStore } from 'effector';
import { ISegmentation_TreeNode } from '../types/segments';

export interface SegmentationStore {
  activeStepIndex: number;
  steps: ISegmentation_TreeNode[];
}

export const addSegmentationStep = createEvent<ISegmentation_TreeNode>();
export const moveToPrevSegmentationStep = createEvent();
export const moveToNextSegmentationStep = createEvent();
export const clearSegmentationStep = createEvent();

export const $segmentation = createStore<SegmentationStore>({
  activeStepIndex: 0,
  steps: [],
})
  .on(addSegmentationStep, (state, payload) => ({
    ...state,
    steps: [...state.steps, ...[payload]],
  }))
  .on(moveToPrevSegmentationStep, (state, payload) => ({
    ...state,
    activeStepIndex: state.activeStepIndex - 1,
  }))
  .on(moveToNextSegmentationStep, (state, payload) => ({
    ...state,
    activeStepIndex: state.activeStepIndex + 1,
  }))
  .on(clearSegmentationStep, (state) => ({
    activeStepIndex: 0,
    steps: [],
  }));
