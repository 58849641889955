import { COLORS } from '../../../global/colors';
import { iconList } from './iconList';

export enum EIcons {
  Close = 'close',
  Eye = 'eye',
  EyeCrossed = 'eyeCrossed',
  Cog = 'cog',
  ChevronDown = 'chevronDown',
  Plus = 'plus',
  PaginationLeft = 'paginationLeft',
  PaginationRight = 'paginationRight',
  Calendar = 'calendar',
  Filter = 'filter',
  Bell = 'bell',
  SocialFb = 'socialFb',
  SocialTw = 'socialTw',
  SocialVk = 'socialVk',
  STVolleyball = 'stVolleyball',
  AuthIcon1 = 'authIcon1',
  AuthIcon2 = 'authIcon2',
  AuthIcon3 = 'authIcon3',
  Trash = 'trash',
  Pencil = 'pencil',
  Check = 'check',
  Search = 'search',
  Table = 'table',
  Crown = 'crown',
  Camera = 'camera',
  Picture = 'picture',
  Envelope = 'envelope',
  SegmentationViewTypeBar = 'segmentationViewTypeBar',
  SegmentationViewTypePie = 'segmentationViewTypePie',
  SegmentationViewTypeSegment = 'segmentationViewTypeSegment',
  SegmentationActionPrev = 'segmentationActionPrev',
  SegmentationActionNext = 'segmentationActionNext',
  SegmentationActionDelete = 'segmentationActionDelete',
  SegmentationActionDownload = 'segmentationActionDownload',
  SegmentationTabSegment = 'segmentationTabSegment',
  SegmentationTabArrow = 'segmentationTabArrow',
  SegmentationTabGrid = 'segmentationTabGrid',
  SegmentationTabInvite = 'segmentationTabInvite',
  CircleCheck = 'circleCheck',
  CircleError = 'circleError',
  Clock = 'clock',
}

export interface ICmpProps_Icon {
  size?: number;
  color?: string;
  name: string;
  className?: string;
  onClick?: any;
}

export const Icon = ({ size = 16, color = COLORS.text, name, ...props }: ICmpProps_Icon) => {
  // @ts-ignore
  if (!iconList[name]) return null;

  // @ts-ignore
  const { viewBox, data } = iconList[name];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox || '0 0 512 512'}
      height={size}
      width={size}
      fill={color}
      {...props}
    >
      {data}
    </svg>
  );
};
