import './AuthMobileMenu.scss';

export const AuthMobileMenu = () => {
  return (
    <div id="mobile-menu" className="scroll-y flex-column jc-between">
      <nav className="d-flex flex-column">
        <a href="#">Узнать больше</a>
        <a href="#">Заказать консультацию</a>
        <a href="#">Зарегестрироваться</a>
      </nav>

      <div className="language">
        <div className="selected link">RU</div>

        <div className="list">
          <div className="list-item">EN</div>
          <div className="list-item">RU</div>
        </div>
      </div>
    </div>
  );
};
