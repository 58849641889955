import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const getLocale = () => {
  i18n.use(initReactI18next).init({
    fallbackLng: 'ru',
    lng: 'ru',
    resources: {
      en: {
        translations: JSON.parse(localStorage.getItem('translation-en')),
      },
      ru: {
        translations: JSON.parse(localStorage.getItem('translation-ru')),
      },
      el: {
        translations: JSON.parse(localStorage.getItem('translation-gr')),
      },
    },
    ns: ['translations'],
    defaultNS: 'translations',
  });
};

getLocale();

i18n.languages = ['en', 'ru', 'el'];

export default i18n;
