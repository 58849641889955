export interface IAlert_Store {
  view: boolean;
  text: string;
  type: string;
}

export enum EAlert_Types {
  Error = 'error',
  Success = 'success',
}
