export const socialIcons = {
  socialFb: {
    viewBox: '0 0 18 18',
    data: (
      <>
        <path d="M10.226 15.546V9.575h2.042l.307-2.328h-2.35V5.76c0-.674.19-1.133 1.177-1.133h1.256V2.546a17.353 17.353 0 00-1.83-.091c-1.812 0-3.052 1.084-3.052 3.075v1.717H5.727v2.328h2.049v5.97h2.45z" />
        <mask id="prefix__a" maskUnits="userSpaceOnUse" x={5} y={2} width={8} height={14}>
          <path d="M10.226 15.546V9.575h2.042l.307-2.328h-2.35V5.76c0-.674.19-1.133 1.177-1.133h1.256V2.546a17.353 17.353 0 00-1.83-.091c-1.812 0-3.052 1.084-3.052 3.075v1.717H5.727v2.328h2.049v5.97h2.45z" />
        </mask>
      </>
    ),
  },
  socialTw: {
    viewBox: '0 0 18 18',
    data: (
      <>
        <path d="M16.364 4.61a5.7 5.7 0 01-1.639.449A2.865 2.865 0 0015.98 3.48a5.753 5.753 0 01-1.814.693 2.853 2.853 0 00-4.862 2.601 8.1 8.1 0 01-5.88-2.98 2.852 2.852 0 00.883 3.809 2.854 2.854 0 01-1.293-.36v.036c0 1.382.984 2.536 2.289 2.798a2.89 2.89 0 01-.752.1c-.184 0-.362-.018-.537-.053a2.855 2.855 0 002.665 1.982 5.724 5.724 0 01-3.544 1.22c-.23 0-.457-.015-.68-.04a8.066 8.066 0 004.373 1.284c5.249 0 8.118-4.348 8.118-8.118l-.01-.37c.561-.4 1.046-.902 1.428-1.474z" />
        <mask id="prefix__a" maskUnits="userSpaceOnUse" x={2} y={3} width={15} height={12}>
          <path d="M16.364 4.61a5.7 5.7 0 01-1.639.449A2.865 2.865 0 0015.98 3.48a5.753 5.753 0 01-1.814.693 2.853 2.853 0 00-4.862 2.601 8.1 8.1 0 01-5.88-2.98 2.852 2.852 0 00.883 3.809 2.854 2.854 0 01-1.293-.36v.036c0 1.382.984 2.536 2.289 2.798a2.89 2.89 0 01-.752.1c-.184 0-.362-.018-.537-.053a2.855 2.855 0 002.665 1.982 5.724 5.724 0 01-3.544 1.22c-.23 0-.457-.015-.68-.04a8.066 8.066 0 004.373 1.284c5.249 0 8.118-4.348 8.118-8.118l-.01-.37c.561-.4 1.046-.902 1.428-1.474z" />
        </mask>
      </>
    ),
  },
  socialVk: {
    viewBox: '0 0 18 18',
    data: (
      <path d="M16.157 12.946l-2.103.027s-.451.084-1.048-.3c-.786-.51-1.527-1.834-2.107-1.663-.584.175-.568 1.37-.568 1.37s.004.254-.13.391c-.144.149-.43.18-.43.18h-.943s-2.076.117-3.905-1.682c-1.995-1.96-3.756-5.85-3.756-5.85s-.1-.255.009-.377c.124-.137.463-.149.463-.149l2.248-.015s.214.034.363.137c.125.088.193.247.193.247s.363.868.847 1.655c.939 1.533 1.378 1.868 1.696 1.704.464-.24.327-2.168.327-2.168s.008-.7-.234-1.012c-.19-.243-.544-.312-.697-.33-.125-.016.08-.294.35-.42.403-.186 1.12-.197 1.967-.19.656.008.846.046 1.104.103.773.175.511.86.511 2.5 0 .525-.1 1.262.303 1.506.173.106.592.015 1.648-1.674.5-.802.874-1.742.874-1.742s.08-.167.21-.24c.129-.072.306-.05.306-.05l2.37-.014s.713-.08.825.224c.121.32-.266 1.061-1.233 2.279-1.587 1.997-1.765 1.81-.447 2.967 1.261 1.103 1.52 1.64 1.564 1.708.523.814-.577.878-.577.878z" />
    ),
  },
};
