import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getUrlParams } from '../helpers/url';

export const useUrlParams = () => {
  const location = useLocation();

  const [url, setUrl] = useState(getUrlParams());

  useEffect(() => {
    setUrl(getUrlParams());
  }, [location.search]);

  return url;
};
