import { IApiResponse_Core } from '../types/api';
import { IProfile } from '../types/profile';
import { apiGet } from './index';

interface IFetchProfile {
  headerList?: {};
}

export const fetchProfile = async ({ headerList = {} }: IFetchProfile): Promise<IProfile | null> => {
  const res: IApiResponse_Core<IProfile> = await apiGet({ url: '/instances/', headerList });

  return res.error ? null : res.data;
};
