import { useStoreMap } from 'effector-react';
import { createRef, forwardRef, memo, MouseEvent, Ref, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import arrowMiniIcon from 'static/imgs/icon/arrowMini.svg';
import logoWhite from 'static/imgs/logo-white.svg';
import { LEFT_MENU_LINKS, LEFT_MENU_LINKS_LENGTH } from '../../../global/leftMenu';
import { $profile } from '../../../store/profile';
import './AppMenu.scss';
import { queryClient } from '../../../index';
import { IDictionary } from '../../../types/dictionary';
import { fetchLangs } from '../../../api/languages';
import { useAppTranslation } from '../../../hooks/useAppTranslation';

export interface ILeftMenuLink {
  type: string;
  text: string;
  link?: string;
  icon: string;
  children?: undefined | ILeftMenuChildLink[];
}

export interface ILeftMenuChildLink {
  text: string;
  link: string;
}

const _AppMenu = (_: any, parentRef: Ref<HTMLDivElement>) => {
  const history = useHistory();

  const dictionaryQueryData = queryClient.getQueryData<IDictionary>('dictionary');

  const $profileData = useStoreMap($profile, s => s.profile);

  const menuRefList = useRef([]);

  const { t } = useAppTranslation();

  // @ts-ignore
  menuRefList.current = LEFT_MENU_LINKS.map((_, index) => (menuRefList.current[index] = createRef()));

  const onMouseOver = (event: MouseEvent<HTMLAnchorElement>) => {
    event.currentTarget.style.color = $profileData?.colorscheme?.menu_active_color || 'transparent';
  };

  const onMouseOut = (event: MouseEvent<HTMLAnchorElement>) => {
    event.currentTarget.style.color = $profileData?.colorscheme?.menu_color || 'transparent';
  };

  const renderMenuSubItem = (childLink: ILeftMenuChildLink) => {
    return (
      <li key={childLink.text}>
        <Link
          to={childLink.link}
          className="link"
          style={{
            color: $profileData?.colorscheme?.menu_color || '',
          }}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        >
          {t(childLink.text)}
        </Link>
      </li>
    );
  };

  const renderMenuItem = (link: ILeftMenuLink, index: number) => {
    const onClick = () => {
      if (link.link) {
        history.push({ pathname: link.link });
        return;
      }

      // @ts-ignore
      menuRefList.current[index].current.classList.toggle('active');
    };

    if (!dictionaryQueryData?.available_modules?.includes(link.type)) {
      return null;
    }

    return (
      <li
        key={link.text}
        ref={menuRefList.current[index]}
        className="has-submenu"
        style={{
          position: 'relative',
          zIndex: LEFT_MENU_LINKS_LENGTH - index,
        }}
      >
        <span className="menu-head link trns" onClick={onClick}>
          <img src={link.icon} alt={link.text} />

          <span
            className="menu-link-text"
            style={{
              color: $profileData?.colorscheme?.menu_color || '',
            }}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
          >
            {t(link.text)}
            {/*{link.text}*/}
            {/*{*/}
            {/*  findInArray({*/}
            {/*    arr: dictionaryQueryData?.locales,*/}
            {/*    value: translatesLocalStorageData,*/}
            {/*  }).name*/}
            {/*}*/}
            {/*{renderTranslates}*/}
          </span>

          {link.children && <img src={arrowMiniIcon} alt="" className="pos-abs-y chevron" />}
        </span>

        {link.children && (
          <ul
            style={{
              background: $profileData?.colorscheme?.menu_gradient_from || 'transparent',
            }}
          >
            {link.children.map(renderMenuSubItem)}
          </ul>
        )}
      </li>
    );
  };

  useEffect(() => {
    fetchLangs();
  }, []);

  return (
    <>
      <div
        ref={parentRef}
        id="lk-side-menu"
        style={{
          background: `linear-gradient(135deg, ${$profileData?.colorscheme?.menu_gradient_from} 0%, ${$profileData?.colorscheme?.menu_gradient_to} 100%)`,
        }}
      >
        <Link to="/" className="logo d-block trns">
          <img src={logoWhite} alt="" />
        </Link>
        <ul className="menu trns">{LEFT_MENU_LINKS.map(renderMenuItem)}</ul>
      </div>
    </>
  );
};

export const AppMenu = memo(forwardRef(_AppMenu));
