export const authIcons = {
  authIcon1: {
    viewBox: '0 0 57 57',
    data: (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.5 56C43.688 56 56 43.688 56 28.5S43.688 1 28.5 1 1 13.312 1 28.5 13.312 56 28.5 56zm0-16.5c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11z"
          fill="#D3153D"
          stroke="#D3153D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.958 47.888A27.413 27.413 0 0055.956 28.5c0-15.172-12.287-27.475-27.454-27.5L28.5 17.5c6.055.024 10.956 4.94 10.956 11 0 3.009-1.208 5.735-3.165 7.721l11.667 11.667z"
          fill="#fff"
          stroke="#D3153D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.998 47.888A27.413 27.413 0 011 28.5C1 13.328 13.287 1.025 28.454 1l.002 16.5c-6.055.024-10.956 4.94-10.956 11 0 3.009 1.208 5.735 3.165 7.721L8.998 47.888z"
          fill="#F7C5D0"
          stroke="#D3153D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
  },
  authIcon2: {
    viewBox: '0 0 57 57',
    data: (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.715 13.752C46.835 6.086 38.261 1 28.5 1 13.312 1 1 13.312 1 28.5S13.312 56 28.5 56 56 43.688 56 28.5a27.456 27.456 0 00-2.351-11.143"
          fill="#fff"
        />
        <path
          d="M51.715 13.752C46.835 6.086 38.261 1 28.5 1 13.312 1 1 13.312 1 28.5S13.312 56 28.5 56 56 43.688 56 28.5a27.456 27.456 0 00-2.351-11.143"
          stroke="#D3153D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.444 32.625a4.125 4.125 0 100-8.25 4.125 4.125 0 000 8.25z"
          fill="#D3153D"
          stroke="#D3153D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.15 24.743V8.153a1.65 1.65 0 10-3.3 0v16.541a4.113 4.113 0 011.594-.319 4.11 4.11 0 011.706.368z"
          fill="#D3153D"
          stroke="#D3153D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M32.618 28.5h15.313-15.313z" fill="#D3153D" />
        <path d="M32.618 28.5h15.313" stroke="#D3153D" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M46.986 13.523l5.253.738.738-5.253" fill="#D3153D" />
        <path d="M46.986 13.523l5.253.738.738-5.253" stroke="#D3153D" strokeLinecap="round" strokeLinejoin="round" />
      </>
    ),
  },
  authIcon3: {
    viewBox: '0 0 57 57',
    data: (
      <>
        <path d="M56 1H1v38.5h55V1z" fill="#fff" stroke="#D3153D" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M33.45 13.65h17.6-17.6z" fill="#F7C5D0" />
        <path d="M33.45 13.65h17.6" stroke="#D3153D" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M33.45 20.25h17.6-17.6z" fill="#F7C5D0" />
        <path d="M33.45 20.25h17.6" stroke="#D3153D" strokeLinecap="round" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M33.45 26.85h11-11z" fill="#F7C5D0" />
        <path d="M33.45 26.85h11" stroke="#D3153D" strokeLinecap="round" strokeLinejoin="round" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.364 15.962c-.203.13-.585.939-.412 1.65.257 1.056 1.17 1.468 1.17 1.468s1.43 1.95 1.43 3.085 0 1.575-.384 2.522c-.383.946-7.899 2.08-8.218 7.66h10.94l11.06.002c-.32-5.58-7.835-6.714-8.218-7.66-.383-.947-.383-1.388-.383-2.523 0-1.134 1.429-3.085 1.429-3.085s.913-.412 1.17-1.468c.172-.71-.21-1.52-.412-1.65.542-1.708 1.623-7.598-4.526-7.81a.46.46 0 00-.082-.002c-6.187.213-5.106 6.103-4.564 7.811z"
          fill="#D3153D"
          stroke="#D3153D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.75 48.3h-16.5a3.85 3.85 0 000 7.7h16.5a3.85 3.85 0 100-7.7z"
          fill="#D3153D"
          stroke="#D3153D"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </>
    ),
  },
};
