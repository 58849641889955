import { IForm_UserEdit } from '../form/users';
import { IPoll_Question } from '../poll';
import { IRoles_Item } from '../roles';
import { IForm_CategoryEdit } from '../form/category';
import { IForm_ShopProductEdit } from '../form/product';

export enum EModal_VisibleStore {
  TableVisibleRows = 'tableVisibleRows',
  AuthRequest = 'authRequest',
  CallbackRequest = 'callbackRequest',
  CallbackRequestSuccess = 'callbackRequestSuccess',
  ClientEdit = 'clientEdit',
  ClientCreate = 'clientCreate',
  PasswordRecover = 'passwordRecover',
  PasswordRecoverSuccess = 'passwordRecoverSuccess',
  ClientQuestionnaire = 'clientQuestionnaire',
  FileImport = 'fileImport',
  SaveSegment = 'saveSegment',
  EditSegments = 'editSegments',
  TicketOwnersData = 'ticketOwnersData',
  TicketOwnersCountEvents = 'ticketOwnersCountEvents',
  UserCreate = 'createUser',
  UserEdit = 'userEdit',
  RoleCreate = 'roleCreate',
  RoleEdit = 'roleEdit',
  ReferenceCreate = 'referenceCreate',
  ReferenceEdit = 'referenceEdit',
  PollQuestions = 'pollQuestions',

  AddPollQuestion = 'addPollQuestion',
  AddPollAnswer = 'addPollAnswer',

  ProductCreate = 'productCreate',
  CategoryCreate = 'categoryCreate',
  CategoryShopEdit = 'categoryShopEdit',
  ProductShopEdit = 'productShopEdit',
  PushCreate = 'pushCreate',
  PushEdit = 'pushEdit',
  BannerCreate = 'bannerCreate',
  BannerEdit = 'bannerEdit',
  BadgeCategoryCreate = 'badgeCategoryCreate',
  BadgeCreate = 'badgeCreate',
  BadgeCategoryEdit = 'badgeCategoryEdit',
  BadgeEdit = 'badgeEdit',
  LoyaltyStatusEdit = 'loyaltyStatusEdit',
  LoyaltyStatusCreate = 'loyaltyStatusCreate',

  SegmentationModal = 'segmentationModal',
  SegmentationItem = 'segmentationItem',
  SegmentationSaveSample = 'segmentationSaveSample',

  CreatePromoCode = 'createPromoCode',

  EventAddPlayer = 'eventAddPlayer',
  EventAddHockeyTeam = 'eventAddHockeyTeam',
  EventLogsAddHockey = 'eventLogsAddHockey',
  EventAddPlayerHockey = 'eventAddPlayerHockey',
  EventAddTrainer = 'eventAddTrainer',
  EventAddVolleyballPlayer = 'eventAddVolleyballPlayer',

  AlbumEdit = 'albumEdit',
  PhotoEdit = 'photoEdit',
  AlbumCreate = 'albumCreate',
  PhotoCreate = 'photoCreate',

  SummaryDataFilter = 'summaryDataFilter',
  BiFilter = 'biFilter',
  BiFilterDate = 'biFilterDate',

  BiManiaEarnedPoints = 'biManiaEarnedPoints',

  MailingTemplateGroupAdd = 'mailingTemplateGroupAdd',
  MailingTemplateGroup = 'mailingTemplateGroup',
  MailingTemplateAdd = 'mailingTemplateAdd',
  MailingAdd = 'mailingAdd',
  MailingTemplateGroupEdit = 'mailingTemplateGroupEdit',

  // BiToSegments = 'biToSegments',
  FromBiClients_AllClients = 'fromBiClients_AllClients',
  FromBiClients_NewClients = 'fromBiClients_NewClients',
  FromBiClients_Male = 'fromBiClients_Male',
  FromBiClients_Female = 'fromBiClients_Female',
  FromBiRealized_TotalTickets = 'fromBiRealized_TotalTickets',
  FromBiRealized_TotalSubscription = 'fromBiRealized_TotalSubscription',
  FromBiRealized_TotalInvitation = 'fromBiRealized_TotalInvitation',
  FromBiRealized_TotalTotal = 'fromBiRealized_TotalTotal',
  FromBiVisits_TotalTickets = 'fromBiVisits_TotalTickets',
  FromBiVisits_TotalSubscription = 'fromBiVisits_TotalSubscription',
  FromBiVisits_TotalInvitation = 'fromBiVisits_TotalInvitation',
  FromBiVisits_TotalTotal = 'fromBiVisits_TotalTotal',

  FromBiConsolidatedEntering_Imports = 'fromBiConsolidatedEntering_Imports',
  FromBiConsolidatedEntering_Tickets = 'fromBiConsolidatedEntering_Tickets',
  FromBiConsolidatedEntering_Loyal = 'fromBiConsolidatedEntering_Loyal',
  FromBiConsolidatedEntering_Polls = 'fromBiConsolidatedEntering_Polls',
  FromBiConsolidatedEntering_Quizes = 'fromBiConsolidatedEntering_Quizes',
  FromBiConsolidatedEntering_Sales = 'fromBiConsolidatedEntering_Sales',

  FromBiGeography_City = 'fromBiGeography_City',
  FromBiGeography_Region = 'fromBiGeography_Region',
  FromBiGeography_Country = 'fromBiGeography_Country',
  FromBiGeography_World = 'fromBiGeography_World',
  FromBiGeography_Unknown = 'fromBiGeography_Unknown',

  FromBiProfile_FioContact = 'fromBiProfile_FioContact',
  FromBiProfile_Email = 'fromBiProfile_Email',
  FromBiProfile_NameEmail = 'fromBiProfile_NameEmail',
  FromBiProfile_Phone = 'fromBiProfile_Phone',
  FromBiProfile_NamePhone = 'fromBiProfile_NamePhone',

  FromBiActivityClients_Total = 'fromBiActivityClients_Total',
  FromBiActivityClients_Buyers = 'fromBiActivityClients_Buyers',
  FromBiActivityClients_Loyal = 'fromBiActivityClients_Loyal',
  FromBiActivityClients_Nonactive = 'fromBiActivityClients_Nonactive',

  FromBiSalesSummary_Total = 'fromBiSalesSummary_Total',
  FromBiSalesSummary_Presales = 'fromBiSalesSummary_Presales',
  FromBiSalesSummary_MatchDay = 'fromBiSalesSummary_MatchDay',
  FromBiSalesSummary_Online = 'fromBiSalesSummary_Online',
  FromBiSalesSummary_Offline = 'fromBiSalesSummary_Offline',

  FromBiSalesLifeCycle_Attracted = 'fromBiSalesLifeCycle_Attracted',
  FromBiSalesLifeCycle_Interested = 'fromBiSalesLifeCycle_Interested',
  FromBiSalesLifeCycle_Buyers = 'fromBiSalesLifeCycle_Buyers',
  FromBiSalesLifeCycle_Promising = 'fromBiSalesLifeCycle_Promising',
  FromBiSalesLifeCycle_Premium = 'fromBiSalesLifeCycle_Premium',
  FromBiSalesLifeCycle_left = 'fromBiSalesLifeCycle_left',
}

export interface IModal_UpdateEditData {
  key: string;
  value: any;
}

export enum EModal_EditData {
  Roles = 'roles',
  Users = 'users',
  References = 'references',
  PollQuestions = 'pollQuestions',
}

export interface IModal_EditData {
  roles: IRoles_Item | null;
  users: IForm_UserEdit | null;
  references: any;
  pollQuestions: IPoll_Question | null;
  shopCategory: IForm_CategoryEdit | null;
  shopProduct: IForm_ShopProductEdit | null;
  encryption: any;
}

export interface IModal_VisibleStore {
  tableVisibleRows: boolean;
  authRequest: boolean;
  callbackRequest: boolean;
  callbackRequestSuccess: boolean;
  clientEdit: boolean;
  clientCreate: boolean;
  passwordRecover: boolean;
  passwordRecoverSuccess: boolean;
  clientQuestionnaire: boolean;
  fileImport: boolean;
  ticketOwnersData: boolean;
  userCreate: boolean;
  userEdit: boolean;
  roleCreate: boolean;
  roleEdit: boolean;
  referenceCreate: boolean;
  referenceEdit: boolean;

  productCreate: boolean;
  categoryCreate: boolean;
  categoryShopEdit: boolean;
  productShopEdit: boolean;
  pushCreate: boolean;

  badgeCategoryCreate: boolean;
  badgeCreate: boolean;
  summaryDataFilter: boolean;
  biFilter: boolean;

  mailingTemplateGroupAdd: boolean;
  mailingTemplateGroupEdit: boolean;
}
