import Lottie from 'react-lottie-player/dist/LottiePlayerLight';
import animation from './spinner.json';

export interface ICmpProps_Spinner {
  width?: number;
  height?: number;
}

export const Spinner = ({ width = 128, height = 128 }: ICmpProps_Spinner) => {
  return (
    <Lottie
      loop
      play
      animationData={animation}
      style={{
        margin: '0 auto',
        width,
        height,
      }}
    />
  );
};
