import adminIcon from 'static/imgs/icon/lk-side-menu/admin.svg';
import biIcon from 'static/imgs/icon/lk-side-menu/bi.svg';
import clientsIcon from 'static/imgs/icon/lk-side-menu/clients.svg';
import communicationsIcon from 'static/imgs/icon/lk-side-menu/communications.svg';
import eventsIcon from 'static/imgs/icon/lk-side-menu/events.svg';
import giftIcon from 'static/imgs/icon/lk-side-menu/gift.svg';
import gtoIcon from 'static/imgs/icon/lk-side-menu/gto.svg';
import questioningIcon from 'static/imgs/icon/lk-side-menu/questioning.svg';
import quizesIcon from 'static/imgs/icon/lk-side-menu/quizesIcon.svg';
import segmentIcon from 'static/imgs/icon/lk-side-menu/segment.svg';
// import ticketIcon from 'static/imgs/icon/lk-side-menu/ticket.svg';

export const LEFT_MENU_LINKS = [
  {
    type: 'bi',
    text: 'BI',
    icon: biIcon,
    link: '/',
  },
  {
    type: 'bi_gto',
    text: 'GTO BI',
    icon: gtoIcon,
    link: '/gto',
  },
  {
    type: 'clients',
    text: 'Клиенты',
    icon: clientsIcon,
    children: [
      {
        text: 'Список клиентов',
        link: '/clients',
      },
      {
        text: 'Владельцы абонементов',
        link: '/ticket-owners',
      },
      {
        text: 'Лиды',
        link: '/leads',
      },
    ],
  },
  {
    type: 'segments',
    text: 'Сегментация',
    icon: segmentIcon,
    children: [
      {
        text: 'Построение сегментации',
        link: '/segmentations',
      },
      {
        text: 'Сегменты',
        link: '/segments',
      },
    ],
  },
  {
    type: 'polls',
    text: 'Анкетирование',
    icon: questioningIcon,
    children: [
      {
        text: 'Группа анкет',
        link: '/poll-groups',
      },
      // {
      //   text: 'Справочники анкет',
      //   link: '/questionnaire-directories',
      // },
    ],
  },
  {
    type: 'quizes',
    text: 'Викторины',
    icon: quizesIcon,
    link: '/quizes',
  },
  // {
  // type: '',
  //   text: 'Промобилеты',
  //   icon: ticketIcon,
  //   link: '#',
  // },
  {
    type: 'communication',
    text: 'Коммуникации',
    icon: communicationsIcon,
    children: [
      {
        text: 'Рассылки',
        link: '/mailings',
      },
      {
        text: 'Шаблоны',
        link: '/mailing-template-groups',
      },
      {
        text: 'Сводные данные',
        link: '/summary-data',
      },
      {
        text: 'Push уведомления',
        link: '/push-notifications',
      },
      {
        text: 'Триггеры',
        link: '/mailing-triggers',
      },
    ],
  },
  {
    type: 'events',
    text: 'Мероприятия',
    icon: eventsIcon,
    link: '/events',
  },
  {
    type: 'admin',
    text: 'Администрирование',
    icon: adminIcon,
    children: [
      {
        text: 'Справочники',
        link: '/references',
      },
      {
        text: 'Журналирование',
        link: '/journal',
      },
      {
        text: 'Пользователи',
        link: '/users',
      },
      {
        text: 'Роли',
        link: '/roles',
      },
      {
        text: 'Шина событий',
        link: '/bus',
      },
    ],
  },
  {
    type: 'loyalty',
    text: 'Программа лояльности',
    icon: giftIcon,
    children: [
      {
        text: 'Настройки приложения',
        link: '/settings-app',
      },
      {
        text: 'Магазин',
        link: '/shop',
      },
      {
        text: 'Промокоды ПЛ',
        link: '/promocodes',
      },
      {
        text: 'Медиа',
        link: '/media',
      },
      {
        text: 'Новости',
        link: '/news',
      },
    ],
  },
];
export const LEFT_MENU_LINKS_LENGTH = LEFT_MENU_LINKS.length;
