import cn from 'classnames';
import { useStoreMap } from 'effector-react';
import { ReactNode } from 'react';
import { $modalVisible, hideModal } from '../../../store/modal';
import { EIcons, Icon } from '../Icon/Icon';
import { useHistory, useLocation } from 'react-router-dom';
import { $segmentsNodes } from '../../../store/segments';

export interface ICmpProps_Modal {
  id?: string;
  wrapperClasses?: string;
  modalClasses?: string;
  storeKey: string;
  children: ReactNode;
  onCloseCallback?: () => void;
  resetForm?: () => void;
  setValue?: () => void;
}

export const Modal = (props: ICmpProps_Modal) => {
  // @ts-ignore
  const $visible = useStoreMap($modalVisible, s => s[props.storeKey]);
  const { pathname } = useLocation();
  const history = useHistory();

  const $segmentsStore = useStoreMap($segmentsNodes, s => ({
    activeNodeId: s.activeNodeId,
    panelData: s.panelData,
    treeId: s.segmentsTreeNode?.id,
  }));

  const closeModal = () => {
    if (props.storeKey === 'segmentationModal') {
      if (props.resetForm && props.setValue) {
        props.resetForm();
        props.setValue();
      }
    }

    hideModal(props.storeKey);
    history.push(pathname);

    if (props.onCloseCallback) {
      props.onCloseCallback();
    }
  };

  return (
    <div
      id={props.id}
      className={cn('modal scroll-y d-flex ai-start jc-center', props.modalClasses, {
        'd-none': !$visible,
      })}
    >
      <div className="shadow" onClick={closeModal} />

      <div className={cn('wrapper', props.wrapperClasses)}>
        <div className="modal-close" onClick={closeModal}>
          <Icon size={12} name={EIcons.Close} className="pos-abs" />
        </div>

        {props.children}
      </div>
    </div>
  );
};
