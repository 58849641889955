import { createEvent, createStore } from 'effector';
import { IChangeActiveTabProps, ITabsStore } from '../types/store/tabs';

export const changeActiveTab = createEvent<IChangeActiveTabProps>();
export const resetActiveTab = createEvent();

export const $tabs = createStore<ITabsStore>({
  mailingTemplate: 0,
  home: 0,
  client: 0,
  event: 0,
  events: 0,
  mailingAdd: 0,
  mailingEdit: 0,
  segmentation: 0,
  segmentationModals: 0,
  topPosts: 0,
  profile: 0,
  profileMailing: 0,
  clientEditModal: 0,
  clientCreateModal: 0,
  promocodes: 0,
  pollGroupAdd: 0,
  poll: 0,
  pollForm: 0,
  mailing: 0,
  settingsApp: 0,
  settingsAppTab3: 0,
  shop: 0,
  news: 0,
  pushNotifications: 0,
  quizForm: 0,

  badgeCreateCategoryModal: 0,
  badgeCreateModal: 0,
  categoryCreateModal: 0,
  productCreateModal: 0,
  segmentsItems: 0,
  albumCreateModal: 0,
  photoCreateModal: 0,

  mailingTemplateAdd: 0,
  mailingTemplateEdit: 0,
  lang: 0,
})
  .on(changeActiveTab, (state, payload) => ({ ...state, [payload.key]: payload.value }))
  .reset(resetActiveTab);
