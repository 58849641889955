import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { sample } from 'effector';
import Cookies from 'js-cookie';
import { API } from '../global/api';
import { errorHandler } from '../services/errorHandler';
import { updateAlert } from '../store/alert';
import { updateApiLoading } from '../store/app';
import { $activeForm, cleanFormErrors, setFormErrors, updateActiveForm } from '../store/form';
import { IApiRequest_Get, IApiRequest_Post } from '../types/api';
import { EAlert_Types } from '../types/store/alert';

export const Api = axios.create({
  baseURL: API.url,
  timeout: API.timeout,
});

Api.interceptors.request.use((config: AxiosRequestConfig) => {
  const authToken = Cookies.get('authToken');

  if (authToken) {
    // @ts-ignore
    config.headers.Authorization = `Token ${authToken}`;
  }

  const $activeFormName = sample({
    source: $activeForm,
    fn: s => s.formName,
  }).getState();

  if ($activeFormName) {
    cleanFormErrors($activeFormName);
  }

  return config;
});

Api.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data.error) {
      if (response.data.status === 400) {
        const activeFormName = sample({
          source: $activeForm,
          fn: s => s.formName,
        }).getState();

        if (activeFormName) {
          setFormErrors({
            key: activeFormName,
            value: response.data.data,
          });
        }

        updateAlert({
          text: 'Ошибка валидации, проверьте корректность отправленных данных.',
          view: true,
          type: EAlert_Types.Error,
        });

        if (response.data.data.non_field_errors) {
          updateAlert({
            view: true,
            text: response.data.data.non_field_errors?.[0]?.message,
            type: EAlert_Types.Error,
          });
        }
      } else if (response.data.data.message) {
        updateAlert({
          view: true,
          text: response.data.data.message,
          type: EAlert_Types.Error,
        });
      } else {
        updateAlert({
          view: true,
          text: 'Произошла ошибка, попробуйте позже.',
          type: EAlert_Types.Error,
        });
      }
    }

    updateActiveForm(null);

    return response;
  },
  (error: AxiosError) => {
    console.log(`Response error: ${error}`);

    errorHandler({
      errorText: error.message,
    });

    return Promise.reject(error);
  }
);

export const apiPost = async ({
  url,
  postData,
  headerList = {},
  loading = true,
  loadingHide = true,
  viewAlert = true,
}: IApiRequest_Post) => {
  try {
    if (loading) {
      updateApiLoading(true);
    }

    const res = await Api.post(url, postData, {
      headers: headerList,
    });

    if (!res.data.error && viewAlert) {
      updateAlert({
        text: 'Действие успешно выполнено.',
        view: true,
        type: EAlert_Types.Success,
      });
    }

    if (loadingHide) {
      updateApiLoading(false);
    }

    return res.data;
  } catch (e) {
    updateApiLoading(false);
  }
};

export const apiGet = async ({ headerList, url }: IApiRequest_Get) => {
  const res = await Api.get(url, { headers: headerList });
  return res.data;
};

export const apiPut = async ({
  url,
  postData,
  headerList = {},
  loading = true,
  loadingHide = true,
  viewAlert = true,
}: IApiRequest_Post) => {
  try {
    if (loading) {
      updateApiLoading(true);
    }

    const res = await Api.put(url, postData, {
      headers: headerList,
    });

    if (!res.data.error && viewAlert) {
      updateAlert({
        text: 'Данные успешно обновлены.',
        view: true,
        type: EAlert_Types.Success,
      });
    }

    if (loadingHide) {
      updateApiLoading(false);
    }

    return res.data;
  } catch (e) {
    updateApiLoading(false);
  }
};

export const apiPatch = async ({
  url,
  postData,
  headerList = {},
  loading = true,
  loadingHide = true,
  viewAlert = true,
}: IApiRequest_Post) => {
  try {
    if (loading) {
      updateApiLoading(true);
    }

    const res = await Api.patch(url, postData, {
      headers: headerList,
    });

    if (!res.data.error && viewAlert) {
      updateAlert({
        text: 'Данные успешно обновлены.',
        view: true,
        type: EAlert_Types.Success,
      });
    }

    if (loadingHide) {
      updateApiLoading(false);
    }

    return res.data;
  } catch (e) {
    updateApiLoading(false);
  }
};

export const apiDelete = async ({
  url,
  postData,
  headerList = {},
  loading = true,
  loadingHide = true,
  viewAlert = true,
}: IApiRequest_Post) => {
  try {
    if (loading) {
      updateApiLoading(true);
    }

    const res = await Api.delete(url, {
      data: postData,
      headers: headerList,
    });

    if (!res.data.error && viewAlert) {
      updateAlert({
        text: 'Данные успешно удалены.',
        view: true,
        type: EAlert_Types.Success,
      });
    }

    if (loadingHide) {
      updateApiLoading(false);
    }

    return res.data;
  } catch (e) {
    updateApiLoading(false);
  }
};
