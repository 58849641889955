import { createEvent, createStore } from 'effector';
import { EAlert_Types, IAlert_Store } from '../types/store/alert';

export const updateAlert = createEvent<IAlert_Store>();

export const $alert = createStore<IAlert_Store>({
  view: false,
  text: '',
  type: EAlert_Types.Error,
}).on(updateAlert, (state, payload) => ({
  ...state,
  ...payload,
}));
