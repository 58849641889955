import { createEvent, createStore } from 'effector';
import { find } from 'lodash-es';
import { resetFormValues } from '../helpers/form';
import { getUrlParams } from '../helpers/url';
import { ITableStore, ITableStore_UpdateParams } from '../types/store/table';
import { ITable_RowVisibleOption } from '../types/table';

const getParams = getUrlParams();
const orderingUrlParam = getUrlParams().ordering || '';

export const updateFilterValues = createEvent<ITableStore_UpdateParams>();
export const updateTableFilterValues = createEvent<ITableStore_UpdateParams>();
export const updateTableOrdering = createEvent<ITableStore_UpdateParams>();

export const cleanTableFilterValues = createEvent<string>();
export const cleanFilterValues = createEvent<string>();
export const cleanTableOrdering = createEvent<string>();

export const resetTableFilterValues = createEvent();
export const resetFilterValues = createEvent();
export const resetTableOrdering = createEvent();

export const toggleTableFilterVisible = createEvent<string>();
export const showTableFilterVisible = createEvent<string>();
export const hideTableFilterVisible = createEvent<string>();

export const setTableVisibleRow = createEvent<ITableStore_UpdateParams>();
export const toggleTableVisibleRow = createEvent<{
  key: string;
  param: string;
}>();
export const toggleAllTableVisibleRow = createEvent<string>();

export const toggleShowChildren = createEvent<string>();

export const $table = createStore<ITableStore>({
  filterIsVisible: {
    clients: false,
    leads: false,
    ticketOwners: false,
  },
  filterValues: {
    clientsEvents: {
      client: getParams.client,
      client_phone: getParams.client_phone,
      client_fio: getParams.client_fio,
      tickets_price: getParams.tickets_price,
      tickets_purchased: getParams.tickets_purchased,
      tickets_unused: getParams.tickets_unused,
    },
    pollList: {},
    references: {},
    clients: {
      email__isnull: getParams.email,
      first_name__isnull: getParams.first_name__isnull,
      last_name__isnull: getParams.last_name__isnull,
      middle_name__isnull: getParams.middle_name__isnull,
      address__isnull: getParams.address__isnull,
      birthdate__isnull: getParams.birthdate__isnull,
      favourite_sport__isnull: getParams.favourite_sport__isnull,
      favourite_club: getParams.favourite_club,
      created_at__lte: getParams.created_at__lte,
      created_at__gte: getParams.created_at__gte,
      updated_at__lte: getParams.updated_at__lte,
      updated_at__gte: getParams.updated_at__gte,
      favourite_player__isnull: getParams.favourite_player__isnull,
      favourite_competition__isnull: getParams.favourite_competition__isnull,
      sources: getParams.sources,
      email_verified: getParams.email_verified,
      phone_verified: getParams.phone_verified,
      phone__isnull: getParams.phone,
      sources__group: getParams.sources__group__isnull,
    },
    ticketOwners: {
      championship_id: getParams.championship_id,
      season: getParams.season,
      stage: getParams.stage,
      event: getParams.event,
      competition: getParams.competition,

      events_count__lte: decodeURI(getParams.events_count__lte || ''),
      events_count__gte: decodeURI(getParams.events_count__gte || ''),
    },
    leads: {
      first_name__isnull: getParams.first_name__isnull,
      last_name__isnull: getParams.last_name__isnull,
      middle_name__isnull: getParams.middle_name__isnull,
      email__isnull: getParams.email__isnull,
      address__isnull: getParams.address__isnull,
      created_at: getParams.created_at,
      updated_at: getParams.updated_at,
      data_proccessing: getParams.data_proccessing,
      subscribed: getParams.subscribed,
    },
    events: {
      name: decodeURI(getParams.name || ''),
      begin_datetime__gte: getParams.begin_datetime__gte,
      end_datetime__lte: getParams.end_datetime__lte,
      kind: getParams.kind,
      season: getParams.season,
      tour: getParams.tour,
      championship: getParams.championship,
      sportobject: getParams.sportobject,
      status: getParams.status,
      sources: getParams.sources,
      sport: getParams.sport,
      events: getParams.events,
      stage: getParams.stage,
      organization: getParams.organizationg,
      competition: getParams.competition,
    },
    gtoBi: {
      region: getParams.region,
      municipality: getParams.Municipality,
      city: getParams.city,
      gender: getParams.gender,
      sport: getParams.sport,
      education: getParams.education,
      employment: getParams.employment,
      sign: getParams.sign,
      step: getParams.step,
      trial: getParams.trial,
      form_by: getParams.form_by,
      sum_by: getParams.sum_by,
      age_from: getParams.age_from,
      age_to: getParams.age_to,
      year: getParams.year,
      quarter: getParams.quarter,
      result_from: getParams.result_from,
      result_to: getParams.result_to,
      date_from: getParams.date_from,
      date_to: getParams.date_to,
    },
    news: {
      news: getParams.news,
    },
    newsSettings: {
      newsSettings: getParams.newsSettings,
    },
    shopCategory: {
      shopCategory: getParams.shopCategory,
    },
    badgeCategory: {
      badgeCategory: getParams.badgeCategory,
    },
    mediaAlbums: {
      mediaAlbums: getParams.mediaAlbums,
    },
    mediaPhotos: {
      mediaPhotos: getParams.mediaPhotos,
    },
    appSettings: {
      appSettings: getParams.appSettings,
    },
    mailings: {
      clients: getParams.clients,
      id: getParams.id,
      name: getParams.name,
      body: getParams.body,
      body_json: getParams.body_json,
      delivery_at: getParams.delivery_at,
      description: getParams.description,
      created_at: getParams.created_at,
      created_by: getParams.created_by,
      conversion: getParams.conversion,
      status: getParams.status,
      delivered: getParams.delivered,
      opened: getParams.opened,
      segment: getParams.segment,
      template: getParams.template,
      type: getParams.type,
      sent: getParams.sent,
      updated_at: getParams.updated_at,
      readiness: getParams.readiness,
      subject: getParams.subject,
    },
  },
  //
  tableFilterValues: {
    clientsEvents: {
      client: getParams.client,
      client_phone: getParams.client_phone,
      client_fio: getParams.client_fio,
      tickets_price: getParams.tickets_price,
      tickets_purchased: getParams.tickets_purchased,
      tickets_unused: getParams.tickets_unused,
      sector_names: getParams.sector_names,
    },
    eventsForecast: {
      client: getParams.client,
      value: getParams.value,
      fio: getParams.fio,
      type: getParams.type,
      is_successful: getParams.is_successful,
      time: getParams.time,
      points: getParams.points,
    },
    mailings: {
      clients: getParams.clients,
      id: getParams.id,
      name: getParams.name,
      body: getParams.body,
      body_json: getParams.body_json,
      delivery_at: getParams.delivery_at,
      description: getParams.description,
      created_at: getParams.created_at,
      created_by: getParams.created_by,
      conversion: getParams.conversion,
      status: getParams.status,
      delivered: getParams.delivered,
      opened: getParams.opened,
      segment: getParams.segment,
      template: getParams.template,
      type: getParams.type,
      sent: getParams.sent,
      updated_at: getParams.updated_at,
      readiness: getParams.readiness,
      subject: getParams.subject,
    },
    mailingTemplates: {
      created_by: getParams.created_by,
      updated_at: getParams.updated_at,
      subject: getParams.subject,
      type: getParams.type,
      created_at: getParams.created_at,
    },
    mailingTemplateGroup: {
      name: getParams.name,
      created_at: getParams.created_at,
      created_by: getParams.created_by,
    },
    pollQuestions: {},
    references: {},
    pollList: {
      id: getParams.id,
      name: getParams.name,
      clients_number: getParams.clients_number,
      event: getParams.event,
      position: getParams.position,
      questionnaire_type: getParams.questionnaire_type,
      verification_type: getParams.verification_type,
    },
    clients: {
      fio: decodeURI(getParams.fio || ''),
      gender: getParams.gender,
      phone: decodeURI(getParams.phone || ''),
      email: decodeURI(getParams.email || ''),
      address: decodeURI(getParams.address || ''),
      sources: getParams.sources ? getParams.sources.split(',') : [],
      birthdate: getParams.birthdate,
      verified: getParams.verified,
    },
    events: {
      name: decodeURI(getParams.name || ''),
      start__gte: getParams.start__gte,
      finish__lte: getParams.finish__lte,
      kind: getParams.kind,
      season_id: getParams.season_id,
      tour_id: getParams.tour_id,
      sportobject_id: getParams.sportobject_id,
      status: getParams.status,
      sources: getParams.sources,
      organization_id: getParams.organization_id,
      sport_id: getParams.sport_id,
    },
    ticketOwners: {
      fio: decodeURI(getParams.fio || ''),
      phone: decodeURI(getParams.phone || ''),
      email: decodeURI(getParams.email || ''),
      abonements_count: decodeURI(getParams.abonements_count || ''),
      events_count: decodeURI(getParams.events_count || ''),
      passed_count: decodeURI(getParams.passed_count || ''),
    },
    leads: {
      fio: decodeURI(getParams.fio || ''),
      birthdate: getParams.birthdate,
      email: decodeURI(getParams.email || ''),
      gender: getParams.gender,
      phone: decodeURI(getParams.phone || ''),
      address: decodeURI(getParams.address || ''),
      external_id: decodeURI(getParams.external_id || ''),
      sources: getParams.sources,
      subscribed: getParams.subscribed,
      data_proccessing: getParams.data_proccessing,
    },
    journal: {
      module: getParams.module,
      actiontype: getParams.actiontype,
      createdat: getParams.createdat,
      user: decodeURI(getParams.user || ''),
      message: decodeURI(getParams.message || ''),
    },
    users: {
      login: decodeURI(getParams.login || ''),
      fio: decodeURI(getParams.fio || ''),
      phone: decodeURI(getParams.phone || ''),
      email: decodeURI(getParams.email || ''),
      roles: getParams.roles || '',
    },
    roles: {
      name: decodeURI(getParams.name || ''),
      modules: decodeURI(getParams.modules || ''),
      user_count: decodeURI(getParams.user_count || ''),
    },
    bus: {
      method: getParams.method,
      token: decodeURI(getParams.token || ''),
      Field4: getParams.Field4,
      event: getParams.event,
      errors: decodeURI(getParams.errors || ''),
      status: getParams.status,
      club: getParams.club,
      bus_status: getParams.bus_status,
    },
    pollGroups: {
      id: getParams.id,
      created_at: getParams.created_at,
      name: getParams.name,
      polls_count: getParams.polls_count,
      verification_type: getParams.verification_type,
    },
    questionnaireDirectories: {
      Field2: getParams.questions,
      Field3: getParams.typequestions,
      Field4: getParams.response,
    },
    quiz: {
      Field1: getParams.Field1,
      Field2: decodeURI(getParams.token || ''),
      Field3: getParams.Field3,
      Field4: getParams.Field4,
      Field5: decodeURI(getParams.errors || ''),
      Field6: getParams.Field6,
    },
    segments: {
      created_at: decodeURI(getParams.created_at || ''),
      updated_at: decodeURI(getParams.updated_at || ''),
      name: decodeURI(getParams.name || ''),
      count: decodeURI(getParams.count || ''),
      segment_type: getParams.segment_type,
    },
    gtoBi: {
      region: getParams.region,
      municipality: getParams.Municipality,
      city: getParams.city,
      gender: getParams.gender,
      sport: getParams.sport,
      education: getParams.education,
      employment: getParams.employment,
      sign: getParams.sign,
      step: getParams.step,
      trial: getParams.trial,
      form_by: getParams.form_by,
      sum_by: getParams.sum_by,
      age_from: getParams.age_from,
      age_to: getParams.age_to,
      year: getParams.year,
      quarter: getParams.quarter,
      result_from: getParams.result_from,
      result_to: getParams.result_to,
      date_from: getParams.date_from,
      date_to: getParams.date_to,
    },
    pushNotification: {
      message: getParams.message,
      send_at: getParams.send_at,
      status: getParams.status,
      title: getParams.title,
      type: getParams.type,
    },
    pushNotificationAuto: {
      message: getParams.message,
      send_at: getParams.send_at,
      status: getParams.status,
      title: getParams.title,
      type: getParams.type,
    },
    orders: {},
    loyaltyStatuses: {
      name: getParams.name,
      rating: getParams.rating,
      id: getParams.id,
      instance: getParams.instance,
    },
    clientFinance: getParams.clientFinance,
    triggers: {
      id: getParams.id,
      name: getParams.name,
      begin_datetime: getParams.begin_datetime,
      segment: getParams.segment,
      template: getParams.template,
      type: getParams.type,
    },
  },
  //
  tableOrdering: {
    mailings: orderingUrlParam,
    pollQuestions: orderingUrlParam,
    clients: orderingUrlParam,
    events: orderingUrlParam,
    ticketOwners: orderingUrlParam,
    leads: orderingUrlParam,
    journal: orderingUrlParam,
    users: orderingUrlParam,
    roles: orderingUrlParam,
    bus: orderingUrlParam,
    pollGroups: orderingUrlParam,
    questionnaireDirectories: orderingUrlParam,
    pollList: orderingUrlParam,
    quiz: orderingUrlParam,
    mailingTemplates: orderingUrlParam,
    mailingTemplateGroup: orderingUrlParam,
    segments: orderingUrlParam,
    gtoBi: orderingUrlParam,
    pushNotification: orderingUrlParam,
    pushNotificationAuto: orderingUrlParam,
    orders: orderingUrlParam,
    loyaltyStatuses: orderingUrlParam,
    clientFinance: orderingUrlParam,
    triggers: orderingUrlParam,
    clientsEvents: orderingUrlParam,
    eventsForecast: orderingUrlParam,
  },
  //
  tableVisibleRow: {
    mailings: null,
    clients: null,
    ticketOwners: null,
    leads: null,
    events: null,
    gtoBi: null,
    pushNotification: null,
    clientsEvents: null,
    eventsForecast: null,
  },
  //
  show_children: {
    clients: false,
    events: false,
  },
})
  .on(toggleShowChildren, (state, payload) => ({
    ...state,
    show_children: {
      ...state.show_children,
      // @ts-ignore
      [payload]: !state.show_children[payload],
    },
  }))

  .on(setTableVisibleRow, (state, payload) => {
    return {
      ...state,
      tableVisibleRow: {
        ...state.tableVisibleRow,
        [payload.key]: payload.value,
      },
    };
  })
  .on(toggleTableVisibleRow, (state, payload) => {
    // @ts-ignore
    const data = [...state.tableVisibleRow[payload.key]];

    const values = data.map((item: ITable_RowVisibleOption) => {
      return {
        ...item,
        checked: payload.param === item.value ? !item.checked : item.checked,
      };
    });

    const valuesCache = [...values];
    valuesCache.shift();

    const hasUnchecked = find(valuesCache, ['checked', false]);

    values[0].checked = !hasUnchecked;

    return {
      ...state,
      tableVisibleRow: {
        ...state.tableVisibleRow,
        [payload.key]: values,
      },
    };
  })
  .on(toggleAllTableVisibleRow, (state, payload) => {
    // @ts-ignore
    const data = [...state.tableVisibleRow[payload]];

    const isChecked = data[0].checked;

    const values = data.map((item: ITable_RowVisibleOption) => ({
      ...item,
      checked: !isChecked,
    }));

    return {
      ...state,
      tableVisibleRow: {
        ...state.tableVisibleRow,
        [payload]: values,
      },
    };
  })

  .on(updateFilterValues, (state, payload) => {
    const newState = { ...state };

    newState.filterValues = {
      ...newState.filterValues,
      [payload.key]: payload.value,
    };

    return newState;
  })
  .on(updateTableFilterValues, (state, payload) => {
    const newState = { ...state };

    newState.tableFilterValues = {
      ...newState.tableFilterValues,
      [payload.key]: payload.value,
    };

    return newState;
  })
  .on(updateTableOrdering, (state, payload) => {
    const newState = { ...state };

    newState.tableOrdering = {
      ...newState.tableOrdering,
      [payload.key]: payload.value,
    };

    return newState;
  })

  .on(cleanFilterValues, (state, payload) => {
    const newState = { ...state };

    newState.filterValues = {
      ...newState.filterValues,
      // @ts-ignore
      [payload]: resetFormValues(state.filterValues[payload]),
    };

    newState.tableFilterValues = {
      ...newState.tableFilterValues,
      // @ts-ignore
      [payload]: resetFormValues(state.tableFilterValues[payload]),
    };

    return newState;
  })
  .on(cleanTableFilterValues, (state, payload) => {
    const newState = { ...state };

    newState.tableFilterValues = {
      ...newState.tableFilterValues,
      // @ts-ignore
      [payload]: resetFormValues(state.tableFilterValues[payload]),
    };

    return newState;
  })
  .on(cleanTableOrdering, (state, payload) => {
    const newState = { ...state };

    newState.tableOrdering = {
      ...newState.tableOrdering,
      [payload]: '',
    };

    return newState;
  })

  .on(resetTableFilterValues, state => {
    const tableFilterValues = { ...state.tableFilterValues };

    // @ts-ignore
    Object.keys(tableFilterValues).forEach(key => (tableFilterValues[key] = resetFormValues(tableFilterValues[key])));

    return {
      ...state,
      tableFilterValues,
    };
  })
  .on(resetFilterValues, state => {
    const filterValues = { ...state.filterValues };

    // @ts-ignore
    Object.keys(filterValues).forEach(key => (filterValues[key] = resetFormValues(filterValues[key])));

    return {
      ...state,
      filterValues,
    };
  })
  .on(resetTableOrdering, state => {
    const tableOrdering = { ...state.tableOrdering };

    // @ts-ignore
    Object.keys(tableOrdering).forEach(key => (tableOrdering[key] = resetFormValues(tableOrdering[key])));

    return {
      ...state,
      tableOrdering,
    };
  })

  .on(toggleTableFilterVisible, (state, payload) => ({
    ...state,
    filterIsVisible: {
      ...state.filterIsVisible,
      //@ts-ignore
      [payload]: !state.filterIsVisible[payload],
    },
  }))
  .on(showTableFilterVisible, (state, payload) => ({
    ...state,
    filterIsVisible: {
      ...state.filterIsVisible,
      [payload]: true,
    },
  }))
  .on(hideTableFilterVisible, (state, payload) => ({
    ...state,
    filterIsVisible: {
      ...state.filterIsVisible,
      [payload]: false,
    },
  }));
