import { useForm } from 'react-hook-form';
import { callbackRequest } from '../../store/app';
import { IForm_AuthRequest } from '../../types/form/auth';
import { EForm_ErrorStore } from '../../types/store/form';
import { CustomButton } from './Button';
import { FormCheckbox } from './Form/Checkbox';
import { FormInput } from './Form/Input';

export interface ICmpProps_AuthRequestForm {
  purpose: string;
}

export const CallbackRequestForm = ({ purpose }: ICmpProps_AuthRequestForm) => {
  const { register, handleSubmit, control } = useForm<IForm_AuthRequest>({
    defaultValues: { phone: '' },
  });

  const onSubmit = async (data: IForm_AuthRequest) => {
    await callbackRequest({ data, purpose });
  };

  const checkboxText = `
    Согласен на <a href='#' class='c-link'>обработку персональных данных</a>
  `;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form">
      <FormInput control={control} formKey={EForm_ErrorStore.AuthRequest} label="Имя" name="name" placeholder="Иван" />
      <FormInput
        control={control}
        formKey={EForm_ErrorStore.AuthRequest}
        label="Номер телефона"
        name="phone"
        type="tel"
        placeholder="+7"
      />

      <FormCheckbox formKey={EForm_ErrorStore.AuthRequest} name="agree" register={register} text={checkboxText} />

      <CustomButton type="submit" btnClasses="mt-4 w-full" color="accent">
        Отправить
      </CustomButton>
    </form>
  );
};
