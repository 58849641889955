import { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MAIN_PAGES } from '../../global/pages';
import { Fallback } from '../common/Fallback';
import { AppLayout } from '../layouts/App';

export const MainStack = () => {
  return (
    <Suspense fallback={<Fallback />}>
      <AppLayout>
        <Switch>
          <Route exact path="/" component={MAIN_PAGES.home} />
          <Route exact path="/gto" component={MAIN_PAGES.homeGto} />
          <Route exact path="/clients" component={MAIN_PAGES.clients} />
          <Route exact path="/clients/:id" component={MAIN_PAGES.client} />
          <Route exact path="/ticket-owners/:id" component={MAIN_PAGES.client} />
          <Route exact path="/events" component={MAIN_PAGES.events} />
          <Route exact path="/events-calendar" component={MAIN_PAGES.eventsCalendar} />
          <Route exact path="/events/:id" component={MAIN_PAGES.event} />
          <Route exact path="/events-edit/:id" component={MAIN_PAGES.eventEdit} />
          <Route exact path="/event-add" component={MAIN_PAGES.eventAdd} />
          <Route exact path="/segmentation-build" component={MAIN_PAGES.segmentation} />
          <Route exact path="/segmentations" component={MAIN_PAGES.segmentation} />
          <Route exact path="/mailings" component={MAIN_PAGES.mailings} />
          <Route exact path="/mailings/:id" component={MAIN_PAGES.mailingView} />
          <Route exact path="/mailing-edit/:id" component={MAIN_PAGES.mailingEdit} />
          <Route exact path="/mailings-create" component={MAIN_PAGES.mailingAdd} />
          <Route exact path="/mailing-triggers" component={MAIN_PAGES.mailingTriggers} />
          <Route exact path="/mailing-triggers/:id" component={MAIN_PAGES.mailingTrigger} />
          <Route exact path="/mailing-triggers-add" component={MAIN_PAGES.mailingTriggerAdd} />
          <Route exact path="/mailing-triggers-edit/:id" component={MAIN_PAGES.mailingTriggerEdit} />
          <Route exact path="/profile" component={MAIN_PAGES.profile} />
          <Route exact path="/ticket-owners" component={MAIN_PAGES.ticketOwners} />
          <Route exact path="/leads" component={MAIN_PAGES.leads} />
          <Route exact path="/references" component={MAIN_PAGES.references} />
          <Route exact path="/references/:model" component={MAIN_PAGES.referencesIn} />
          <Route exact path="/journal" component={MAIN_PAGES.journal} />
          <Route exact path="/users" component={MAIN_PAGES.users} />
          <Route exact path="/roles" component={MAIN_PAGES.roles} />
          <Route exact path="/bus" component={MAIN_PAGES.bus} />
          <Route exact path="/bus/:id" component={MAIN_PAGES.busIn} />
          <Route exact path="/poll-groups" component={MAIN_PAGES.pollGroups} />
          <Route exact path="/poll-groups/:id" component={MAIN_PAGES.pollsList} />
          <Route exact path="/poll-groups-add" component={MAIN_PAGES.pollGroupsAdd} />
          <Route exact path="/poll-groups-edit/:id" component={MAIN_PAGES.pollGroupsEdit} />
          <Route exact path="/poll/:id" component={MAIN_PAGES.poll} />
          <Route exact path="/poll-add/:id" component={MAIN_PAGES.pollAdd} />
          <Route exact path="/poll-edit/:id" component={MAIN_PAGES.pollEdit} />
          {/*<Route exact path="/questionnaire-directories" component={MAIN_PAGES.questionnaireDirectories} />*/}
          <Route exact path="/quiz/:id" component={MAIN_PAGES.quiz} />
          <Route exact path="/quizes" component={MAIN_PAGES.quizes} />
          <Route exact path="/quiz-add" component={MAIN_PAGES.quizAdd} />
          <Route exact path="/quiz-edit/:id" component={MAIN_PAGES.quizEdit} />
          <Route exact path="/settings-app" component={MAIN_PAGES.settingsApp} />
          <Route exact path="/shop" component={MAIN_PAGES.shop} />
          <Route exact path="/promocodes" component={MAIN_PAGES.promocodes} />
          <Route exact path="/media" component={MAIN_PAGES.media} />
          <Route exact path="/media-list" component={MAIN_PAGES.mediaList} />
          <Route exact path="/news" component={MAIN_PAGES.news} />
          <Route exact path="/news/:id" component={MAIN_PAGES.newsOne} />

          <Route exact path="/mailing-template/:id" component={MAIN_PAGES.mailingTemplate} />
          <Route exact path="/mailing-template-add" component={MAIN_PAGES.mailingTemplateAdd} />
          <Route exact path="/mailing-template-edit/:id" component={MAIN_PAGES.mailingTemplateEdit} />

          <Route exact path="/mailing-template-groups" component={MAIN_PAGES.mailingTemplateGroupList} />
          <Route exact path="/mailing-template-groups/:id" component={MAIN_PAGES.mailingTemplateGroup} />

          <Route exact path="/summary-data" component={MAIN_PAGES.summaryData} />
          <Route exact path="/segments" component={MAIN_PAGES.segments} />
          <Route exact path="/push-notifications" component={MAIN_PAGES.pushNotifications} />

          <Route exact path="/import/:id" component={MAIN_PAGES.import} />
          <Route exact path="/test-websocket" component={MAIN_PAGES.test} />

          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </AppLayout>
    </Suspense>
  );
};
