import cn from 'classnames';
import { useStoreMap } from 'effector-react';
import { MouseEvent, ReactNode, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { $profile } from '../../store/profile';

interface ICmpProps_Button {
  children: ReactNode;
  color?: 'accent' | 'primary' | 'white' | 'border' | 'disable' | 'circle' | null | string;
  size?: 'xs' | 'sm' | 'lg';
  btnClasses?: string;
  download?: boolean;
  link?: string;
  onClick?: any;
  type?: 'button' | 'submit' | 'reset';
  target?: string;
  rel?: string;
  disabled?: boolean;
  linkState?: {};
  btnBgColor?: string;
  btnColor?: string;
  noSubmit?: boolean;
}

export const CustomButton = ({
  type = 'button',
  color = 'primary',
  btnClasses = '',
  linkState = {},
  btnBgColor,
  btnColor,
  noSubmit,
  ...props
}: ICmpProps_Button) => {
  const ref = useRef<HTMLButtonElement>(null);

  const history = useHistory();

  const $profileData = useStoreMap($profile, s => s.profile);

  const colorscheme = $profileData?.colorscheme;

  let btnBg = '';
  let size = 'btn-sm';

  if (color) {
    btnBg = `btn-${color}`;
  }

  if (props.size) {
    size = `btn-${props.size}`;
  }

  const onMouseOver = (event: MouseEvent<any>) => {
    if (btnBg === 'btn-primary') {
      event.currentTarget.style.background = btnBgColor || colorscheme?.button_active_bg || '';
      event.currentTarget.style.color = btnColor || colorscheme?.button_active_color || '';
    }
  };

  const onMouseOut = (event: MouseEvent<any>) => {
    if (btnBg === 'btn-primary') {
      event.currentTarget.style.background = btnBgColor || colorscheme?.button_bg || '';
      event.currentTarget.style.color = btnColor || colorscheme?.button_color || '';
    }
  };

  const commonProps = {
    ...props,
    style:
      btnBg === 'btn-primary'
        ? {
            background: colorscheme?.button_bg || '',
            color: colorscheme?.button_color || '',
          }
        : {},
    onMouseOver,
    onMouseOut,
    className: cn('btn', btnBg, size, btnClasses),
    onClick: (e: MouseEvent<HTMLButtonElement>) => {
      if (props.onClick) {
        props.onClick(e);
        return;
      }

      if (props.target === '_blank') {
        window.open(props.link, '_blank');
        return;
      }

      if (props.link) {
        history.push(props.link, linkState);
      }
    },
  };

  useEffect(() => {
    if (ref.current && btnBg === 'btn-primary') {
      ref.current.style.background = btnBgColor || colorscheme?.button_bg || '';
      ref.current.style.color = btnColor || colorscheme?.button_color || '';
    }
  }, [ref.current]);

  if (noSubmit) {
    return <div>{props.children}</div>
  }

  return (
    <button ref={ref} {...commonProps} type={type}>
      {props.children}
    </button>
  );
};
