import { IPoll_Question } from './poll';

export interface IExternalPollStore {
  step: number;
  formId: number | null;
  questions: IPoll_Question[];
  step3Form: any;
  pageBegin: IExternalPollStore_PageBegin;
  pageEnd: IExternalPollStore_PageEnd;
}

export interface IExternalPollStore_PageBegin {
  visible: boolean;
  begin_description?: string;
  begin_image?: string;
  begin_title?: string;
}

export interface IExternalPollStore_PageEnd {
  end_description: string | null;
  end_image: string | null;
  end_title: string | null;
  end_url: string | null;
}

export interface IExternalPoll_Step1 {
  additional_scripts: string | null;
  agreement_text_color: string | null;
  answer_background_color: string | null;
  answer_text_color: string | null;
  background_color: string | null;
  background_image: string | null;
  button_color: string | null;
  button_text_color: string | null;
  choosen_answer_background_color: string | null;
  choosen_answer_text_color: string | null;
  get_answers_text: string | null;
  is_subscription_required: boolean;
  main_content_background_color: string | null;
  name: string | null;
  verification_code_header: string | null;
  verification_message: string | null;
  verification_notify_header: string | null;
  verification_text_color: string | null;
  verification_type: string | null;
}

export interface IExternalPoll_Step2 {
  begin_description: string | null;
  begin_image: string | null;
  begin_title: string | null;
  questions: IPoll_Question[];
  visible_start_page: boolean;
}

export enum EExternalPoll_QuestionType {
  Simple = 'simple',
  One_text = 'one_text',
  Multiple_text = 'multiple_text',
  One_image = 'one_image',
  Multiple_image = 'multiple_image',
  Directory = 'directory',
  Directory_type = 'directory_type',
}
