import { logout } from '../store/auth';

export interface IProps_ErrorHandler {
  errorText: string;
  updateAlert?: boolean;
}

export const errorHandler = ({ errorText, updateAlert = true }: IProps_ErrorHandler) => {
  const IS_NOT_AUTH = errorText.match(/401/);

  let errText = null;

  if (IS_NOT_AUTH) {
    logout();
  } else if (errorText.match(/timeout/gi)) {
    errText = 'Превышено время ответа от сервера. Перезагрузите приложение.';
  } else if (errorText.match(/network/gi)) {
    errText = 'Отсутствует интернет соединение.';
  } else if (errorText.match(/404/g)) {
    errText = 'Ошибка сервера: #404';
  }

  if (updateAlert && !IS_NOT_AUTH) {
    alert(`Ошибка ${errText || errorText}`);
  }
};
