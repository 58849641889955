import { ISelect_Item } from '../components/common/Form/Select';
import { IClient } from '../types/clients';
import { IDictionary_ClothingSize } from '../types/dictionary';
import { IUser } from '../types/users';

export interface IPrepareDictionaryArrayForSelect {
  arr: any;
}

export const prepareArrayForSelectClients = (props: { arr: IClient[] }): ISelect_Item[] | [] => {
  if (!props.arr) return [];

  return props.arr.map((item: IClient) => ({
    label: `${item.id}. ${item.email || ''} ${item.first_name || ''} ${item.middle_name || ''} ${
      item.email ? `(${item.email})` : ''
    }`,
    value: String(item.id || ''),
  }));
};

export const prepareArrayForSelectSources = (props: { arr: any[] }) => {
  if (!props.arr) return [];

  return props.arr.map((item: { name: string; id: string | number }) => ({
    label: String(item.name || ''),
    value: String(item.id || ''),
  }));
};

export const prepareArrayForSelectUsers = (props: { arr: IUser[] }): ISelect_Item[] | [] => {
  if (!props.arr) return [];

  return props.arr.map((item: IUser) => ({
    label: `${item.id}. ${String(item.username || '')}`,
    value: String(item.id || ''),
  }));
};

export const prepareArrayForSelectMailings = (props: IPrepareDictionaryArrayForSelect): ISelect_Item[] | [] => {
  if (!props.arr) return [];

  return props.arr.map((item: { subject: string; id: string | number }) => ({
    label: String(item.subject || ''),
    value: String(item.id || ''),
  }));
};

export const prepareArrayForSelectPlayers = (
  props: IPrepareDictionaryArrayForSelect,
  lang?: 'ru' | 'en' | 'el',
  playersField: boolean = false
): ISelect_Item[] | [] => {
  if (!props.arr) return [];

  return props.arr.map(
    (item: {
      id: number | string;
      player: {
        name: string;
        name_en: string;
        name_el: string;
        id: string | number;
        last_name?: string;
        last_name_en?: string;
        last_name_el?: string;
        number?: string | number;
      };
    }) => {
      let langName: string = '';

      switch (lang) {
        case 'ru':
          if (item.player.last_name) {
            langName = `${item.player.name} ${item.player.last_name}`;
            break;
          }
          langName = item.player.name;
          break;
        case 'en':
          if (item.player.last_name_en) {
            langName = `${item.player.name_en} ${item.player.last_name_en}`;
            break;
          }
          langName = item.player.name_en;
          break;
        case 'el':
          if (item.player.last_name_el) {
            langName = `${item.player.name_el} ${item.player.last_name_el}`;
            break;
          }
          langName = item.player.name_el;
          break;
        default:
          langName = item.player.name;
          break;
      }

      if (playersField) {
        return {
          label: String(langName || ''),
          value: String(item.id || ''),
        };
      }

      return {
        label: String(langName || ''),
        value: String(item.id || ''),
      };
    }
  );
};

export const prepareArrayForSelectSegmentsAge = (props: IPrepareDictionaryArrayForSelect): ISelect_Item[] | [] => {
  if (!props.arr) return [];

  const arr = props.arr.map((item: number) => {
    return {
      label: item,
      value: item,
    };
  });

  return arr;
};

export const prepareArrayForSelectDictionary = (
  props: IPrepareDictionaryArrayForSelect,
  lang?: 'ru' | 'en' | 'el',
  isSettings: boolean = false,
  isTicketOwner: boolean = false
): ISelect_Item[] | [] => {
  if (!props.arr) return [];

  const arr = props.arr.map(
    (item: {
      name: string;
      name_en: string;
      name_el: string;
      id: string | number;
      last_name?: string;
      last_name_en?: string;
      last_name_el?: string;
    }) => {
      let langName: string = '';

      if (isSettings) {
        return {
          label: String(item.name || ''),
          value: String(item.id || ''),
        };
      }

      switch (lang) {
        case 'ru':
          if (item.last_name) {
            langName = `${item.name} ${item.last_name}`;
            break;
          }
          langName = item.name;
          break;
        case 'en':
          if (item.last_name_en) {
            langName = `${item.name_en} ${item.last_name_en}`;
            break;
          }
          langName = item.name_en;
          break;
        case 'el':
          if (item.last_name_el) {
            langName = `${item.name_el} ${item.last_name_el}`;
            break;
          }
          langName = item.name_el;
          break;
        default:
          langName = item.name;
          break;
      }

      return {
        label: String(langName || ''),
        value: String(item.id || ''),
      };
    }
  );

  return arr;
};

export const prepareObjectInArrayForSelectDictionary = (
  props: IPrepareDictionaryArrayForSelect
): ISelect_Item[] | [] => {
  if (!props.arr) return [];

  return props.arr.map((item: { group_detailed: { name: string } | null; id: number }) => ({
    label: String(item.group_detailed?.name || ''),
    value: String(item.id || ''),
  }));
};

export const prepareArrayForSelectClothes = (props: { arr: IDictionary_ClothingSize[] }): ISelect_Item[] => {
  if (!props.arr) return [];

  return props.arr.map((item: IDictionary_ClothingSize) => ({
    label: String(item.international_standard || ''),
    value: String(item.id || ''),
  }));
};

export const formDefaultValue = (value: string | number | null | undefined): string | null => {
  if (value == null) {
    return null;
  }

  return String(value);
};

export const resetFormValues = (data: {}) => {
  const newData = { ...data };

  Object.keys(data).map(key => {
    // @ts-ignore
    let value = data[key];

    if (Array.isArray(value)) {
      value = [];
    } else {
      value = '';
    }

    // @ts-ignore
    newData[key] = value;
  });

  return newData;
};

export const prepareFormData = (data: {}): FormData => {
  const formData = new FormData();

  if (!data || typeof data !== 'object') {
    return formData;
  }

  Object.keys(data).forEach((key: string) => {
    // @ts-ignore
    let value = data[key];

    if (value == null) return;

    if (typeof value === 'object') {
      value = value?.[0] || null;
    } else {
      value = String(value);
    }

    // Todo: картинка в polls не сохраняется

    if (value) formData.append(key, value);
  });

  return formData;
};
