interface IFindInArrayParams {
  arr: any[] | undefined;
  param?: string;
  value: string | number | null | undefined;
}

export const findInArray = ({ arr, param = 'id', value }: IFindInArrayParams): any => {
  if (!arr?.length || !param || !value) return '';

  let result = arr.find(el => el[param] === value);
  return result || {};
};

export const filterLocationById = (arr: any[], id: string, paramName: string) => {
  return arr.filter(item => item[paramName] === +id);
};
