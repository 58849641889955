import { EIcons, Icon } from '../../Icon/Icon';
import { IApiResponse_Core, IApiResponse_Pagination } from '../../../../types/api';
import { INotifications_Item } from '../../../../types/notifications';
import { useCallback, useEffect, useRef, useState } from 'react';
import { queryClient } from '../../../../index';
import { apiGet } from '../../../../api';
import { useQuery } from 'react-query';
import { COLORS } from '../../../../global/colors';
import dayjs from 'dayjs';
import { Link, useLocation } from 'react-router-dom';
import useOnClickOutside from 'use-onclickoutside';
import { useStoreMap } from 'effector-react';
import { $notification, updateHasNotification } from '../../../../store/notification';
import useSound from 'use-sound';
// @ts-ignore
import Sound from '../../../../static/sound/notice.mp3';
import cn from 'classnames';
import { useAppTranslation } from '../../../../hooks/useAppTranslation';
import { $profile } from '../../../../store/profile';

export const NotificationApp = () => {
  const { t } = useAppTranslation();
  const location = useLocation();
  const { hasNotification, notificationsList } = useStoreMap($notification, s => s);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [play] = useSound(Sound);
  const $profileData = useStoreMap($profile, s => s.profile);

  useEffect(() => {
    if (hasNotification) {
      setShowPopup(false);

      setTimeout(() => {
        setShowPopup(true);
        play();
      }, 300);
    }
  }, [notificationsList]);

  const wrapRef = useRef<HTMLDivElement>(null);
  const notificationListRef = useRef<HTMLDivElement>(null);

  // const [hasNotifications, setHasNotifications] = useState<boolean>(false);

  //TODO missing queryFn (пустой useQuery, без функции)
  const notificationsQuery = useQuery('notifications', () => {});

  const toggleNotificationList = useCallback(async () => {
    updateHasNotification(false);
    // if (!queryClient.getQueryData('notifications')) {
    await queryClient.fetchQuery(
      'notifications',
      async () => {
        const res: IApiResponse_Core<IApiResponse_Pagination<INotifications_Item[]>> = await apiGet({
          url: 'notifications/',
        });

        return res.data;
      },
      {
        staleTime: 300,
      }
    );
    // }

    notificationListRef.current?.classList?.toggle('_visible');
  }, [notificationListRef.current]);

  const closeNotificationPopup = () => {
    setShowPopup(false);
  };

  const hideList = useCallback(
    () => notificationListRef.current?.classList?.remove('_visible'),
    [notificationListRef.current]
  );

  useOnClickOutside(wrapRef, () => {
    hideList();
  });

  useEffect(() => {
    hideList();
  }, [location.pathname]);

  return (
    <>
      <div ref={wrapRef} className="d-flex ai-center pos">
        <button onClick={toggleNotificationList} className="notification pos mx-6" style={{ position: 'relative' }}>
          {hasNotification && <span className="dot" />}
          <Icon name={EIcons.Bell} size={18} />
        </button>

        <div ref={notificationListRef} className="notification-list">
          <button className="float-right text-18" onClick={toggleNotificationList}>
            &times;
          </button>

          {
            <>
              {/*@ts-ignore*/}
              {notificationsQuery.data?.results?.map((i: INotifications_Item) => (
                <div key={i.id} className="pb-3 pt-3 d-flex" style={{ flexDirection: 'column' }}>
                  {i.type === 'import' && (
                    <>
                      {(i.active && <span>{t('Импорт прошел успешно')}</span>) || (
                        <span>{t('Импорт прошел не успешно')}</span>
                      )}
                      <span className="pb-0">Создан: {dayjs(i.created_at).format('YYYY-MM-DD HH:mm') || null}</span>
                      <Link to={`/import/${i.import_file}`} style={{ color: `${COLORS.link}` }}>
                        {t('Детали операции')}
                      </Link>
                    </>
                  )}

                  {i.type === 'export' && (
                    <>
                      {(i.active && <span>{t('Экспорт прошел успешно')}</span>) || (
                        <span>{t('Экспорт прошел не успешно')}</span>
                      )}
                      <span className="pb-0">Создан: {dayjs(i.created_at).format('YYYY-MM-DD HH:mm') || null}</span>
                      <a href={i.export_file} download={'download'} style={{ color: `${COLORS.link}` }}>
                        {t('Скачать документ')}
                      </a>
                    </>
                  )}
                </div>
              ))}
              {/*@ts-ignore*/}
              {!notificationsQuery.data?.results.length && <div>{t('Нет уведомлений')}</div>}
            </>
          }
        </div>
      </div>
      <div
        className={cn('notification-popup', {
          _visible: hasNotification && showPopup,
        })}
        style={{
          background: `linear-gradient(135deg, ${$profileData?.colorscheme?.menu_gradient_from} 0%, ${$profileData?.colorscheme?.menu_gradient_to} 100%)`,
        }}
      >
        <div>
          {/*@ts-ignore*/}
          <div>{notificationsList.length && notificationsList[notificationsList.length - 1].message}</div>
          <button onClick={closeNotificationPopup}>&times;</button>
        </div>
      </div>
    </>
  );
};
