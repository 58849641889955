import { IApiResponse_Core } from '../types/api';
import { IDictionary } from '../types/dictionary';
import { apiGet } from './index';

export const fetchDictionary = async (): Promise<IDictionary> => {
  const res: IApiResponse_Core<IDictionary> = await apiGet({
    url: '/info/',
  });

  return res.data;
};
