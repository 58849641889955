import {log} from 'util';

export interface IUpdateUrlParams {
  key: string;
  value: string | null;
}

export const getUrlParams = (): any => {
  const params = {};

  let search = new URL(window.location.href).search.replace('?', '');

  if (search.length) {
    search.split('&').forEach(i => {
      const tmp = i.split('=');

      // @ts-ignore
      params[tmp[0]] = tmp[1];
    });
  }

  return params;
};

export const prepareSearchParams = (
  values: IUpdateUrlParams[]
): {
  fullUrl: string;
  search: string;
} | null => {
  if (!values.length) return null;

  let url = new URL(window.location.href);
  const params = new window.URLSearchParams(window.location.search);

  values.forEach(el => {
    if (!el.value?.length) {
      params.delete(el.key);
    } else {
      params.set(el.key, el.value);
    }
  });

  const searchParams = decodeURIComponent(String(params));

  return {
    fullUrl: `${url.pathname}?${searchParams}`,
    search: searchParams,
  };
};
