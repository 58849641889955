import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import ConfigProvider from 'antd/es/config-provider';
import locale from 'antd/lib/locale/ru_RU';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './static/styles/index.scss';
import './i18n/config';
import { Suspense } from 'react';

dayjs.locale('ru-RU');

Sentry.init({
  dsn: 'https://81f82729e56d4f7c91b0a03a210174ee@o1070324.ingest.sentry.io/6067796',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 0,
    },
  },
});

ReactDOM.render(
  <ConfigProvider locale={locale}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<div>Loading...</div>}>
          <App />
        </Suspense>
      </QueryClientProvider>
    </BrowserRouter>
  </ConfigProvider>,
  document.getElementById('root')
);
