import { ReactNode } from 'react';
import { AuthHeader } from '../common/AuthHeader/AuthHeader';
import { AuthMobileMenu } from '../common/AuthMobileMenu/AuthMobileMenu';

interface IAuthLayout {
  children: ReactNode;
}

export const AuthLayout = ({ children }: IAuthLayout) => {
  return (
    <div id="main-wrap" className="w-full">
      <AuthHeader />

      <AuthMobileMenu />

      {children}
    </div>
  );
};
