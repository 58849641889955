import { createEvent, createStore } from 'effector';
import { IActiveForm, IForm_ErrorStore, IUpdateForm_ErrorStores } from '../types/store/form';

export const setFormErrors = createEvent<IUpdateForm_ErrorStores>();
export const updateForm_ErrorStores = createEvent<IUpdateForm_ErrorStores>();
export const cleanFormErrors = createEvent<string>();
export const resetFormErrors = createEvent();

export const $formError = createStore<IForm_ErrorStore>({
  login: {},
  mailingAdd: {},
  mailingEdit: {},
  eventCalendar: {},
  authRequest: {},
  passwordRecover: {},
  setNewPassword: {},
  clientCreate: {},
  clientEdit: {},
  eventEdit: {},
  eventAdd: {},
  profileEdit: {},
  fileImport: {},
  userCreate: {},
  userEdit: {},
  pollGroupCreate: {},
  pollGroupAdd: {},
  pollForm: {},
  addPollRequest: {},
  externalPoll: {},
  quizForm: {},
  quizFormQuestion: {},
  mailingTemplateGroupAdd: {},
  mailingTemplateGroupEdit: {},
  mailingTemplateAdd: {},
  mailingTemplateEdit: {},
  gtoFilter: {},
  biToSegments: {},
})
  .on(setFormErrors, (state, payload) => {
    return {
      ...state,
      [payload.key]: payload.value,
    };
  })
  .on(updateForm_ErrorStores, (state, payload) => ({
    ...state,
    [payload.key]: {
      // @ts-ignore
      ...state[payload.key],
      ...payload.value,
    },
  }))
  .on(cleanFormErrors, (state, payload) => ({ ...state, [payload]: {} }))
  .reset(resetFormErrors);

export const updateActiveForm = createEvent<string | null>();

export const $activeForm = createStore<IActiveForm>({
  formName: null,
}).on(updateActiveForm, (state, payload) => ({
  ...state,
  formName: payload,
}));
