import cn from 'classnames';
import { useStore } from 'effector-react';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { $alert, updateAlert } from 'store/alert';
import { EAlert_Types } from '../../types/store/alert';

export const PopUp = () => {
  const $alertState = useStore($alert);

  const location = useLocation();

  const alertDiv = useRef<HTMLDivElement>(null);

  const closeAlert = () => updateAlert({ view: false, text: $alertState.text, type: EAlert_Types.Error });

  useEffect(() => {
    if ($alertState.view) {
      const timer = setTimeout(() => {
        closeAlert();
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [$alertState.view]);

  useEffect(() => {
    closeAlert();
  }, [location.pathname]);

  return (
    <div
      ref={alertDiv}
      onClick={closeAlert}
      style={{
        maxWidth: 200,
        zIndex: 1011,
      }}
      className={cn(
        'fixed transition-all right-10 top-6 index-8 text-white py-2 px-4 rounded-lg cursor-pointer leading-snug',
        {
          'opacity-0 invisible': !$alertState.view,
          'bg-accent': $alertState.type === EAlert_Types.Error,
          'bg-green': $alertState.type === EAlert_Types.Success,
        }
      )}
    >
      {$alertState.text}
    </div>
  );
};
