import * as Sentry from '@sentry/react';
import { PopUp } from 'components/common/PopUp';
import { useStore, useStoreMap } from 'effector-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useLocation } from 'react-router-dom';
import { fetchAllClients } from './api/clients';
import { fetchDictionary } from './api/dictionary';
import { fetchAllUsers } from './api/users';
import { ScreenLoader } from './components/common/ScreenLoader/ScreenLoader';
import { Spinner } from './components/common/Spinner/Spinner';
import { AuthStack } from './components/navigator/AuthStack';
import { MainStack } from './components/navigator/MainStack';
import { ExternalPollPage } from './components/pages/externalPoll/ExternalPoll';
import { $app, updateAppLoading } from './store/app';
import { $auth, authCheck } from './store/auth';
import { resetFormErrors } from './store/form';
import { resetFilterValues, resetTableFilterValues, resetTableOrdering } from './store/table';
import { resetActiveTab } from './store/tabs';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { IDictionary } from './types/dictionary';
import { $language, changeLanguage, Language, updateTranslations } from './store/language';
import { updateHasNotification, updateNotificationList } from './store/notification';
import { queryClient } from './index';
import { IApiResponse_Core, IApiResponse_Pagination, IApiResponse_Translations } from './types/api';
import { INotifications_Item } from './types/notifications';
import { apiGet } from './api';
import axios from 'axios';
import Cookies from 'js-cookie';
import { getLocale } from './i18n/config';
import { useFetchLocales } from './hooks/react-query/useFetchLacales';
import {notification} from 'antd';
import {log} from 'util';

// let socket = new WebSocket('wss://backend.capital-sport.ru/notifications/47/');
// socket.onopen = function(e) {
//   console.log('соединение установлено')
// };
//
// socket.onmessage = function(event) {
//   console.log(`${event.data}`)
// };

const initApp = async () => {
  const defaultLang = localStorage.getItem('lang');

  if (!defaultLang) {
    localStorage.setItem('lang', 'ru');
  } else {
    changeLanguage(defaultLang as Language);
  }

  const translation: IApiResponse_Core<IApiResponse_Translations[]> = await apiGet({
    url: '/locales/translitions/',
  });

  const cache = {};

  Object.keys(translation.data[0]).forEach(key => {
    // @ts-ignore
    cache[key] = {};

    translation.data.forEach((innerKey, index) => {
      // @ts-ignore
      cache[key][innerKey['ru']] = translation.data[index][key];
    });
  });

  // @ts-ignore
  localStorage.setItem('translation-ru', JSON.stringify(cache.ru));
  // @ts-ignore
  localStorage.setItem('translation-en', JSON.stringify(cache.en));
  // @ts-ignore
  localStorage.setItem('translation-gr', JSON.stringify(cache.el));

  updateTranslations(cache);

  getLocale();
};

const App = () => {
  useFetchLocales();

  useEffect(() => {
    initApp();
  }, []);

  const location = useLocation();
  if (location.pathname === '/poll/external/show') {
    return (
      <>
        <ExternalPollPage />
        {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
      </>
    );
  }

  const { lang, translations } = useStore($language);
  const $authStore = useStore($auth);
  const appLoading = useStoreMap($app, s => s.appLoading);

  const queryOptions = { enabled: !!$authStore.authToken, cacheTime: Infinity, staleTime: Infinity };

  const dictionaryQueryData = useQuery<IDictionary | undefined>('dictionary', fetchDictionary, queryOptions);
  // useQuery('clients_all', fetchAllClients, queryOptions);
  // useQuery('users_all', fetchAllUsers, queryOptions);

  // >> web socket
  const [socketUrl, setSocketUrl] = useState('wss://backend.capital-sport.ru/notifications/');
  const [messageHistory, setMessageHistory] = useState<any[]>([]);
  const didUnmount = useRef(false);

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);
  useEffect(() => {
    // if (lastMessage !== null) {
    // setMessageHistory(prev => prev.concat(lastMessage));
    // notification.open({
    //   message: 'Сообщение',
    //   description: 'Вам пришло новое сообщение',
    //   onClick: () => {},
    // });
    // }

    console.log(lastMessage);
    const message = lastMessage ? JSON.parse(lastMessage.data) : false;

    if (message && !message.notification.error) {
      updateHasNotification(true);
      updateNotificationList(message);
    }
    didUnmount.current = true;
  }, [lastMessage]);

  const handleClickChangeSocketUrl = useCallback(
    () => setSocketUrl('wss://backend.capital-sport.ru/notifications/'),
    []
  );

  // const handleClickSendMessage = useCallback(() => {}, []);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];
  // <<

  useEffect(() => {
    authCheck();
  }, []);

  useEffect(() => {
    resetFormErrors();
    window.scrollTo({ top: 0 });

    resetActiveTab();

    if (!location.pathname.search.length) {
      resetTableFilterValues();
      resetFilterValues();
      resetTableOrdering();
    }
  }, [location.pathname]);

  if (appLoading || dictionaryQueryData.isLoading) {
    return (
      <div className="d-flex ai-center jc-center flex-1">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <ScreenLoader />

      <PopUp />

      {$authStore.authToken ? <MainStack /> : <AuthStack />}

      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
    </>
  );
};

export default Sentry.withProfiler(App);
