export const commonIcons = {
  close: {
    viewBox: '0 0 12 12',
    data: (
      <path d="M10.33 11.488a.818.818 0 001.158-1.157L7.158 6l4.33-4.33A.818.818 0 0010.33.511L6 4.842 1.67.513A.818.818 0 10.511 1.67L4.842 6l-4.33 4.33a.818.818 0 001.157 1.158L6 7.158l4.33 4.33z" />
    ),
  },
  eye: {
    viewBox: '0 0 18 11',
    data: (
      <path d="M9 0C5.56 0 2.442 1.882.14 4.938a.713.713 0 000 .85C2.443 8.848 5.56 10.73 9 10.73c3.44 0 6.558-1.882 8.86-4.938a.713.713 0 000-.85C15.557 1.881 12.44 0 9 0zm.247 9.143a3.788 3.788 0 01-4.025-4.025 3.794 3.794 0 013.531-3.531 3.788 3.788 0 014.025 4.025 3.806 3.806 0 01-3.531 3.53zm-.114-1.746a2.035 2.035 0 01-2.166-2.165A2.047 2.047 0 018.871 3.33a2.035 2.035 0 012.165 2.165 2.047 2.047 0 01-1.903 1.903z" />
    ),
  },
  eyeCrossed: {
    viewBox: '0 0 512.008 512.008',
    data: (
      <path d="M256.011 106.673c-131.435 0-248.555 136.619-253.483 142.443-3.371 3.968-3.371 9.792 0 13.781 2.944 3.477 45.995 53.461 108.075 93.269l66.859-66.859a84.91 84.91 0 01-6.784-33.301c0-47.061 38.272-85.333 85.333-85.333a84.907 84.907 0 0133.301 6.784l54.976-54.976c-26.795-9.579-56.213-15.808-88.277-15.808zM510.112 249.926c-2.731-3.947-48.021-67.691-124.16-108.779l58.944-58.944c4.16-4.16 4.16-10.923 0-15.083s-10.923-4.16-15.083 0L67.147 429.788c-4.16 4.16-4.16 10.923 0 15.083a10.56 10.56 0 007.531 3.136c2.731 0 5.461-1.045 7.552-3.115l66.88-66.88c32.96 16.085 69.312 27.328 106.901 27.328 131.435 0 248.555-136.619 253.483-142.443 3.093-3.691 3.37-9.003.618-12.971zM256.011 341.34c-19.691 0-37.589-6.976-52.053-18.197l119.189-119.189c11.221 14.464 18.197 32.363 18.197 52.053 0 47.061-38.272 85.333-85.333 85.333z" />
    ),
  },
  cog: {
    viewBox: '0 0 14 14',
    data: (
      <path d="M13.349 6.156l-.759-.146a1.31 1.31 0 01-.978-.813 1.31 1.31 0 01.164-1.261l.476-.65a.242.242 0 00-.024-.315L11.2 1.943a.242.242 0 00-.308-.03l-.64.434a1.315 1.315 0 01-2.043-.89L8.09.659a.242.242 0 00-.24-.205H6.395a.243.243 0 00-.238.196l-.188.98c-.12.62-.666 1.07-1.3 1.07-.26 0-.514-.078-.734-.228l-.826-.559a.243.243 0 00-.308.03L1.772 2.971a.243.243 0 00-.025.315l.477.649c.268.367.33.838.164 1.261a1.312 1.312 0 01-.979.813l-.758.146a.242.242 0 00-.196.238v1.455c0 .119.087.22.205.239l.796.122c.45.07.826.36 1.008.776.182.417.138.89-.117 1.267l-.433.64a.242.242 0 00.03.307l1.028 1.028a.242.242 0 00.314.025l.65-.476c.229-.168.497-.257.775-.257.633 0 1.18.45 1.299 1.07l.146.759a.243.243 0 00.238.197h1.455c.12 0 .22-.088.239-.206l.086-.56c.113-.735.744-1.12 1.308-1.12.278 0 .546.09.775.258l.457.334c.096.071.23.06.314-.024l1.029-1.028a.242.242 0 00.03-.308l-.434-.64a1.312 1.312 0 01-.117-1.266 1.311 1.311 0 011.008-.776l.796-.122a.242.242 0 00.206-.24V6.395a.242.242 0 00-.197-.238zm-4.167.965c0 1.136-.924 2.06-2.06 2.06a2.063 2.063 0 01-2.061-2.06c0-1.136.924-2.06 2.06-2.06 1.137 0 2.06.924 2.06 2.06z" />
    ),
  },
  chevronDown: {
    viewBox: '0 0 12 6',
    data: (
      <path d="M10.878 1.332A.727.727 0 009.849.304L6 4.154 2.15.303a.727.727 0 00-1.028 1.028l4.364 4.364a.727.727 0 001.028 0l4.364-4.364z" />
    ),
  },
  plus: {
    viewBox: '0 0 16 16',
    data: (
      <path d="M8.727 3.636a.727.727 0 00-1.454 0v3.637H3.636a.727.727 0 000 1.454h3.637v3.637a.727.727 0 101.454 0V8.727h3.637a.727.727 0 100-1.454H8.727V3.636z" />
    ),
  },
  paginationLeft: {
    viewBox: '0 0 24 24',
    data: (
      <path d="M13.001 19.317a1.091 1.091 0 001.543-1.543L8.77 12l5.774-5.774a1.091 1.091 0 00-1.543-1.543L6.456 11.23a1.09 1.09 0 000 1.542L13 19.317z" />
    ),
  },
  paginationRight: {
    viewBox: '0 0 24 24',
    data: (
      <path d="M10.998 4.683a1.09 1.09 0 10-1.542 1.543L15.23 12l-5.774 5.774a1.09 1.09 0 001.543 1.543l6.545-6.546a1.09 1.09 0 000-1.542l-6.546-6.546z" />
    ),
  },
  calendar: {
    viewBox: '0 0 14 13',
    data: (
      <path d="M2.637.182a.727.727 0 00-.727.727v.727h-.728a.727.727 0 00-.727.727v9.455c0 .402.326.727.727.727H12.82a.727.727 0 00.727-.727V2.363a.727.727 0 00-.727-.727h-.728V.91a.727.727 0 10-1.454 0v.727H3.364V.91a.727.727 0 00-.727-.727zM1.91 3.818H12.09v7.273H1.91V3.818zM6.273 6H4.091v2.182h2.182V6z" />
    ),
  },
  filter: {
    viewBox: '0 0 12 13',
    data: (
      <path d="M.75 0h10.5a.375.375 0 01.3.6L7.5 6v6.143a.375.375 0 01-.543.336L4.5 11.25V6L.45.6a.375.375 0 01.3-.6z" />
    ),
  },
  bell: {
    data: (
      <path d="M465.455 372.364h-23.273V186.182C442.182 83.526 358.656 0 256 0S69.818 83.526 69.818 186.182v186.182H46.545c-12.87 0-23.273 10.403-23.273 23.273s10.403 23.273 23.273 23.273H162.909c0 51.339 41.751 93.09 93.091 93.09s93.091-41.751 93.091-93.091H465.455c12.87 0 23.273-10.403 23.273-23.273s-10.404-23.272-23.273-23.272zM256 465.455c-25.67 0-46.545-20.876-46.545-46.545h93.091c-.001 25.669-20.876 46.545-46.546 46.545zm139.636-93.091H116.364V186.182c0-77.009 62.627-139.636 139.636-139.636s139.636 62.627 139.636 139.636v186.182z" />
    ),
  },
  pencil: {
    viewBox: '0 0 24 24',
    data: (
      <path d="M20.061 9.361a1.25 1.25 0 01-.018 1.75L10.07 21H3.864c-.336.02-.778-.452-.796-.776L3 14.062l9.968-10.026a1.252 1.252 0 011.769 0l.018.019.688-.688a1.25 1.25 0 011.77 0l3.537 3.537c.488.489.488 1.28 0 1.77l-.689.687zM5.893 14.65l3.537 3.538 7.96-7.96-3.538-3.537-7.96 7.96z" />
    ),
  },
  trash: {
    viewBox: '0 0 24 24',
    data: (
      <>
        <path d="M20.25 7.154H3.75a.783.783 0 01-.53-.203.666.666 0 01-.22-.49c0-.183.079-.36.22-.489a.783.783 0 01.53-.203h16.5c.199 0 .39.073.53.203.141.13.22.306.22.49 0 .183-.079.36-.22.49a.783.783 0 01-.53.202zM9.75 16.846a.783.783 0 01-.53-.203.666.666 0 01-.22-.49v-5.538c0-.183.079-.36.22-.49a.783.783 0 01.53-.202c.199 0 .39.073.53.203.141.13.22.306.22.49v5.538c0 .183-.079.36-.22.49a.783.783 0 01-.53.202zM14.25 16.846a.783.783 0 01-.53-.203.666.666 0 01-.22-.49v-5.538c0-.183.079-.36.22-.49a.783.783 0 01.53-.202c.199 0 .39.073.53.203.141.13.22.306.22.49v5.538c0 .183-.079.36-.22.49a.783.783 0 01-.53.202z" />
        <path d="M18 21H6c-.398 0-.78-.146-1.06-.405a1.333 1.333 0 01-.44-.98V6.462c0-.184.079-.36.22-.49a.783.783 0 01.53-.203c.199 0 .39.073.53.203.141.13.22.306.22.49v13.153h12V6.462c0-.184.079-.36.22-.49a.783.783 0 01.53-.203c.199 0 .39.073.53.203.141.13.22.306.22.49v13.153c0 .368-.158.72-.44.98-.28.26-.662.405-1.06.405z" />
        <path d="M15.75 7.154a.783.783 0 01-.53-.203.666.666 0 01-.22-.49V5.078a.666.666 0 00-.22-.49.783.783 0 00-.53-.202h-4.5a.783.783 0 00-.53.202.666.666 0 00-.22.49v1.385c0 .183-.079.36-.22.49a.783.783 0 01-.53.202.783.783 0 01-.53-.203.666.666 0 01-.22-.49V5.078a2 2 0 01.659-1.469C8.581 3.218 9.153 3 9.75 3h4.5c.597 0 1.169.219 1.591.608a2 2 0 01.659 1.469v1.385c0 .183-.079.36-.22.49a.783.783 0 01-.53.202z" />
      </>
    ),
  },
  check: {
    viewBox: '0 0 24 24',
    data: (
      <path d="M19.215 5.684c.476.37.562 1.056.192 1.531l-8.482 10.91a1.091 1.091 0 01-1.611.122l-4.61-4.364a1.09 1.09 0 011.5-1.584l3.737 3.537 7.743-9.96a1.091 1.091 0 011.531-.192z" />
    ),
  },
  search: {
    viewBox: '0 0 56.966 56.966',
    data: (
      <path d="M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z" />
    ),
  },
  table: {
    viewBox: '0 0 12 12',
    data: (
      <>
        <path d="M10.984 12H1.016C.456 12 0 11.462 0 10.8V.6C0 .44.06.288.165.176A.546.546 0 01.563 0h10.874c.15 0 .293.063.398.176A.62.62 0 0112 .6v10.2c0 .662-.46 1.2-1.016 1.2zm-9.858-1.2h9.748V1.2H1.126v9.6z" />
        <path d="M11.437 4.8H.563a.546.546 0 01-.398-.176A.62.62 0 010 4.2c0-.159.06-.312.165-.424A.546.546 0 01.563 3.6h10.874c.15 0 .293.063.398.176A.62.62 0 0112 4.2a.62.62 0 01-.165.424.546.546 0 01-.398.176zM11.437 8.4H.563a.546.546 0 01-.398-.176A.62.62 0 010 7.8c0-.16.06-.312.165-.424A.546.546 0 01.563 7.2h10.874c.15 0 .293.063.398.176A.62.62 0 0112 7.8a.62.62 0 01-.165.424.546.546 0 01-.398.176z" />
        <path d="M3.734 12a.546.546 0 01-.398-.176.62.62 0 01-.165-.424V4.2c0-.159.06-.312.165-.424a.546.546 0 01.398-.176c.15 0 .292.063.398.176a.62.62 0 01.165.424v7.2a.62.62 0 01-.165.424.546.546 0 01-.398.176z" />
      </>
    ),
  },
  crown: {
    viewBox: '0 0 28 28',
    data: (
      <>
        <circle cx={14} cy={14} r={14} fill="#FFC83C" />
        <path
          d="M22.73 10.47a1.33 1.33 0 00-1.317-.17l-3.988 1.661-2.322-3.916a1.222 1.222 0 00-.463-.444 1.327 1.327 0 00-1.28 0c-.194.107-.354.26-.463.444l-2.322 3.916-3.989-1.66a1.339 1.339 0 00-1.317.17c-.18.14-.316.324-.39.533a1.11 1.11 0 00-.033.646l2.006 8.002c.038.155.11.301.21.43.1.127.228.235.374.316a1.356 1.356 0 00.985.122 22.329 22.329 0 0111.154-.002 1.355 1.355 0 00.984-.122c.147-.08.274-.188.374-.316.1-.128.172-.273.21-.428l2.01-8a1.104 1.104 0 00-.03-.648 1.177 1.177 0 00-.393-.534z"
          fill="#fff"
        />
      </>
    ),
  },
  camera: {
    viewBox: '0 0 420.8 420.8',
    data: (
      <>
        <path d="M406.8 96.4c-8.4-8.8-20-14-33.2-14h-66.4v-.8c0-10-4-19.6-10.8-26-6.8-6.8-16-10.8-26-10.8h-120c-10.4 0-19.6 4-26.4 10.8-6.8 6.8-10.8 16-10.8 26v.8h-66c-13.2 0-24.8 5.2-33.2 14-8.4 8.4-14 20.4-14 33.2v199.2C0 342 5.2 353.6 14 362c8.4 8.4 20.4 14 33.2 14h326.4c13.2 0 24.8-5.2 33.2-14 8.4-8.4 14-20.4 14-33.2V129.6c0-13.2-5.2-24.8-14-33.2zM400 328.8h-.4c0 7.2-2.8 13.6-7.6 18.4s-11.2 7.6-18.4 7.6H47.2c-7.2 0-13.6-2.8-18.4-7.6-4.8-4.8-7.6-11.2-7.6-18.4V129.6c0-7.2 2.8-13.6 7.6-18.4s11.2-7.6 18.4-7.6h77.2c6 0 10.8-4.8 10.8-10.8V81.2c0-4.4 1.6-8.4 4.4-11.2s6.8-4.4 11.2-4.4h119.6c4.4 0 8.4 1.6 11.2 4.4 2.8 2.8 4.4 6.8 4.4 11.2v11.6c0 6 4.8 10.8 10.8 10.8H374c7.2 0 13.6 2.8 18.4 7.6s7.6 11.2 7.6 18.4v199.2z" />
        <path d="M210.4 130.8c-27.2 0-52 11.2-69.6 28.8-18 18-28.8 42.4-28.8 69.6s11.2 52 28.8 69.6c18 18 42.4 28.8 69.6 28.8s52-11.2 69.6-28.8c18-18 28.8-42.4 28.8-69.6s-11.2-52-28.8-69.6c-17.6-17.6-42.4-28.8-69.6-28.8zM264.8 284c-14 13.6-33.2 22.4-54.4 22.4S170 297.6 156 284c-14-14-22.4-33.2-22.4-54.4 0-21.2 8.8-40.4 22.4-54.4 14-14 33.2-22.4 54.4-22.4s40.4 8.8 54.4 22.4c14 14 22.4 33.2 22.4 54.4.4 21.2-8.4 40.4-22.4 54.4z" />
        <circle cx={352.8} cy={150} r={19.6} />
      </>
    ),
  },
  picture: {
    viewBox: '0 0 409.848 409.848',
    data: (
      <>
        <path d="M359.848 49.785H50c-27.614 0-50 22.386-50 50v210.277c0 27.614 22.386 50 50 50h309.848c27.614 0 50-22.386 50-50V99.785c0-27.614-22.386-50-50-50zm10 260.277c0 5.514-4.486 10-10 10H50c-5.514 0-10-4.486-10-10V99.785c0-5.514 4.486-10 10-10h309.848c5.514 0 10 4.486 10 10v210.277z" />
        <path d="M248.214 174.113c-6.909-6.909-18.111-6.909-25.019 0l-64.973 64.973-18.271-18.271c-3.317-3.318-7.817-5.181-12.509-5.181s-9.192 1.863-12.509 5.181l-47.318 47.318a17.691 17.691 0 0012.51 30.2h249.6a17.692 17.692 0 0012.509-30.2l-94.02-94.02z" />
        <circle cx={126.924} cy={149.334} r={26.333} />
      </>
    ),
  },
  envelope: {
    viewBox: '0 0 18 18',
    data: (
      <path d="M1.637 4.367l6.82 6.063a.818.818 0 001.087 0l6.82-6.063v9.542a.818.818 0 01-.818.818H2.455a.818.818 0 01-.818-.818V4.367zm1.231-1.094h12.265L9 8.723l-6.132-5.45z" />
    ),
  },

  circleCheck: {
    viewBox: '0 0 191.667 191.667',
    data: (
      <path d="M95.833 0C42.991 0 0 42.99 0 95.833s42.991 95.834 95.833 95.834 95.833-42.991 95.833-95.834S148.676 0 95.833 0zm55.029 79.646l-60.207 60.207a13.463 13.463 0 01-9.583 3.969c-3.62 0-7.023-1.409-9.583-3.969l-30.685-30.685a13.464 13.464 0 01-3.97-9.583c0-3.621 1.41-7.024 3.97-9.584a13.46 13.46 0 019.583-3.97c3.62 0 7.024 1.41 9.583 3.971l21.101 21.1 50.623-50.623a13.463 13.463 0 019.583-3.969c3.62 0 7.023 1.409 9.583 3.969 5.286 5.286 5.286 13.883.002 19.167z" />
    ),
  },
  circleError: {
    data: (
      <path d="M501.609 384.603L320.543 51.265c-13.666-23.006-37.802-36.746-64.562-36.746-26.76 0-50.896 13.74-64.562 36.746-.103.176-.19.352-.293.528L10.662 384.076c-13.959 23.491-14.223 51.702-.719 75.457 13.535 23.769 37.919 37.948 65.266 37.948h360.544c27.347 0 52.733-14.179 66.267-37.948 13.504-23.754 13.241-51.967-.411-74.93zM225.951 167.148c0-16.586 13.445-30.03 30.03-30.03 16.586 0 30.03 13.445 30.03 30.03v120.121c0 16.584-13.445 30.03-30.03 30.03s-30.03-13.447-30.03-30.03V167.148zm30.03 270.273c-24.839 0-45.046-20.206-45.046-45.046 0-24.839 20.206-45.045 45.046-45.045 24.839 0 45.045 20.206 45.045 45.045.001 24.839-20.205 45.046-45.045 45.046z" />
    ),
  },
  clock: {
    data: (
      <path d="M507.203 205.836C487.977 105.187 406.813 24.023 306.164 4.797 126.117-29.594-29.594 126.117 4.797 306.164c19.227 100.648 100.391 181.813 201.039 201.039 180.047 34.391 335.758-121.32 301.367-301.367zM374.625 374.625C368.375 380.875 360.188 384 352 384s-16.375-3.125-22.625-9.375l-96-96c-6-6-9.375-14.125-9.375-22.625V128c0-17.688 14.328-32 32-32s32 14.313 32 32v114.75l86.625 86.625c12.5 12.5 12.5 32.75 0 45.25z" />
    ),
  },
};
