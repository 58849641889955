import { AxiosResponse } from 'axios';
import { API } from '../global/api';
import { prepareFormData } from '../helpers/form';
import { queryClient } from '../index';
import { updateApiLoading } from '../store/app';
import { updateActiveForm } from '../store/form';
import { IApiResponse_Core, IApiResponse_Pagination } from '../types/api';
import { IForm_PollGroup } from '../types/form/polls';
import {
  IPoll,
  IPoll_Group,
  IPoll_Question,
  IPoll_QuestionAnswer,
  IPollQuestion,
  IPollRequest_Create,
  IPollRequest_CreateAnswer,
  IPollRequest_CreateQuestion,
  IPollRequest_Edit,
  IPollRequest_EditAnswer,
  IPollRequest_EditQuestion,
  IPollRequest_RemoveAnswer,
  IPollRequest_RemoveQuestion,
} from '../types/poll';
import { EForm_ErrorStore } from '../types/store/form';
import { Api, apiDelete, apiGet, apiPatch, apiPost } from './index';
// import { prepareObjectForQuery } from '../helpers/query';

const REQUEST_COMMON_OPTIONS = {
  loading: false,
  loadingHide: false,
};

export const pollCreateRequest = async ({ mainFormData, questionFormData, callback }: IPollRequest_Create) => {
  updateActiveForm(EForm_ErrorStore.PollForm);
  updateApiLoading(true);

  let errorFlag = true;

  const postData = prepareFormData(mainFormData);

  const pollCreateResponse: IApiResponse_Core<IPoll> = await apiPost({
    url: '/polls/',
    postData,
    ...REQUEST_COMMON_OPTIONS,
  });

  if (pollCreateResponse.error) return;

  errorFlag = false;

  const questionLength = Object.keys(questionFormData).length;

  for (let i = 0; i < questionLength; i++) {
    const question = questionFormData[i];
    const answers = question.answer_choices || [];

    if (!question || errorFlag) return;

    delete question.answer_choices;

    const questionCreateResponse = await pollQuestionCreateRequest({
      pollId: pollCreateResponse.data.id,
      question,
      position: i + 1,
      options: REQUEST_COMMON_OPTIONS,
    });

    if (questionCreateResponse.error) {
      errorFlag = true;
      return;
    }

    const answerLength = answers.length;

    for (let j = 0; j < answerLength; j++) {
      const answer = answers[j];

      if (!answer || errorFlag) return;

      const answerData = prepareFormData(answer);
      answerData.append('question', questionCreateResponse.data.id + '');
      answerData.append('position', String(j + 1));

      const answerCreateResponse = await pollAnswerCreateRequest({
        answerData,
        options: REQUEST_COMMON_OPTIONS,
      });

      if (answerCreateResponse.error) {
        errorFlag = true;
        return;
      }
    }
  }

  if (!errorFlag) {
    await queryClient.refetchQueries('polls');

    updateApiLoading(false);

    if (callback) {
      callback(pollCreateResponse.data);
    }
  } else {
    updateApiLoading(false);
  }
};

export const pollEditRequest = async ({ mainFormData, questionFormData, callback }: IPollRequest_Edit) => {
  updateActiveForm(EForm_ErrorStore.PollForm);
  updateApiLoading(true);

  let errorFlag = true;

  const postData = prepareFormData(mainFormData);

  const pollEditResponse: IApiResponse_Core<IPoll> = await apiPatch({
    url: `/polls/${mainFormData.id}/`,
    postData,
    ...REQUEST_COMMON_OPTIONS,
  });

  if (pollEditResponse.error) {
    return;
  }

  errorFlag = false;

  const questionLength = Object.keys(questionFormData).length;

  for (let i = 0; i < questionLength; i++) {
    const question = questionFormData[i];
    const answers = question.answer_choices || [];

    if (!question || errorFlag) return;

    delete question.answer_choices;

    let questionResponseId = Number(question.id);

    const questionSendData = {
      pollId: pollEditResponse.data.id,
      question,
      position: i + 1,
      options: REQUEST_COMMON_OPTIONS,
    };

    if (Number(question.is_new)) {
      const questionCreateResponse = await pollQuestionCreateRequest(questionSendData);

      if (questionCreateResponse.error) {
        errorFlag = true;
        return;
      }

      questionResponseId = questionCreateResponse?.data?.id;
    } else {
      const questionEditResponse = await pollQuestionEditRequest(questionSendData);

      if (questionEditResponse.error) {
        errorFlag = true;
        return;
      }
    }

    const answerLength = answers.length;

    for (let j = 0; j < answerLength; j++) {
      const answer = answers[j];

      if (!answer || errorFlag) return;

      let answerResponseError = false;

      const answerData = prepareFormData(answer);
      answerData.append('question', String(questionResponseId));
      answerData.append('position', String(j + 1));

      if (Number(answer.is_new)) {
        const answerCreateResponse = await pollAnswerCreateRequest({
          answerData,
          options: REQUEST_COMMON_OPTIONS,
        });

        answerResponseError = answerCreateResponse.error;
      } else {
        const answerEditResponse = await pollAnswerEditRequest({
          answerId: Number(answer.id),
          answerData,
          options: REQUEST_COMMON_OPTIONS,
        });

        answerResponseError = answerEditResponse.error;
      }

      if (answerResponseError) {
        errorFlag = true;
        return;
      }
    }
  }

  if (!errorFlag) {
    queryClient.setQueryData(['poll', pollEditResponse.data.id + ''], pollEditResponse.data);

    updateApiLoading(false);

    if (callback) {
      callback(pollEditResponse.data);
    }
  } else {
    updateApiLoading(false);
  }
};

export const pollRemoveRequest = async (id: number | string) => {
  const res = await apiDelete({
    url: `/polls/${id}/`,
  });

  if (res) {
    // await queryClient.refetchQueries('polls');
  }
};

export const pollGroupCreateRequest = async (data: IForm_PollGroup, callback: (data: IPoll_Group) => void) => {
  updateActiveForm(EForm_ErrorStore.PollGroupCreate);

  const postData = prepareFormData(data);

  const res: IApiResponse_Core<IPoll_Group> = await apiPost({
    url: '/poll_groups/',
    postData,
  });

  if (res.error) return;

  // await queryClient.refetchQueries('poll_groups');

  if (callback) {
    callback(res.data);
  }
};

export const pollGroupEditRequest = async (data: IForm_PollGroup, callback: (data: IPoll_Group) => void) => {
  updateActiveForm(EForm_ErrorStore.PollGroupCreate);

  // Todo: картинка в polls не сохраняется
  // console.log('patch', data);

  const postData = prepareFormData(data);

  const res: IApiResponse_Core<IPoll_Group> = await apiPatch({
    url: `/poll_groups/${data.id}/`,
    postData,
  });

  if (res.error) return;

  // await queryClient.refetchQueries('poll_groups');
  queryClient.setQueryData(['poll_group', data.id], res.data);

  if (callback) {
    callback(res.data);
  }
};

export const pollGroupRemoveRequest = async (id: string | number, callback?: () => void) => {
  if (confirm('Удалить группу?')) {
    const res = await apiDelete({
      url: `/poll_groups/${id}/`,
    });

    if (res.error) return;

    await queryClient.refetchQueries('poll_groups');

    if (callback) {
      callback();
    }
  }
};

export const pollQuestionCreateRequest = async ({
  pollId,
  question,
  position,
  options,
}: IPollRequest_CreateQuestion): Promise<IApiResponse_Core<IPoll_Question>> => {
  return await apiPost({
    url: `/polls/${pollId}/questions/`,
    postData: {
      ...question,
      poll: pollId,
      position,
    },
    ...options,
  });
};

export const pollQuestionEditRequest = async ({
  pollId,
  question,
  position,
  options,
}: IPollRequest_EditQuestion): Promise<IApiResponse_Core<IPoll_Question>> => {
  return await apiPatch({
    url: `/polls/${pollId}/questions/${question.id}/`,
    postData: {
      ...question,
      poll: pollId,
      position,
    },
    ...options,
  });
};

export const pollQuestionRemoveRequest = async ({ pollId, question }: IPollRequest_RemoveQuestion) => {
  await apiDelete({
    url: `/polls/${pollId}/questions/${question.id}/`,
  });
};

export const pollAnswerCreateRequest = async ({
  answerData,
  options,
}: IPollRequest_CreateAnswer): Promise<IApiResponse_Core<IPoll_QuestionAnswer>> => {
  return await apiPost({
    url: '/poll_question_choices/',
    postData: answerData,
    ...options,
  });
};

export const pollAnswerEditRequest = async ({
  answerId,
  answerData,
  options,
}: IPollRequest_EditAnswer): Promise<IApiResponse_Core<IPoll_QuestionAnswer>> => {
  return await apiPatch({
    url: `/poll_question_choices/${answerId}/`,
    postData: answerData,
    ...options,
  });
};

export const pollAnswerRemoveRequest = async ({
  answerId,
}: IPollRequest_RemoveAnswer): Promise<IApiResponse_Core<IPoll_QuestionAnswer>> => {
  return await apiDelete({
    url: `/poll_question_choices/${answerId}/`,
  });
};

export const fetchExternalPoll = async (group: string) => {
  const res: AxiosResponse = await Api.get(`${API.url.replace('/api', '')}/external/poll/groups/?id=${group}`);

  return res.data;
};

export const pollFetch = async (id: string) => {
  return await apiGet({
    url: `/polls/${id}/`,
  });
};

export const pollGroupFetch = async (groupId: string | number | undefined) => {
  return await apiGet({
    url: `/poll_groups/${groupId}/`,
  });
};

export const fetchPollForms = async () => {
  return await apiGet({
    url: '/poll_forms/',
  });
};

export const fetchPollsStatistics = async (id: string) => {
  return await apiGet({
    url: `/polls/statistics/?poll=${id}`,
  });
};
