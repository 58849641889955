import { useStoreMap } from 'effector-react';
import { $app } from '../../../store/app';
import { Spinner } from '../Spinner/Spinner';
import './ScreenLoader.scss';

export const ScreenLoader = () => {
  const $apiLoading = useStoreMap($app, s => s.apiLoading);

  if (!$apiLoading) return null;

  return (
    <div className="cmp-screen-loader fixed top-0 left-0 right-0 bottom-0 d-flex ai-center jc-center">
      <Spinner />
    </div>
  );
};
