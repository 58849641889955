import cn from 'classnames';
import { useStoreMap } from 'effector-react';
import { ChangeEvent, memo, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { $formError } from '../../../store/form';
import { EIcons, Icon } from '../Icon/Icon';
import InputMask from 'react-input-mask';
import { AsYouType } from 'libphonenumber-js';

export interface ICmpProps_Input {
  label?: string;
  classes?: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  name: string;
  control: any;
  formKey?: string;
  required?: boolean | string;
  handleChange?: (name: string, value: string) => void;
  inputClasses?: string;
  defaultValue?: string;
  style?: {};
  price?: boolean;
  value?: string;
}

const _Input = ({
  required = false,
  type = 'text',
  formKey,
  handleChange,
  defaultValue,
  price = false,
  ...props
}: ICmpProps_Input) => {
  const ref = useRef<HTMLInputElement>(null);

  // @ts-ignore
  const $error = useStoreMap($formError, s => s[formKey]?.[props.name]?.[0]?.message);

  const toggleInputType = () => {
    ref.current?.setAttribute('type', ref.current?.type === 'text' ? 'password' : 'text');
  };

  return (
    <label className={cn('form-field _icon-right', props.classes)} style={props.style}>
      {props.label && (
        <span className="form-label">
          {props.label} {required && <span className="c-red">*</span>}
        </span>
      )}

      <span className="pos d-block">
        <Controller
          name={props.name}
          control={props.control}
          render={({ field }) => {
            if (defaultValue) {
              field.value = defaultValue;
            }

            const onRemove = (e: ChangeEvent<HTMLInputElement>, clear?: boolean) => {
              if (clear) {
                field.onChange(' ');
                field.value = ' ';
                if (handleChange) {
                  handleChange(props.name, '');
                }
                return;
              }
            };

            const onChange = (e: ChangeEvent<HTMLInputElement>, clear?: boolean) => {
              if (clear) {
                field.onChange('');
                field.value = '';

                if (handleChange) {
                  handleChange(props.name, '');
                }
                return;
              }

              const { value } = e.target;
              let val = value;

              if (type === 'tel') {
                if (!value.length) {
                  val = `+${new AsYouType().input(value)}`;
                } else {
                  val = new AsYouType().input(value);
                }
              }

              field.onChange(val);

              if (handleChange) {
                handleChange(props.name, val);
              }
            };

            // const onFocus = () => {
            //   if (type === 'tel') {
            //     if (!field.value?.length) {
            //       field.onChange('+');
            //     }
            //   }
            // };

            // if (price) {
            //   const func = (e: any) => {
            //     if (e.target.value.indexOf('.') != '-1') {
            //       e.target.value = e.target.value.substring(0, e.target.value.indexOf('.') + 3);
            //     }
            //   };
            //
            //   return (
            //     <div style={{ position: 'relative' }}>
            //       <input
            //         ref={ref}
            //         type={type}
            //         value={field.value || ''}
            //         onChange={onChange}
            //         onFocus={onFocus}
            //         className={cn('form-input', { _error: !!$error }, props.inputClasses)}
            //         placeholder={props.placeholder || ''}
            //         disabled={props.disabled}
            //         onInput={func}
            //         step=".01"
            //         {...props}
            //       />
            //       {field.value && (
            //         <div style={{ position: 'absolute', top: '13px', right: '10px' }}>
            //           <Icon
            //             className="cursor-pointer"
            //             name={EIcons.Close}
            //             size={10}
            //             onClick={(e: ChangeEvent<HTMLInputElement>) => onChange(e, true)}
            //           />
            //         </div>
            //       )}
            //     </div>
            //   );
            // }

            // if (type === 'tel') {
            //   return (
            //     <div style={{ position: 'relative' }}>
            //       <InputMask
            //         // @ts-ignore
            //         ref={ref}
            //         type={type}
            //         value={field.value || ''}
            //         onChange={onChange}
            //         // onFocus={onFocus}
            //         className={cn('form-input', { _error: !!$error }, props.inputClasses)}
            //         placeholder={props.placeholder || ''}
            //         disabled={props.disabled}
            //         mask="+7 999 999 9999"
            //         {...props}
            //       />
            //       {field.value && (
            //         <div style={{ position: 'absolute', top: '13px', right: '10px' }}>
            //           <Icon
            //             className="cursor-pointer"
            //             name={EIcons.Close}
            //             size={10}
            //             onClick={(e: ChangeEvent<HTMLInputElement>) => onChange(e, true)}
            //           />
            //         </div>
            //       )}
            //     </div>
            //   );
            // }

            // if (type === 'phone') {
            //   return (
            //     <div style={{ position: 'relative' }}>
            //       <input
            //         ref={ref}
            //         type={'number'}
            //         value={field.value || ''}
            //         onChange={onChange}
            //         onFocus={onFocus}
            //         className={cn('form-input', 'arrowNone', { _error: !!$error }, props.inputClasses)}
            //         placeholder={props.placeholder || ''}
            //         disabled={props.disabled}
            //         {...props}
            //       />
            //       {field.value && (
            //         <div style={{ position: 'absolute', top: '13px', right: '10px' }}>
            //           <Icon
            //             className="cursor-pointer"
            //             name={EIcons.Close}
            //             size={10}
            //             onClick={(e: ChangeEvent<HTMLInputElement>) => onChange(e, true)}
            //           />
            //         </div>
            //       )}
            //     </div>
            //   );
            // }

            return (
              <div style={{ position: 'relative' }}>
                {type === 'tel' ? (
                  <>
                    <InputMask
                      // @ts-ignore
                      ref={ref}
                      type={type}
                      value={field.value || '+'}
                      onChange={onChange}
                      // onFocus={onFocus}
                      className={cn('form-input', { _error: !!$error }, props.inputClasses)}
                      placeholder={props.placeholder || ''}
                      disabled={props.disabled}
                      mask="+7 999 999 9999"
                      {...props}
                    />
                    <div style={{ position: 'absolute', top: '13px', right: '10px' }}>
                      <Icon
                        className="cursor-pointer"
                        name={EIcons.Close}
                        size={10}
                        onClick={(e: ChangeEvent<HTMLInputElement>) => onRemove(e, true)}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <input
                      ref={ref}
                      type={type}
                      value={field.value || ''}
                      onChange={onChange}
                      // onFocus={onFocus}
                      className={cn('form-input', { _error: !!$error }, props.inputClasses)}
                      placeholder={props.placeholder || ''}
                      disabled={props.disabled}
                      {...props}
                    />
                    <div style={{ position: 'absolute', top: '13px', right: '10px' }}>
                      <Icon
                        className="cursor-pointer"
                        name={EIcons.Close}
                        size={10}
                        onClick={(e: ChangeEvent<HTMLInputElement>) => onRemove(e, true)}
                      />
                    </div>
                  </>
                )}
              </div>
            );

            // return type === 'tel' ? (
            //   <InputMask
            //     // @ts-ignore
            //     ref={ref}
            //     type={type}
            //     value={field.value || ''}
            //     onChange={onChange}
            //     onFocus={onFocus}
            //     className={cn('form-input', { _error: !!$error }, props.inputClasses)}
            //     placeholder={props.placeholder || ''}
            //     disabled={props.disabled}
            //     mask="+7 999 999 9999"
            //     {...props}
            //   />
            // ) : (
            //   <div style={{ position: 'relative' }}>
            //     <input
            //       ref={ref}
            //       type={type}
            //       value={field.value || ''}
            //       onChange={onChange}
            //       onFocus={onFocus}
            //       className={cn('form-input', { _error: !!$error }, props.inputClasses)}
            //       placeholder={props.placeholder || ''}
            //       disabled={props.disabled}
            //       {...props}
            //     />
            //     {field.value && (
            //       <div style={{ position: 'absolute', top: '13px', right: '10px' }}>
            //         <Icon
            //           className="cursor-pointer"
            //           name={EIcons.Close}
            //           size={10}
            //           onClick={(e: ChangeEvent<HTMLInputElement>) => onRemove(e, true)}
            //         />
            //       </div>
            //     )}
            //   </div>
            // );
          }}
        />

        {type === 'password' && (
          <span className="eye icon _right cursor-pointer d-flex ai-center jc-center" onClick={toggleInputType}>
            <Icon name={ref.current?.type === 'password' ? EIcons.Eye : EIcons.EyeCrossed} />
          </span>
        )}
      </span>

      {$error && <div className="form-error text-sm mt-1">{$error}</div>}
    </label>
  );
};

export const FormInput = memo(_Input);
