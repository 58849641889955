import { useStoreMap } from 'effector-react';
import { ChangeEvent, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import menuIcon from 'static/imgs/icon/menu.svg';
import logo from 'static/imgs/logo.svg';
import useOnClickOutside from 'use-onclickoutside';
import { defaultAvatar } from '../../../global/imageList';
import { logout } from '../../../store/auth';
import { $profile } from '../../../store/profile';
import './AppHeader.scss';
import { NotificationApp } from './Notification/PopupNotice';
import { queryClient } from '../../../index';
import { IDictionary } from '../../../types/dictionary';
import { $language, changeLanguage, Language } from '../../../store/language';
import { useTranslation } from 'react-i18next';

interface ICmpProps_PageHeader {
  mobMenuCallback: () => void;
}

export const AppHeader = (props: ICmpProps_PageHeader) => {
  const lang = useStoreMap($language, s => s.lang);
  const $profileStore = useStoreMap($profile, s => s.profile);
  const location = useLocation();
  const { i18n } = useTranslation();

  const langListRef = useRef<HTMLDivElement>(null);
  const profileListRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(langListRef, () => langListRef.current?.classList.remove('_active'));

  useOnClickOutside(profileListRef, () => profileListRef.current?.classList.remove('_active'));

  const dictionaryQueryData = queryClient.getQueryData<IDictionary>('dictionary');

  const toggleLangList = () => langListRef.current?.classList.toggle('_active');

  const toggleProfileList = () => profileListRef.current?.classList.toggle('_active');

  const handleLogout = () => logout();

  const languageChangeHandler = (value: ChangeEvent<HTMLSelectElement>) => {
    changeLanguage(value.currentTarget.value as Language);
    const lang = value.currentTarget.value;
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  useEffect(() => {
    langListRef.current?.classList.remove('_active');
    profileListRef.current?.classList.remove('_active');
  }, [location.pathname]);

  return (
    <>
      <div id="lk-header" className="d-flex ai-center jc-between pos z-[2]">
        <div className="d-flex ai-center">
          <div id="lk-menu-btn" className="cursor-pointer" onClick={props.mobMenuCallback}>
            <img src={menuIcon} alt="" />
          </div>

          <Link to="/" id="logo" className="d-block d-xl-none d-sm-none d-md-none ml-3">
            <img src={logo} alt="" />
          </Link>
        </div>

        <div className="d-flex ai-stretch">
          <div ref={langListRef} className="language d-flex ai-center">
            <div onClick={toggleLangList} className="selected link">
              {lang}
            </div>

            <select
              onChange={languageChangeHandler}
              value={lang}
              className="list d-flex flex-column transition duration-200"
              style={{ width: 32 }}
              size={3}
            >
              {dictionaryQueryData?.locales.map(s => (
                <option key={s.id} value={s.id} className="link transition duration-200 p-3">
                  {s.id}
                </option>
              ))}
            </select>
          </div>

          <NotificationApp />

          <div ref={profileListRef} className="profile pos">
            <div className="d-flex ai-center cursor-pointer" onClick={toggleProfileList}>
              <img
                src={$profileStore?.logo || defaultAvatar}
                alt={$profileStore?.name || ''}
                className="object-cover avatar"
              />

              <span className="ml-2 name d-none d-xs-block leading-tight">{$profileStore?.name}</span>
            </div>

            <div className="link-list d-flex flex-column transition duration-200">
              <Link to="/profile" className="link transition duration-200">
                Профиль
              </Link>

              <a
                className="link transition duration-200"
                href="https://info.capital-system.com/"
                target="_blank"
                rel="noreferrer"
              >
                О программе
              </a>

              <button onClick={handleLogout} className="link transition duration-200">
                Выход
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
