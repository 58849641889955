import { logoImg } from 'global/imageList';
import { ChangeEvent, useEffect, useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { showModal } from '../../../store/modal';
import { EModal_VisibleStore } from '../../../types/store/modals';
import { CallbackRequestModal } from '../Modal/CallbackRequest';
import { CallbackRequestSuccess } from '../Modal/CallbackRequestSuccess';
import './AuthHeader.scss';
import { useStoreMap } from 'effector-react';
import { $language, changeLanguage, Language, updateTranslations } from '../../../store/language';
import { queryClient } from '../../../index';
import { IDictionary } from '../../../types/dictionary';
import { useAppTranslation } from '../../../hooks/useAppTranslation';
import { IApiResponse_Core, IApiResponse_Translations } from '../../../types/api';
import { apiGet } from '../../../api';
import { getLocale } from '../../../i18n/config';
import {useTranslation} from 'react-i18next';



export const AuthHeader = () => {
  const { t } = useAppTranslation();

  const { i18n } = useTranslation();
  const langListRef = useRef<HTMLDivElement>(null);
  const lang = useStoreMap($language, s => s.lang);

  const toggleLangList = () => {
    langListRef.current?.classList.toggle('_active');
  };

  const dictionaryQueryData = queryClient.getQueryData<IDictionary>('dictionary');
  const locales = queryClient.getQueryData<{ id: string; name: string }[]>('locales');

  const showModalCallback = () => showModal(EModal_VisibleStore.CallbackRequest);

  useOnClickOutside(langListRef, () => langListRef.current?.classList.remove('_active'));

  const languageChangeHandler = (value: ChangeEvent<HTMLSelectElement>) => {
    changeLanguage(value.currentTarget.value as Language);
    const lang = value.currentTarget.value;
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, []);

  useEffect(() => {
    langListRef.current?.classList.remove('_active');
  }, [location.pathname]);

  return (
    <>
      <div id="auth-header">
        <div className="container">
          <div className="row jc-between ai-center">
            <div className="col-auto">
              <a href="/" id="logo" className="d-block">
                <img src={logoImg} alt="Capital Sport CRM" className="" />
              </a>
            </div>

            <div className="col-auto d-block d-md-none">
              <div id="open-mobile-menu" className="pos">
                <span className="pos-abs" />
              </div>
            </div>

            <div className="col-auto d-none d-md-block">
              <div className="row gx-4 ai-center">
                <div className="col-auto">
                  <a href="https://info.capital-system.com/" className="link" target="_blank" rel="noreferrer">
                    {t('Узнать больше')}
                  </a>
                </div>

                <div className="col-auto">
                  <div ref={langListRef} className="language d-flex ai-center">
                    <div onClick={toggleLangList} className="selected link">
                      {lang}
                    </div>

                    <select
                      onChange={languageChangeHandler}
                      value={lang}
                      className="list d-flex flex-column transition duration-200"
                      style={{ width: 32 }}
                      size={3}
                    >
                      {/*@ts-ignore*/}
                      {locales && locales.map((s: any) => (
                        <option key={s.id} value={s.id} className="link transition duration-200 p-3">
                          {s.id}
                        </option>
                      ))}
                    </select>

                    {/*<div className="list">*/}
                    {/*  <div className="list-item">EN</div>*/}
                    {/*  <div className="list-item">RU</div>*/}
                    {/*</div>*/}
                  </div>
                </div>

                <div className="col-auto">
                  <button
                    onClick={showModalCallback}
                    className="button link js-open-modal"
                    data-modal-id="modal-consultation"
                  >
                    {t('Заказать консультацию')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CallbackRequestModal />

      <CallbackRequestSuccess />
    </>
  );
};
