import { useStoreMap } from 'effector-react';
import { ChangeEvent, useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { fetchExternalPoll } from '../../../api/poll';
import { testJSON } from '../../../helpers/json';
import { useUrlParams } from '../../../hooks/useUrlParams';
import {
  $externalPoll,
  formSubmitStep1,
  formSubmitStep2,
  formSubmitStep3,
  hidePageBegin,
  updateStep3Form,
} from '../../../store/externalPoll';
import { IApiResponse_Core } from '../../../types/api';
import { EExternalPoll_QuestionType, IExternalPoll_Step1 } from '../../../types/externalPoll';
import {
  EForm_ExternalPollStep1,
  EForm_ExternalPollStep2,
  IForm_ExternalPollStep1,
  IForm_ExternalPollStep2,
} from '../../../types/form/externalPoll';
import { IPoll_Question, IPoll_Question_Choice } from '../../../types/poll';
import { EForm_ErrorStore } from '../../../types/store/form';
import { CustomButton } from '../../common/Button';
import { FormCheckbox } from '../../common/Form/Checkbox';
import { FormInput } from '../../common/Form/Input';
import { Spinner } from '../../common/Spinner/Spinner';
import InputMask from 'react-input-mask';
import { useAppTranslation } from '../../../hooks/useAppTranslation';

export const ExternalPollPage = () => {
  const mainWrapperRef = useRef<HTMLDivElement>(null);
  const { t } = useAppTranslation();

  const params: { group: string } = useUrlParams();

  const $pollStore = useStoreMap($externalPoll, s => ({
    step: s.step,
    step3Form: s.step3Form,
    questions: s.questions,
    pageEnd: s.pageEnd,
    pageBegin: s.pageBegin,
  }));

  const externalPollQuery = useQuery<IApiResponse_Core<IExternalPoll_Step1>>(['externalPoll', params.group], () =>
    fetchExternalPoll(params.group)
  );

  const btnColors = {
    bg: externalPollQuery.data?.data?.button_color || 'transparent',
    color: externalPollQuery.data?.data?.button_text_color || 'transparent',
  };

  const buttonProps = {
    btnBgColor: btnColors.bg,
    btnColor: btnColors.color,
    btnClasses: 'w-full mt-10',
  };

  const step1Form = useForm<IForm_ExternalPollStep1>();
  const step2Form = useForm<IForm_ExternalPollStep2>();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const questionId = Number(event.currentTarget.getAttribute('data-question-id'));
    const answerId = Number(event.currentTarget.getAttribute('data-answer-id'));
    const questionType = event.currentTarget.getAttribute('data-question-type');
    const value = event.currentTarget.value;

    const newStep3Form = { ...$pollStore.step3Form };

    if (questionType === EExternalPoll_QuestionType.Simple || questionType === EExternalPoll_QuestionType.Directory) {
      newStep3Form[questionId] = value;
    } else if (
      questionType === EExternalPoll_QuestionType.One_text ||
      questionType === EExternalPoll_QuestionType.One_image
    ) {
      newStep3Form[questionId] = [answerId];
    } else if (
      questionType === EExternalPoll_QuestionType.Multiple_text ||
      questionType === EExternalPoll_QuestionType.Multiple_image
    ) {
      if (Array.isArray(newStep3Form[questionId])) {
        if (newStep3Form[questionId].includes(answerId)) {
          newStep3Form[questionId] = newStep3Form[questionId].filter((id: number) => id !== answerId);
        } else {
          newStep3Form[questionId].push(answerId);
        }
      } else {
        newStep3Form[questionId] = [answerId];
      }
    }

    updateStep3Form(newStep3Form);
  };
  const renderQuestion = useCallback(
    (question: IPoll_Question, questionIndex: number) => {
      if (externalPollQuery.isLoading) {
        return null;
      }

      const commonParamsSimpleAndDirectory = {
        'data-question-id': question.id,
        'data-answer-id': 0,
        'data-question-type': question.type,
      };

      return (
        <div key={question.id} className="cmp-card">
          <div className="text-20 mb-6 font-medium">{question.name}</div>

          {(question.type === EExternalPoll_QuestionType.Simple ||
            question.type === EExternalPoll_QuestionType.Directory) &&
            (question.directory_type === 'phone' ? (
              <label className="form-field">
                {
                  <InputMask
                    mask="+7 (999) 999-99-99"
                    className="form-input"
                    style={{
                      color: externalPollQuery.data?.data?.answer_text_color || 'transparent',
                      backgroundColor: externalPollQuery.data?.data?.answer_background_color || 'transparent',
                    }}
                    onChange={handleChange}
                    {...commonParamsSimpleAndDirectory}
                    type="tel"
                  />
                }
              </label>
            ) : (
              <label className="form-field">
                {
                  <input
                    className="form-input"
                    style={{
                      color: externalPollQuery.data?.data?.answer_text_color || 'transparent',
                      backgroundColor: externalPollQuery.data?.data?.answer_background_color || 'transparent',
                    }}
                    onChange={handleChange}
                    {...commonParamsSimpleAndDirectory}
                  />
                }
              </label>
            ))}

          {question.choices.map((answer: IPoll_Question_Choice, answerIndex: number) => {
            const commonParams = {
              'data-question-id': question.id,
              'data-answer-id': answer.id,
              'data-question-type': question.type,
            };

            const isSingleAnswerActive = $pollStore.step3Form[question.id]?.includes(answer.id);

            const key = `${questionIndex}${answerIndex}`;

            const styles = isSingleAnswerActive
              ? {
                  color: externalPollQuery.data?.data?.choosen_answer_text_color || 'transparent',
                  backgroundColor: externalPollQuery.data?.data?.choosen_answer_background_color || 'transparent',
                }
              : {
                  color: externalPollQuery.data?.data?.answer_text_color || 'transparent',
                  backgroundColor: externalPollQuery.data?.data?.answer_background_color || 'transparent',
                };

            // if (question.type === ('phone')) {
            //   return (
            //     <label key={key} className="mt-4 px-5 cursor-pointer rounded-md d-block p-3" style={styles}>
            //       <input
            //         type="radio"
            //         name={`${questionIndex}.answer`}
            //         aria-label={'PHONE'}
            //         className="d-none"
            //         onChange={handleChange}
            //         {...commonParams}
            //       />
            //       PHONE
            //       {answer.name}
            //     </label>
            //   );
            // }

            if (question.type === EExternalPoll_QuestionType.One_text) {
              return (
                <label key={key} className="mt-4 px-5 cursor-pointer rounded-md d-block p-3" style={styles}>
                  <input
                    type="radio"
                    name={`${questionIndex}.answer`}
                    className="d-none"
                    onChange={handleChange}
                    {...commonParams}
                  />

                  {answer.name}
                </label>
              );
            }

            if (question.type === EExternalPoll_QuestionType.Multiple_text) {
              return (
                <label key={key} className="mt-4 px-5 cursor-pointer rounded-md d-block p-3" style={styles}>
                  <input
                    type="checkbox"
                    name={`${questionIndex}.answer`}
                    className="d-none"
                    onChange={handleChange}
                    {...commonParams}
                  />

                  {answer.name}
                </label>
              );
            }

            if (question.type === EExternalPoll_QuestionType.One_image) {
              return (
                <label
                  key={key}
                  className="mt-4 cursor-pointer rounded-md d-flex flex-column p-4 shadow-md shadow-text-500"
                  style={styles}
                >
                  <input
                    type="radio"
                    name={`${questionIndex}.answer`}
                    className="d-none"
                    onChange={handleChange}
                    {...commonParams}
                  />

                  {answer.image && (
                    <img src={answer.image} alt={answer.name || ''} className="max-w-full rounded-md mb-4 bg-white" />
                  )}

                  {answer.name}
                </label>
              );
            }

            if (question.type === EExternalPoll_QuestionType.Multiple_image) {
              return (
                <label
                  key={key}
                  className="mt-4 cursor-pointer rounded-md d-flex flex-column p-4 shadow-md shadow-text-500"
                  style={styles}
                >
                  <input
                    type="checkbox"
                    name={`${questionIndex}.answer`}
                    className="d-none"
                    onChange={handleChange}
                    {...commonParams}
                  />

                  {answer.image && (
                    <img src={answer.image} alt={answer.name || ''} className="max-w-full rounded-md mb-4 bg-white" />
                  )}

                  {answer.name}
                </label>
              );
            }
          })}
        </div>
      );
    },
    [$pollStore.step3Form, externalPollQuery.isLoading]
  );

  useEffect(() => {
    if (mainWrapperRef.current && !externalPollQuery.isLoading) {
      if ($pollStore.step === 3 && $pollStore.pageBegin.begin_image) {
        mainWrapperRef.current.style.backgroundImage = `url(${$pollStore.pageBegin.begin_image})`;
      } else if ($pollStore.step === 4 && $pollStore.pageEnd.end_image) {
        mainWrapperRef.current.style.backgroundImage = `url(${$pollStore.pageEnd.end_image})`;
      }
    }
  }, [mainWrapperRef.current, externalPollQuery.isLoading, $pollStore]);

  if (externalPollQuery.isLoading) {
    return (
      <div className="d-flex ai-center jc-center flex-1">
        <Spinner />
      </div>
    );
  }

  return (
    <div
      className="flex-1 d-flex bg-fixed bg-no-repeat bg-cover bg-bottom overflow-y-auto overflow-x-hidden"
      style={{
        backgroundImage: `url(${externalPollQuery.data?.data?.background_image})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%',
        backgroundColor: externalPollQuery.data?.data.background_color || 'transparent',
      }}
    >
      {!!externalPollQuery.data?.data?.additional_scripts && (
        <div dangerouslySetInnerHTML={{ __html: externalPollQuery.data?.data?.additional_scripts || '' }} />
      )}

      <div className="container-md d-flex flex-1 jc-center">
        <div
          className="w-full"
          style={{
            maxWidth: 740,
          }}
        >
          {$pollStore.step === 1 && (
            <div
              style={{
                background: externalPollQuery.data?.data?.verification_text_color || 'transparent',
                padding: '40px 60px 60px',
              }}
            >
              {/*{testJSON(externalPollQuery.data?.data?.verification_notify_header) && (*/}
              {/*  <div*/}
              {/*    className="text-24 md:text-32 font-medium text-center mb-10"*/}
              {/*    dangerouslySetInnerHTML={{*/}
              {/*      __html: JSON.parse(externalPollQuery.data?.data?.verification_notify_header || '123'),*/}
              {/*    }}*/}
              {/*  />*/}
              {/*)}*/}
              {/*------------------------------------------*/}
              {testJSON(externalPollQuery.data?.data.verification_notify_header) && (
                <div
                  className="text-24 md:text-32 font-medium text-center mb-10"
                  dangerouslySetInnerHTML={{
                    __html: JSON.parse(externalPollQuery.data?.data?.verification_notify_header || ''),
                  }}
                />
              )}
              {testJSON(externalPollQuery.data?.data?.verification_code_header) && (
                <div
                  className="text-24 md:text-32 font-medium text-center mb-10"
                  dangerouslySetInnerHTML={{
                    __html: JSON.parse(externalPollQuery.data?.data?.verification_code_header || ''),
                  }}
                />
              )}

              <form onSubmit={step1Form.handleSubmit(formSubmitStep1)}>
                <FormInput
                  control={step1Form.control}
                  name={EForm_ExternalPollStep1.Email}
                  type={externalPollQuery.data?.data?.verification_type === 'email' ? 'email' : 'tel'}
                  formKey={EForm_ErrorStore.ExternalPoll}
                  required
                  label={externalPollQuery.data?.data?.verification_type === 'email' ? 'E-Mail' : 'Телефон'}
                />

                <FormCheckbox
                  register={step1Form.register}
                  name={EForm_ExternalPollStep1.Subscription}
                  text="* Согласен на получение рассылки"
                  formKey={EForm_ErrorStore.ExternalPoll}
                  textStyle={{ color: externalPollQuery.data?.data?.agreement_text_color }}
                />

                <FormCheckbox
                  register={step1Form.register}
                  name={EForm_ExternalPollStep1.Policy}
                  text="* Согласен на обработку персональных данных и принимаю условия договора-оферты"
                  formKey={EForm_ErrorStore.ExternalPoll}
                  textStyle={{ color: externalPollQuery.data?.data?.agreement_text_color }}
                />

                <div className="row ai-center jc-between mt-10">
                  <div className="col-md-auto mb-6 md:mb-0">
                    <a
                      href="https://capital-system.com/images/resource/settings/1588835078.doc"
                      target="_blank"
                      rel="noreferrer"
                      className="underline"
                      style={{ color: externalPollQuery.data?.data?.agreement_text_color || '' }}
                    >
                      {t('Условия обработки персональных данных')}
                    </a>
                  </div>

                  <div className="col-md-auto">
                    <CustomButton type="submit" {...buttonProps} btnClasses="px-20 w-full md:w-auto">
                      <span style={{ color: externalPollQuery.data?.data?.button_text_color || 'transparent' }}>
                        {t('Далее')}
                      </span>
                    </CustomButton>
                  </div>
                </div>
              </form>
            </div>
          )}

          {$pollStore.step === 2 && (
            <div
              style={{
                backgroundColor: externalPollQuery.data?.data?.main_content_background_color || 'transparent',
                padding: '40px 60px 60px',
              }}
            >
              <div className="text-24 md:text-32 font-medium text-center mb-10">{t('Введите код авторизации')}</div>

              <form onSubmit={step2Form.handleSubmit(formSubmitStep2)}>
                <FormInput
                  name={EForm_ExternalPollStep2.Code}
                  control={step2Form.control}
                  formKey={EForm_ErrorStore.ExternalPoll}
                  label={t('Код авторизации')}
                />

                <CustomButton type="submit" {...buttonProps}>
                  <span style={{ color: externalPollQuery.data?.data?.button_text_color || 'transparent' }}>
                    {t('Отправить')}
                  </span>
                </CustomButton>
              </form>
            </div>
          )}

          {$pollStore.step === 3 && (
            <div
              style={{
                backgroundColor: externalPollQuery.data?.data?.main_content_background_color || 'transparent',
                padding: '40px 60px 60px',
              }}
            >
              <div
                style={{
                  backgroundColor:
                    // externalPollQuery.data?.data?.background_image ||
                    externalPollQuery.data?.data?.main_content_background_color || 'transparent',
                  padding: '40px 60px 60px',
                }}
              >
                {$pollStore.pageBegin?.visible ? (
                  <>
                    {testJSON($pollStore.pageBegin?.begin_title) && (
                      <div
                        className="text-24 md:text-32 font-medium mt-14 text-center mb-10"
                        dangerouslySetInnerHTML={{ __html: JSON.parse($pollStore.pageBegin?.begin_title || '') }}
                      />
                    )}

                    {testJSON($pollStore.pageBegin?.begin_description) && (
                      <div
                        className="mb-10"
                        dangerouslySetInnerHTML={{ __html: JSON.parse($pollStore.pageBegin?.begin_description || '') }}
                      />
                    )}

                    <img src={$pollStore.pageBegin.begin_image} alt={$pollStore.pageBegin.begin_image} />

                    <CustomButton onClick={hidePageBegin} {...buttonProps}>
                      <span style={{ color: externalPollQuery.data?.data?.button_text_color || 'transparent' }}>
                        {t('Далее')}
                      </span>
                    </CustomButton>
                  </>
                ) : (
                  <>
                    {/*{testJSON(externalPollQuery.data?.data?.verification_notify_header) && (*/}
                    {/*  <div*/}
                    {/*    className="text-24 md:text-32 font-medium text-center mb-10"*/}
                    {/*    dangerouslySetInnerHTML={{*/}
                    {/*      __html: JSON.parse(externalPollQuery.data?.data.get_answers_text || ''),*/}
                    {/*    }}*/}
                    {/*  />*/}
                    {/*)}*/}

                    {testJSON(externalPollQuery.data?.data?.get_answers_text) && (
                      <div
                        className="text-24 md:text-32 font-medium text-center mb-10"
                        dangerouslySetInnerHTML={{
                          __html: JSON.parse(externalPollQuery.data?.data.get_answers_text || ''),
                        }}
                      />
                    )}

                    {/*{externalPollQuery.data?.data?.verification_notify_header && (*/}
                    {/*  <div*/}
                    {/*    className="text-24 md:text-32 font-medium text-center mb-10"*/}
                    {/*    dangerouslySetInnerHTML={{*/}
                    {/*      __html: JSON.parse(externalPollQuery.data?.data.get_answers_text || ''),*/}
                    {/*    }}*/}
                    {/*  />*/}
                    {/*)}*/}

                    {$pollStore.questions.map(renderQuestion)}

                    <CustomButton onClick={formSubmitStep3} {...buttonProps}>
                      <span style={{ color: externalPollQuery.data?.data?.button_text_color || 'transparent' }}>
                        {t('Отправить')}
                      </span>
                    </CustomButton>
                  </>
                )}
              </div>
            </div>
          )}

          {$pollStore.step === 4 && (
            <div
              style={{
                background: externalPollQuery.data?.data?.main_content_background_color || 'transparent',
                padding: '40px 60px 60px',
              }}
            >
              {testJSON($pollStore.pageEnd.end_title) && (
                <div
                  className="text-24 md:text-32 font-medium mt-14 text-center mb-10"
                  dangerouslySetInnerHTML={{ __html: JSON.parse($pollStore.pageEnd.end_title || '') }}
                />
              )}

              <div
                className="mb-10"
                ref={mainWrapperRef}
                style={{ backgroundImage: $pollStore.pageEnd.end_image || '', height: 'auto' }}
              />

              {testJSON($pollStore.pageEnd.end_image) && (
                <div dangerouslySetInnerHTML={{ __html: JSON.parse($pollStore.pageEnd.end_image || '') }} />
              )}

              {testJSON($pollStore.pageEnd.end_description) && (
                <p
                  className="text-center text-16 mb-10"
                  dangerouslySetInnerHTML={{ __html: JSON.parse($pollStore.pageEnd.end_description || '') }}
                />
              )}

              {$pollStore.pageEnd.end_image && (
                //@ts-ignore
                <a href={$pollStore.pageEnd.end_url}>
                  <img src={$pollStore.pageEnd.end_image} alt={$pollStore.pageEnd.end_image} />
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
