import { IApiResponse_Core, IApiResponse_PasswordRecover } from '../types/api';
import { IForm_PasswordUpdate } from '../types/form/passwordRecover';
import { apiPost } from './index';

export const checkPasswordRecoverToken = async (
  token: string
): Promise<IApiResponse_Core<IApiResponse_PasswordRecover>> => {
  return await apiPost({
    url: '/reset_password/validate_token/',
    postData: {
      token,
    },
  });
};

export const passwordRecoverConfirm = async (
  data: IForm_PasswordUpdate
): Promise<IApiResponse_Core<IApiResponse_PasswordRecover>> => {
  return await apiPost({
    url: '/reset_password/confirm/',
    postData: {
      password: data.password,
      token: data.token,
    },
  });
};
