import cn from 'classnames';
import { useStoreMap } from 'effector-react';
import { memo, Ref, useMemo, useState } from 'react';
import { ChangeHandler, FieldError } from 'react-hook-form';
import { $formError } from '../../../store/form';

export interface ICmpProps_Checkbox {
  text?: string;
  name: string;
  fieldCls?: string;
  classes?: string;
  register: (v: any) => {
    onChange: ChangeHandler;
    ref: Ref<any>;
  };
  handleChange?: (v: any) => void;
  single?: boolean;
  error?: FieldError | null;
  formKey?: string;
  textClasses?: string;
  disabled?: boolean;
  textStyle?: {};
  defaultValue?: string;
  checked?: boolean;
}

export const _Checkbox = (props: ICmpProps_Checkbox) => {
  // @ts-ignore
  const $error = useStoreMap($formError, s => s[props.formKey]?.[props.name]?.[0]?.message);

  const reg = props.register(props.name);

  let inputParams = {
    ...reg,
    onChange: (e: any) => {
      reg.onChange(e);
      props.handleChange && props.handleChange(e.target.checked);
    },
  }

  return (
    <label className={cn('form-field', props.fieldCls)}>
      <span className="d-flex ai-center cursor-pointer">
        <input
          type="checkbox"
          className="checkbox"
          disabled={props.disabled}
          defaultValue={props.defaultValue}
          {...inputParams}
        />

        {props.text && (
          <span
            className={cn('c-text2 pl-2', props.textClasses)}
            style={props.textStyle}
            dangerouslySetInnerHTML={{ __html: props.text }}
          />
        )}
      </span>

      {$error && <div className="form-error text-sm mt-1">{$error}</div>}
    </label>
  );
};

export const FormCheckbox = memo(_Checkbox);
