export const segmentationIcons = {
  segmentationViewTypeBar: {
    viewBox: '0 0 24 24',
    data: (
      <>
        <path
          opacity={0.4}
          d="M14.91 5.697a1.455 1.455 0 10-2.91 0v9.697a1.455 1.455 0 102.91 0V5.697zM10.06 10.545a1.455 1.455 0 00-2.909 0v4.849a1.455 1.455 0 002.91 0v-4.849z"
        />
        <path d="M5.212 18.788h14.545a.97.97 0 110 1.94H4.242a.97.97 0 01-.97-.97V4.242a.97.97 0 011.94 0v14.546z" />
        <path opacity={0.4} d="M19.758 12.485a1.454 1.454 0 10-2.91 0v2.909a1.455 1.455 0 002.91 0v-2.91z" />
      </>
    ),
  },
  segmentationViewTypePie: {
    viewBox: '0 0 24 24',
    data: (
      <>
        <path d="M19.102 5.989a9.272 9.272 0 11-15.83 6.556h9.273l6.557-6.556z" />
        <path opacity={0.4} d="M3.395 11.045h8.529l6.03-6.03a9.272 9.272 0 00-14.56 6.03z" />
      </>
    ),
  },
  segmentationViewTypeSegment: {
    viewBox: '0 0 24 24',
    data: (
      <>
        <rect x={3.272} y={17.455} width={6.545} height={3.273} rx={1.636} />
        <rect x={7.636} y={3.273} width={8.727} height={3.273} rx={1.636} />
        <rect x={14.182} y={17.455} width={6.545} height={3.273} rx={1.636} />
        <path
          opacity={0.4}
          d="M11.25 8.727a.75.75 0 011.5 0v2.523h4.704a.75.75 0 01.75.75v3.273a.75.75 0 11-1.5 0V12.75H7.295v2.523a.75.75 0 11-1.5 0V12a.75.75 0 01.75-.75h4.704V8.727z"
        />
      </>
    ),
  },
  segmentationActionPrev: {
    viewBox: '0 0 24 24',
    data: (
      <path d="M3.563 7.19c-.75.447-.75 1.568 0 2.016l5.625 4.031c.75.448 1.687-.113 1.687-1.008V9.362h3.375c2.485 0 4.5 2.083 4.5 4.655 0 2.57-2.015 4.655-4.5 4.655H6.375c-.62 0-1.125.52-1.125 1.164 0 .643.504 1.164 1.125 1.164h7.875c3.728 0 6.75-3.127 6.75-6.983 0-3.857-3.022-6.983-6.75-6.983h-3.375V4.165c0-.895-.937-1.455-1.687-1.008L3.563 7.19z" />
    ),
  },
  segmentationActionNext: {
    viewBox: '0 0 24 24',
    data: (
      <path d="M20.437 7.19c.75.447.75 1.568 0 2.016l-5.625 4.031c-.75.448-1.687-.113-1.687-1.008V9.362H9.75c-2.485 0-4.5 2.083-4.5 4.655 0 2.57 2.015 4.655 4.5 4.655h7.875c.62 0 1.125.52 1.125 1.164 0 .643-.504 1.164-1.125 1.164H9.75C6.022 21 3 17.873 3 14.017c0-3.857 3.022-6.983 6.75-6.983h3.375V4.165c0-.895.937-1.455 1.687-1.008l5.625 4.033z" />
    ),
  },
  segmentationActionDownload: {
    viewBox: '0 0 24 24',
    data: (
      <path d="M12.23 3c.621 0 1.125.504 1.125 1.125v9.286l2.96-2.816a1.302 1.302 0 011.776 0c.491.467.491 1.223 0 1.69l-5.862 5.34-5.861-5.34a1.154 1.154 0 010-1.69 1.301 1.301 0 011.776 0l2.961 2.816V4.125c0-.621.503-1.125 1.125-1.125zM7.237 18.75h9.9c.685 0 1.238.504 1.238 1.125S17.822 21 17.137 21h-9.9C6.555 21 6 20.496 6 19.875s.554-1.125 1.237-1.125z" />
    ),
  },
  segmentationActionDelete: {
    viewBox: '0 0 24 24',
    data: (
      <>
        <path d="M20.25 7.154H3.75a.783.783 0 01-.53-.203.666.666 0 01-.22-.49c0-.183.079-.36.22-.489a.783.783 0 01.53-.203h16.5c.199 0 .39.073.53.203.141.13.22.306.22.49 0 .183-.079.36-.22.49a.783.783 0 01-.53.202zM9.75 16.846a.783.783 0 01-.53-.203.666.666 0 01-.22-.49v-5.538c0-.183.079-.36.22-.49a.783.783 0 01.53-.202c.199 0 .39.073.53.203.141.13.22.306.22.49v5.538c0 .183-.079.36-.22.49a.783.783 0 01-.53.202zM14.25 16.846a.783.783 0 01-.53-.203.666.666 0 01-.22-.49v-5.538c0-.183.079-.36.22-.49a.783.783 0 01.53-.202c.199 0 .39.073.53.203.141.13.22.306.22.49v5.538c0 .183-.079.36-.22.49a.783.783 0 01-.53.202z" />
        <path d="M18 21H6c-.398 0-.78-.146-1.06-.405a1.333 1.333 0 01-.44-.98V6.462c0-.184.079-.36.22-.49a.783.783 0 01.53-.203c.199 0 .39.073.53.203.141.13.22.306.22.49v13.153h12V6.462c0-.184.079-.36.22-.49a.783.783 0 01.53-.203c.199 0 .39.073.53.203.141.13.22.306.22.49v13.153c0 .368-.158.72-.44.98-.28.26-.662.405-1.06.405z" />
        <path d="M15.75 7.154a.783.783 0 01-.53-.203.666.666 0 01-.22-.49V5.078a.666.666 0 00-.22-.49.783.783 0 00-.53-.202h-4.5a.783.783 0 00-.53.202.666.666 0 00-.22.49v1.385c0 .183-.079.36-.22.49a.783.783 0 01-.53.202.783.783 0 01-.53-.203.666.666 0 01-.22-.49V5.078a2 2 0 01.659-1.469C8.581 3.218 9.153 3 9.75 3h4.5c.597 0 1.169.219 1.591.608a2 2 0 01.659 1.469v1.385c0 .183-.079.36-.22.49a.783.783 0 01-.53.202z" />
      </>
    ),
  },

  segmentationTabSegment: {
    viewBox: '0 0 24 24',
    data: (
      <>
        <rect x={3.272} y={17.455} width={6.545} height={3.273} rx={1.636} />
        <rect x={7.636} y={3.273} width={8.727} height={3.273} rx={1.636} />
        <rect x={14.182} y={17.455} width={6.545} height={3.273} rx={1.636} />
        <path
          opacity={0.4}
          d="M11.25 8.727a.75.75 0 011.5 0v2.523h4.704a.75.75 0 01.75.75v3.273a.75.75 0 11-1.5 0V12.75H7.295v2.523a.75.75 0 11-1.5 0V12a.75.75 0 01.75-.75h4.704V8.727z"
        />
      </>
    ),
  },
  segmentationTabArrow: {
    viewBox: '0 0 24 24',
    data: (
      <>
        <path d="M11.525 14.293a1 1 0 01-1.414 1.414l-6-6a1 1 0 01-.03-1.383l5.5-6a1 1 0 011.475 1.352L6.203 8.97l5.322 5.323z" />
        <path opacity={0.4} d="M9.318 8a1 1 0 100 2h6.5a1 1 0 011 1v10.042a1 1 0 102 0V11a3 3 0 00-3-3h-6.5z" />
      </>
    ),
  },
  segmentationTabGrid: {
    viewBox: '0 0 24 24',
    data: (
      <>
        <path d="M9.5 4h-4A1.5 1.5 0 004 5.5v4A1.5 1.5 0 005.5 11h4A1.5 1.5 0 0011 9.5v-4A1.5 1.5 0 009.5 4z" />
        <path
          opacity={0.4}
          d="M5.5 13h4a1.5 1.5 0 011.5 1.5v4A1.5 1.5 0 019.5 20h-4A1.5 1.5 0 014 18.5v-4A1.5 1.5 0 015.5 13zm9-9h4A1.5 1.5 0 0120 5.5v4a1.5 1.5 0 01-1.5 1.5h-4A1.5 1.5 0 0113 9.5v-4A1.5 1.5 0 0114.5 4zm0 9h4a1.5 1.5 0 011.5 1.5v4a1.5 1.5 0 01-1.5 1.5h-4a1.5 1.5 0 01-1.5-1.5v-4a1.5 1.5 0 011.5-1.5z"
        />
      </>
    ),
  },
  segmentationTabInvite: {
    viewBox: '0 0 24 24',
    data: (
      <>
        <path
          opacity={0.4}
          d="M19.636 8.606h-1.697a.849.849 0 010-1.697h1.697V5.212a.848.848 0 011.697 0v1.697h1.697a.849.849 0 010 1.697h-1.697v1.697a.848.848 0 11-1.697 0V8.606zm-7.637 2.545a3.394 3.394 0 110-6.787 3.394 3.394 0 010 6.787z"
        />
        <path d="M4.364 18.957c.329-4.05 3.615-6.109 7.622-6.109 4.062 0 7.4 1.946 7.648 6.11.01.165 0 .678-.637.678H4.98c-.213 0-.635-.459-.617-.68z" />
      </>
    ),
  },
};
