import { authIcons } from './icons/authIcons';
import { commonIcons } from './icons/commonIcons';
import { segmentationIcons } from './icons/segmentationIcons';
import { socialIcons } from './icons/socialIcons';
import { sportTypesIcons } from './icons/sportTypes';

export const iconList = {
  ...commonIcons,
  ...socialIcons,
  ...sportTypesIcons,
  ...authIcons,
  ...segmentationIcons,
};
