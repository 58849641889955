import { createEvent, createStore } from 'effector';

interface INotification {
  hasNotification: boolean;
  notificationsList: any[];
}

export const updateHasNotification = createEvent<boolean>();
export const updateNotificationList = createEvent<any>();

export const $notification = createStore<INotification>({
  hasNotification: false,
  notificationsList: [],
})
  .on(updateHasNotification, (state, payload) => ({ ...state, hasNotification: payload }))
  .on(updateNotificationList, (state, payload) => ({
    ...state,
    notificationsList: [payload],
  }));
