import { createEvent, createStore } from 'effector';

export type Language = 'ru' | 'en' | 'el';

export type Translation = Record<Language, Record<string, string>> | {};

interface LanguageStore {
  lang: Language;
  translations: Translation;
}

export const changeLanguage = createEvent<Language>();
export const updateTranslations = createEvent<Translation>();

export const $language = createStore<LanguageStore>({
  lang: 'ru',
  translations: {},
})
  .on(changeLanguage, (state, payload) => {
    localStorage.setItem('lang', payload);
    return {
      ...state,
      lang: payload,
    };
  })
  .on(updateTranslations, (state, payload) => ({ ...state, translations: payload }));
