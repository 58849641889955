import { EModal_VisibleStore } from '../../../types/store/modals';
import { CallbackRequestForm } from '../CallbackRequestForm';
import { Modal } from './Modal';
import { useAppTranslation } from '../../../hooks/useAppTranslation';

export const CallbackRequestModal = () => {
  const { t } = useAppTranslation();

  return (
    <Modal wrapperClasses="pt-20" storeKey={EModal_VisibleStore.CallbackRequest}>
      <div className="mb-4">
        <div className="title ta-center mb-2">{t('Заявка на консультацию')}</div>
        <p className="des ta-center">{t('Мы свяжемся с вами ближающее время')}</p>
      </div>

      <CallbackRequestForm purpose="Консультация" />
    </Modal>
  );
};
