export interface IForm_ExternalPollStep1 {
  email: string;
  subscription: boolean;
  policy: boolean;
}

export enum EForm_ExternalPollStep1 {
  Email = 'email',
  Subscription = 'subscription',
  Policy = 'policy',
}

export enum EForm_ExternalPollStep2 {
  Code = 'code',
}

export interface IForm_ExternalPollStep2 {
  code: string;
}
