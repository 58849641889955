import { EModal_VisibleStore } from '../../../types/store/modals';
import { Modal } from './Modal';
import { useAppTranslation } from '../../../hooks/useAppTranslation';

export const CallbackRequestSuccess = () => {
  const { t } = useAppTranslation();

  return (
    <Modal wrapperClasses="pt-20" storeKey={EModal_VisibleStore.CallbackRequestSuccess}>
      <div className="title ta-center mb-2">{t('Заявка успешно отправлена')}</div>
      <p className="des ta-center">{t('Спасибо за проявленный интерес, в ближайшее время мы с вами свяжемся')}</p>
    </Modal>
  );
};
