import { useQuery } from 'react-query';
import { apiGet } from '../../api';

const fetchLocales = async () => {
  return await apiGet({
    url: '/locales/',
  });
};

export const useFetchLocales = () => {
  return useQuery(['locales'], async () => {
    const { data } = await fetchLocales();
    return data.locales;
  });
};
